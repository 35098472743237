import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import { MenuItem } from '../../models';
import './Analytics.scss';

const Analytics = (): JSX.Element => {
  const menuItems: MenuItem[] = [
    {
      path: '/analytics/patient-orders-heatmap',
      icon: '/assets/icons/map.svg',
      title: 'Patient Orders Heatmap',
      description: 'Heatmap of patient orders based on zip code',
    },
    {
      path: '/analytics/ssp-users-metrics',
      icon: '/assets/icons/users.svg',
      title: 'Self Service Portal Users Metrics',
      description: 'Metrics of Self Service Portal users',
    },
  ];

  return (
    <DashboardLayout title="Analytics">
      <MenuGrid menuItems={menuItems} />
    </DashboardLayout>
  );
};

export default Analytics;
