import { useCallback, useEffect, useState } from 'react';

const screenMdMax = 991;
const screenSmMax = 575;
const screenSmMin = 320;

type Device = 'small-mobile' | 'mobile' | 'tablet' | 'desktop';
interface ReturnType {
  device: Device;
  windowDimensions: {
    width: number;
    height: number;
  };
}

const useWindowDimensions = (): ReturnType => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  };

  const getDeviceType = useCallback(() => {
    const { width } = getWindowDimensions();

    if (width < screenSmMin) {
      return 'small-mobile';
    }
    if (width < screenSmMax) {
      return 'mobile';
    }

    if (width < screenMdMax) {
      return 'tablet';
    }
    return 'desktop';
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [device, setDevice] = useState<Device>(getDeviceType());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
      setDevice(getDeviceType());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getDeviceType]);

  return { device, windowDimensions };
};

export default useWindowDimensions;
