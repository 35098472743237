import { RootState } from 'MyTypes';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { getCurrentUserDetails } from '../../../../api';
import { ContextMenu } from '../../../../components';
import { CurrentUserDetails } from '../../../../models/CurrentUserDetails';
import * as UserDetailsActions from '../../../../store/UserDetailsDuck/duck/action';
import { selectCurrentUserDetailsData } from '../../../../store/UserDetailsDuck/duck/selector';
import './TopBar.scss';

interface Props {
  currentUser: CurrentUserDetails | null;
  setCurrentUserDetails: (currentUserDetails: CurrentUserDetails) => void;
}

const TopBar = memo<Props>(({ currentUser, setCurrentUserDetails }) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const isUserDropDownItemActive = useCallback((item) => item.path === currentPath, [currentPath]);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const toggleUserDropdown = () => setIsUserDropdownOpen((v) => !v);
  const dropdownToggleRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const recurGetUser = async () => {
      getCurrentUserDetails().then((res) => {
        if (res.status === 'error') {
          setTimeout(recurGetUser, 300);
          return;
        }
        if (res.user) setCurrentUserDetails(res.user);
      });
    };
    if (!currentUser) recurGetUser();
  }, [currentUser, setCurrentUserDetails]);

  const userDropdownItems = [
    {
      path: '/settings/change-user-password',
      label: 'Change user password',
      icon: '/assets/icons/reset.svg',
    },
    {
      path: '/',
      label: 'Logout',
      icon: '/assets/icons/log-out.svg',
    },
  ];

  const renderCurrentUser = () => (
    <div className="TopBar-user" role="button" tabIndex={0} onClick={toggleUserDropdown} ref={dropdownToggleRef}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/avatar-light-cyan.png`}
        alt="current-user"
        className="TopBar-user-picture"
      />
      <span className="TopBar-user-name">{currentUser?.username || '...'}</span>
      <span className="TopBar-user-role">{currentUser?.accessLevel || '...'}</span>
      <span
        className="TopBar-user-dropdown-icon"
        style={{
          transform: isUserDropdownOpen ? 'rotate(180deg)' : undefined,
        }}
      />
    </div>
  );

  return (
    <div className="TopBar">
      <div className="TopBar-current-user-container">
        {renderCurrentUser()}
        {isUserDropdownOpen && (
          <ContextMenu
            menuItems={userDropdownItems}
            isItemActive={isUserDropDownItemActive}
            toggleRef={dropdownToggleRef}
            onClickOutside={toggleUserDropdown}
          />
        )}
      </div>
    </div>
  );
});

TopBar.displayName = 'TopBar';

const mapStateToProps = (state: RootState) => ({
  currentUser: selectCurrentUserDetailsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setCurrentUserDetails: (currentUserDetails: CurrentUserDetails) =>
    dispatch(UserDetailsActions.setCurrentUserDetails(currentUserDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
