import React, { memo, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import './InputRtfTextareaField.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

export const InputRtfTextareaField = memo<Props>(({ label, value, onChange }) => {
  const [focused, setFocused] = useState(false);

  const initialEditorState = useMemo(() => {
    const contentBlock = convertFromHTML(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
    return undefined;
  }, [value]);

  const [editorState, setEditorState] = useState<EditorState | undefined>(initialEditorState);

  return (
    <div className="InputRtfTextareaField">
      {label && <span className="InputRtfTextareaField-label">{label}</span>}
      <Editor
        editorState={editorState}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        editorClassName={`InputRtfTextareaField-editor ${focused ? 'mod-focused' : ''}`}
        onEditorStateChange={(editorStateValue) => {
          setEditorState(editorStateValue);
          onChange(draftToHtml(convertToRaw(editorStateValue.getCurrentContent())));
        }}
        toolbarHidden
      />
    </div>
  );
});

InputRtfTextareaField.displayName = 'InputRtfTextareaField';

export default InputRtfTextareaField;
