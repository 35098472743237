import React, { memo } from 'react';
import TableauReport from 'tableau-react';
import './TableauWidget.scss';

interface Props {
  url: string;
}

export const TableauWidget = memo<Props>(({ url }) => {
  return (
    <div className="TableauWidget">
      <TableauReport url={url} />
    </div>
  );
});

TableauWidget.displayName = 'TableauWidget';

export default TableauWidget;
