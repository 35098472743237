import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { getAllForms, getPendingOrderForms } from '../../../../../api';
import { Accordion, InputTextField } from '../../../../../components';
import { ViewSSPUserDetails } from '../../../../../models';
import { FormsAndDocumentsMap } from '../../../../../models/FormsAndDocumentsMap';
import {
  selectSelectedDocument,
  selectSelectedSspUsers,
} from '../../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import * as SendFormsAndDocumentsActions from '../../../../../store/SendFormsAndDocumentsDuck/duck/action';
import Layout from '../Layout';
import './SelectDocument.scss';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDocument: FormsAndDocumentsMap | null;
  setSelectedDocument: (selectedDocument: FormsAndDocumentsMap) => void;
}

const SelectDocument = ({ selectedSspUsers, selectedDocument, setSelectedDocument }: Props): JSX.Element => {
  const history = useHistory();
  const { register, watch } = useForm();

  /* API Response State */
  const [initialPendingFormsList, setInitialPendingFormsList] = useState<FormsAndDocumentsMap[] | null>(null);
  const [initialAllFormsList, setInitialAllFormsList] = useState<FormsAndDocumentsMap[] | null>(null);

  /* Display State */
  const [allFormsList, setAllFormsList] = useState<FormsAndDocumentsMap[]>([]);
  const [pendingOrderFormsList, setPendingOrderFormsList] = useState<FormsAndDocumentsMap[]>([]);

  /* Redirect back to step 1 if no selectedSspUsers */
  useEffect(() => {
    if (!selectedSspUsers || selectedSspUsers.length === 0) {
      history.push('/self-service-portal/send-forms-and-documents/select-patient');
    }
  }, [history, selectedSspUsers]);

  /* API Calls */
  useEffect(() => {
    const customerIds = selectedSspUsers.map((x) => x.bonafideCustomerId);
    getPendingOrderForms(undefined, customerIds).then((res) => {
      if (!res?.data) return;
      setInitialPendingFormsList(res.data);
      setPendingOrderFormsList(res.data);
    });

    getAllForms().then((res) => {
      if (!res?.data) return;
      setInitialAllFormsList(res.data);
      setAllFormsList(res.data);
    });
  }, [selectedSspUsers]);

  /* Search Effect */
  useEffect(() => {
    const subscription = watch((value) => {
      if (initialAllFormsList && initialPendingFormsList) {
        const filterAllForms = initialAllFormsList.filter((x) =>
          x.fileName.toLowerCase().includes(value.search.toLowerCase()),
        );
        const filterPendingForms = initialPendingFormsList.filter((x) =>
          x.fileName.toLowerCase().includes(value.search.toLowerCase()),
        );
        setPendingOrderFormsList(filterPendingForms);
        setAllFormsList(filterAllForms);
      }
    });
    return () => subscription.unsubscribe();
  }, [initialAllFormsList, initialPendingFormsList, watch]);

  return (
    <Layout
      ctaLabel="Next"
      handleCtaClick={() => history.push('/self-service-portal/send-forms-and-documents/review')}
      currentStep={1}
      className="SendFormsAndDocuments-select-document-container"
      disableCta={(!initialPendingFormsList && !initialAllFormsList) || !selectedDocument}
    >
      {initialPendingFormsList && initialAllFormsList ? (
        <>
          <div className="SendFormsAndDocuments-select-document-document-list">
            <InputTextField
              {...register('search')}
              forceHideLabel
              name="search"
              placeholder="Search document..."
              type="text"
            />
            <div className="documents-container">
              <Accordion defaultExpanded title="Pending Order Forms">
                {initialPendingFormsList.length === 0 ? (
                  <div className="no-forms-content">No Pending Order Forms Available</div>
                ) : (
                  pendingOrderFormsList.map((x) => (
                    <div
                      onClick={() => setSelectedDocument(x)}
                      key={x.fileName}
                      className={`document ${selectedDocument?.fileName === x.fileName ? 'mod-selected' : ''}`}
                    >
                      {x.fileName}
                    </div>
                  ))
                )}
              </Accordion>
              <Accordion defaultExpanded title="All Forms">
                {allFormsList.map((x) => (
                  <div
                    onClick={() => setSelectedDocument(x)}
                    key={x.fileName}
                    className={`document ${selectedDocument?.fileName === x.fileName ? 'mod-selected' : ''}`}
                  >
                    {x.fileName}
                  </div>
                ))}
              </Accordion>
            </div>
          </div>
          <div className="SendFormsAndDocuments-select-document-document-preview">
            {selectedDocument ? <ClipLoader color="#06bbc9" /> : <span>Select Document To Preview</span>}
            <iframe className="preview-container" title="preview-container " src={selectedDocument?.pdf} />
          </div>
        </>
      ) : (
        <div className="SendFormsAndDocuments-select-document-loading">
          <ClipLoader color="#06bbc9" />
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers' | 'selectedDocument'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDocument: selectSelectedDocument(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'setSelectedDocument'> => ({
  setSelectedDocument: (selectedDocument: FormsAndDocumentsMap) =>
    dispatch(SendFormsAndDocumentsActions.setSelectedDocument(selectedDocument)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDocument);
