import React, { useState, useRef, useMemo, useCallback, useEffect, forwardRef, ReactElement } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './SignaturePadField.scss';

interface Props {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  bottomClassName?: string;
  label?: string;
  status?: 'active' | 'error' | 'inactive' | 'disabled';
  name: string;
  bottomLabel?: string;
  forceHideLabel?: boolean;
  forceHideBottomLabel?: boolean;
  elementAfter?: ReactElement;
  height?: number;
  penColor?: string;
  /** Base64 data URL of the signature image */
  value: string;
  onChange: (base64DataUrl: string) => unknown;
  clearButtonHidden?: boolean;
  clearButtonDisabled?: boolean;
  disabled?: boolean;
}

export const SignaturePadField = forwardRef(
  ({
    className = '',
    labelClassName = '',
    inputClassName = '',
    bottomClassName = '',
    label,
    status = 'active',
    bottomLabel,
    forceHideLabel,
    forceHideBottomLabel,
    elementAfter,
    height = 200,
    penColor = '#000',
    name,
    value,
    onChange,
    clearButtonHidden,
    clearButtonDisabled,
    disabled,
  }: Props) => {
    const sigCanvasRef = useRef<null | SignatureCanvas>(null);
    const [isDrawing, setIsDrawing] = useState(false);

    // Update canvas from value
    useEffect(() => {
      if (sigCanvasRef.current) {
        return sigCanvasRef.current?.fromDataURL(value);
      }
      return undefined;
    }, [value]);

    // Disable canvas based on 'disabled prop
    useEffect(() => {
      if (disabled) return sigCanvasRef.current?.off();
      return sigCanvasRef.current?.on();
    }, [disabled]);

    // Stroke handlers
    const handleStrokeBegin = useCallback(() => setIsDrawing(true), []);
    const handleStrokeEnd = useCallback(() => {
      setIsDrawing(false);
      setTimeout(() => {
        if (sigCanvasRef.current?.isEmpty()) return;

        const base64DataUrl = sigCanvasRef.current?.toDataURL();
        if (base64DataUrl) onChange(base64DataUrl);
      }, 0);
    }, [onChange]);

    // Clear handler
    const handleClear = useCallback(() => {
      onChange('');
      return sigCanvasRef.current?.clear();
    }, [onChange]);

    // Compose canvas props
    const canvasProps = useMemo(
      () => ({
        id: name,
        className: `${
          status === 'disabled'
            ? 'SignaturePadField-input-disabled'
            : status === 'error'
            ? 'SignaturePadField-input-error'
            : 'SignaturePadField-input'
        } ${inputClassName}`,
        height,
      }),
      [height, inputClassName, name, status],
    );

    return (
      <div data-testid="SignaturePadField" className={`SignaturePadField ${className}`}>
        {!forceHideLabel && <div className={`SignaturePadField-input-label  ${labelClassName}`}>{label}</div>}
        <div className="SignaturePadField-inputContainer">
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor={penColor}
            canvasProps={canvasProps}
            onBegin={handleStrokeBegin}
            onEnd={handleStrokeEnd}
            // Disabled for: https://github.com/agilgur5/react-signature-canvas/issues/65
            clearOnResize={false}
          />
          {!clearButtonHidden && !isDrawing && (
            <button
              type="button"
              className="SignaturePadField-inputContainer-clearButton"
              onClick={handleClear}
              disabled={disabled || clearButtonDisabled}
            >
              <span className="SignaturePadField-inputContainer-clearButton-icon" />
            </button>
          )}
        </div>
        {!forceHideBottomLabel && (
          <div
            className={`${
              status === 'error' ? 'SignaturePadField-input-label-bottom-error' : 'SignaturePadField-input-label-bottom'
            } ${bottomClassName}`}
          >
            {bottomLabel}
          </div>
        )}
        {elementAfter}
      </div>
    );
  },
);

SignaturePadField.displayName = 'SignaturePadField';

export default SignaturePadField;
