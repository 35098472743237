import { action } from 'typesafe-actions';
import { ViewUserDetails } from '../../../models/ViewUserDetails';

export interface ActionType {
  type:
    | 'users/CLEAR_USERS'
    | 'users/FETCH_USERS'
    | 'users/FETCH_USERS_SUCCESS'
    | 'users/FETCH_USERS_FAIL'
    | 'users/FETCH_APPEND_USERS'
    | 'users/FETCH_APPEND_USERS_SUCCESS'
    | 'users/FETCH_APPEND_USERS_FAIL'
    | 'users/FETCH_SEARCH_RESULTS'
    | 'users/FETCH_SEARCH_RESULTS_SUCCESS'
    | 'users/FETCH_SEARCH_RESULTS_FAIL'
    | 'users/CLEAR_SEARCH_RESULTS';
  payload: {
    users?: ViewUserDetails[];
    query?: string;
    limit?: number;
    skip?: number;
    error?: string;
    searchResults?: ViewUserDetails[];
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const CLEAR_USERS = 'users/CLEAR_USERS';

export const FETCH_USERS = 'users/FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'users/FETCH_USERS_FAIL';

export const FETCH_APPEND_USERS = 'users/FETCH_APPEND_USERS';
export const FETCH_APPEND_USERS_SUCCESS = 'users/FETCH_APPEND_USERS_SUCCESS';
export const FETCH_APPEND_USERS_FAIL = 'users/FETCH_APPEND_USERS_FAIL';

export const FETCH_SEARCH_RESULTS = 'users/FETCH_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'users/FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_FAIL = 'users/FETCH_SEARCH_RESULTS_FAIL';
export const CLEAR_SEARCH_RESULTS = 'users/CLEAR_SEARCH_RESULTS';

/* ------------- Fetch iPortal Users  ------------- */
export const fetchUsers = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_USERS, { limit, skip, successCallback, errorCallback });
export const fetchUsersSuccess = (users: ViewUserDetails[]): ActionType => action(FETCH_USERS_SUCCESS, { users });
export const fetchUsersFail = (error?: string): ActionType => action(FETCH_USERS_FAIL, { error });

/* ------------- Fetch More iPortal Users ------------- */
export const fetchAppendUsers = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_APPEND_USERS, { limit, skip, successCallback, errorCallback });
export const fetchAppendUsersSuccess = (users: ViewUserDetails[]): ActionType =>
  action(FETCH_APPEND_USERS_SUCCESS, { users });
export const fetchAppendUsersFail = (error?: string): ActionType => action(FETCH_APPEND_USERS_FAIL, { error });

export const clearUsers = (): Promise<ActionType> => Promise.resolve(action(CLEAR_USERS)).then();

/* ------------- Search API ------------- */
export const fetchSearchResults = (query: string): ActionType => action(FETCH_SEARCH_RESULTS, { query });
export const fetchSearchResultsSuccess = (searchResults?: ViewUserDetails[]): ActionType =>
  action(FETCH_SEARCH_RESULTS_SUCCESS, { searchResults });
export const fetchSearchResultsFail = (error?: string): ActionType => action(FETCH_SEARCH_RESULTS_FAIL, { error });
export const clearSearchResults = (): Promise<ActionType> => Promise.resolve(action(CLEAR_SEARCH_RESULTS)).then();
