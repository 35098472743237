const composeChunkArr = (input: any[], chunkSize: number): any[] => {
  const chunked: any[] = [];

  Array.from({ length: Math.ceil(input.length / chunkSize) }, (_, i) => {
    chunked.push(input.slice(i * chunkSize, i * chunkSize + chunkSize));
    return null;
  });

  return chunked;
};

export default composeChunkArr;
