import { Report } from 'models/Report';
import { action } from 'typesafe-actions';

export interface ActionType {
  type: 'reports/FETCH_REPORTS' | 'reports/FETCH_REPORTS_SUCCESS' | 'reports/FETCH_REPORTS_FAIL';

  payload: {
    reports?: Report[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const FETCH_REPORTS = 'reports/FETCH_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'reports/FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAIL = 'reports/FETCH_REPORTS_FAIL';

/* ------------- Fetch Address Zips  ------------- */
export const fetchReports = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_REPORTS, { successCallback, errorCallback });
export const fetchReportsSuccess = (reports: Report[]): ActionType => action(FETCH_REPORTS_SUCCESS, { reports });
export const fetchReportsFail = (error?: string): ActionType => action(FETCH_REPORTS_FAIL, { error });
