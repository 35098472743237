import publicAxios, { AxiosResponse } from 'axios';
import qs from 'qs';

import { PDFFormDropdownOptionsErrorResponse, PDFFormDropdownOptionsResponse } from 'models/PDFFormDropdownOptions';
import { AddressZipsResponse } from 'models/AddressZipsResponse';
import { ReportsListResponse } from 'models/Report';
import { DownloadReportResponse } from 'models/DownloadReport';
import { ISspUsersMetricsResponse } from 'models';
import Config from '../Config';
import {
  GetFormContentErrorResponse,
  GetFormContentResponse,
  LoginDetails,
  RegisterUserDetails,
  RegisterUserDetailsResponse,
  TimeslotDetailsResponse,
  UpdateTimeslotPayload,
  ViewSSPUserDetails,
  ViewSSPUserDetailsResponse,
} from '../models';
import { CustomerInterfaceResponse } from '../models/CustomerInterfaceResponse';
import { OrderResponseInterface } from '../models/OrderResponseInterface';
import { ChangeUserPasswordDetails, ChangeUserPasswordResponse } from '../models/ChangeUserPasswordDetails';
import { CurrentUserDetailsResponse } from '../models/CurrentUserDetails';
import { FormsAndDocumentsMapResponse } from '../models/FormsAndDocumentsMap';
import { LoginResponse } from '../models/LoginResponse';
import { ResetUserPasswordDetails, ResetUserPasswordResponse } from '../models/ResetUserPasswordDetails';
import {
  SendNewFormsAndDocumentsInterface,
  SendNewFormsAndDocumentsResponseInterface,
} from '../models/SendNewFormsAndDocumentsInterface';
import {
  ViewAllUsersFormsInterface,
  ViewAllUsersFormsInterfaceResponseInterface,
} from '../models/ViewAllUsersFormsInterface';
import { ViewUserDetailsResponse } from '../models/ViewUserDetails';
import axios from './axios';
import { SubmitFormDetails } from '../models/SubmitFormDetails';
import { SubmitFormResponse, SubmitFormErrorResponse } from '../models/SubmitFormResponse';
import { AppointmentDetailsResponse, AppointmentPayload, AppointmentResponse } from '../models/Appointment';
import { StoreLocationResponse } from '../models/StoreLocations';
import { ServiceStatus } from '../models/ServiceStatus';
import { SeriesData } from '../models/SeriesData';

export const fetchData = (): Promise<AxiosResponse> => axios.get('/').then((res) => res.data);

export const postSample = (name: string, result: string): Promise<AxiosResponse> => axios.post('', { name, result });

// Authentication API
export const postLoginDetails = (loginDetails: LoginDetails): Promise<LoginResponse> =>
  axios.post('/api/auth/login', loginDetails).then((res) => res.data);

export const postRegisterUser = (registerUserDetails: RegisterUserDetails): Promise<RegisterUserDetailsResponse> =>
  axios.post('/api/auth/register', registerUserDetails).then((res) => res.data);

export const postChangeUserPassword = (
  changePwDetails: ChangeUserPasswordDetails,
): Promise<ChangeUserPasswordResponse> =>
  axios.post('/api/auth/change-password', changePwDetails).then((res) => res.data);

export const postResetPassword = (resetPwDetails: ResetUserPasswordDetails): Promise<ResetUserPasswordResponse> =>
  axios.post('/api/auth/reset-password', resetPwDetails).then((res) => res.data);

export const getAllUsers = (limit: number, skip = 0): Promise<ViewUserDetailsResponse> =>
  axios.get(`/api/profile/all?${qs.stringify({ limit, skip })}`).then((res) => res.data);

export const getSearchUsers = (query: string): Promise<ViewUserDetailsResponse> =>
  axios.get(`/api/profile/search?${qs.stringify({ query })}`).then((res) => res.data);

export const getCurrentUserDetails = (): Promise<CurrentUserDetailsResponse> =>
  axios.get('/api/profile/me').then((res) => res.data);

export const getAllSspUsers = (limit: number, skip = 0): Promise<ViewSSPUserDetailsResponse> =>
  axios.get(`/api/ssp-profile/all?${qs.stringify({ limit, skip })}`).then((res) => res.data);

export const getCurrentSspUserDetails = (id?: string, customerId?: string): Promise<ViewSSPUserDetails> =>
  axios.get(`/api/ssp-profile?${qs.stringify({ id, customerId })}`).then((res) => res.data.data);

export const deleteUser = (username: string): Promise<CurrentUserDetailsResponse> =>
  axios.delete(`/api/profile?${qs.stringify({ username })}`).then((res) => res.data);

export const getBonafideCustomerDetails = (id: string): Promise<CustomerInterfaceResponse> =>
  axios.get(`/api/ssp-profile/customer?${qs.stringify({ id })}`).then((res) => res.data);

export const getBonafidePatientOrderInformation = (id: string): Promise<OrderResponseInterface> =>
  axios.get(`/api/ssp-profile/order-information?${qs.stringify({ id })}`).then((res) => res.data);

export const getSearchSspUsers = (query: string): Promise<ViewSSPUserDetailsResponse> =>
  axios.get(`/api/ssp-profile/search?${qs.stringify({ query })}`).then((res) => res.data);

export const getPendingOrderForms = (
  hcpcsList?: string[],
  customerIds?: string[],
): Promise<FormsAndDocumentsMapResponse> =>
  axios
    .post('/api/ssp-send-forms-and-documents/pending-order-forms', { hcpcsList, customerIds })
    .then((res) => res.data);

export const getAllForms = (): Promise<any> =>
  axios.get('/api/ssp-send-forms-and-documents/all-forms').then((res) => res.data);

export const postSendNewForms = (
  forms: SendNewFormsAndDocumentsInterface[],
): Promise<SendNewFormsAndDocumentsResponseInterface> =>
  axios.post('/api/ssp-send-forms-and-documents/send-new-forms', { forms }).then((res) => res.data);

export const getAllUsersForms = (limit?: number, skip = 0): Promise<ViewAllUsersFormsInterfaceResponseInterface> =>
  axios
    .get(`/api/ssp-send-forms-and-documents/all-users-forms?${qs.stringify({ limit, skip })}`)
    .then((res) => res.data);

export const getUserFormDetails = (id: string): Promise<ViewAllUsersFormsInterface> =>
  axios.get(`/api/ssp-send-forms-and-documents/form-details?${qs.stringify({ id })}`).then((res) => res.data.data);

export const getFormDropdownOptions = <T = any>(
  formComponent: string,
  version: string,
): Promise<PDFFormDropdownOptionsResponse<T> | PDFFormDropdownOptionsErrorResponse> =>
  axios
    .get(`/api/ssp-send-forms-and-documents/form-dropdown-options/${formComponent}/${version}`)
    .then((res) => res.data);

export const getSearchUsersForms = (query: string): Promise<ViewAllUsersFormsInterfaceResponseInterface> =>
  axios.get(`/api/ssp-send-forms-and-documents/search?${qs.stringify({ query })}`).then((res) => res.data);

export const deleteUserForm = (id: string): Promise<ViewAllUsersFormsInterfaceResponseInterface> =>
  axios.delete(`/api/ssp-send-forms-and-documents/delete-user-form?${qs.stringify({ id })}`).then((res) => res.data);

export const getS3ContentBuffer = (objectKey: string): Promise<Blob> =>
  axios
    .get(`/s3/${objectKey}`, {
      baseURL: Config.cdnProxyBaseUrl,
      responseType: 'blob',
    })
    .then((res) => res.data);

export const getFormContent = (
  formComponent: string,
  version: string,
): Promise<GetFormContentResponse | GetFormContentErrorResponse> =>
  axios.get(`/api/forms/${formComponent}/${version}`, { baseURL: Config.contentUrl }).then((res) => res.data);

export const postSubmitForm = (
  id: string,
  details: SubmitFormDetails,
): Promise<SubmitFormResponse | SubmitFormErrorResponse> =>
  axios.post(`/api/ssp-send-forms-and-documents/submit/${id}`, details, { timeout: 300000 }).then((res) => res.data);

export const postSubmitAppointment = (newAppointment: AppointmentPayload): Promise<AppointmentResponse> =>
  axios.post(`/api/ssp-appointments/submit`, newAppointment, { timeout: 300000 }).then((res) => res.data);

export const getAppointments = (month: number, year: number): Promise<AppointmentResponse> =>
  axios.get(`/api/ssp-appointments/all-appointments?${qs.stringify({ month, year })}`).then((res) => res.data);

export const getAppointmentDetails = (id?: string, customerId?: string): Promise<AppointmentDetailsResponse> =>
  axios.get(`/api/ssp-appointments/?${qs.stringify({ id, customerId })}`).then((res) => res.data);

export const deleteAppointment = (
  id: string,
  timeslot: string,
  date: string,
  storeId: string,
): Promise<{ status: 'ok' | 'error' }> =>
  axios
    .delete(`/api/ssp-appointments/delete-appointment?${qs.stringify({ id, timeslot, date, storeId })}`)
    .then((res) => res.data);

export const getAllStoreLocations = (): Promise<StoreLocationResponse> =>
  axios.get(`/api/store-locations/all`).then((res) => res.data);

export const getTimeslotDetails = (storeId: string, date: string): Promise<TimeslotDetailsResponse> =>
  axios.post('/api/ssp-appointments/timeslot-details', { storeId, date }).then((res) => res.data);

export const updateTimeslot = (payload: UpdateTimeslotPayload): Promise<TimeslotDetailsResponse> =>
  axios.post('/api/ssp-appointments/update-timeslot', payload).then((res) => res.data);

export const getAllServiceStatus = (): Promise<ServiceStatus> =>
  publicAxios
    .all([
      axios.get(Config.baseUrl),
      axios.get(Config.sspBaseUrl),
      axios.get(Config.cdnProxyBaseUrl),
      axios.get(Config.contentUrl),
      axios.get(Config.pdfBaseUrl),
    ])
    .then(
      publicAxios.spread((iportal, ssp, cdn, content, pdf) => {
        return {
          iportal: iportal.data,
          ssp: ssp.data,
          cdn: cdn.data,
          content: content.data,
          pdf: pdf.data,
        };
      }),
    );

export const getNewUsersAnalytics = (): Promise<SeriesData> =>
  axios.get(`/api/analytics/new-ssp-users`).then((res) => res.data.data);

export const getAddressZips = (): Promise<AddressZipsResponse> =>
  axios.get(`/api/analytics/address-zips`).then((res) => res.data);

export const getAllReports = (): Promise<ReportsListResponse> => axios.get(`api/reports/all`).then((res) => res.data);

export const downloadReport = (reportId: string): Promise<DownloadReportResponse> =>
  axios.get(`api/reports/download/${reportId}`).then((res) => res.data);

export const getSspUsersMetrics = (): Promise<ISspUsersMetricsResponse> =>
  axios.get(`/api/analytics/ssp-users-metrics`).then((res) => res.data);
