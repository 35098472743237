import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import SSPUsersTable from '../../../../../components/SSPUsersTable/SSPUsersTable';
import { ViewSSPUserDetails } from '../../../../../models';
import { selectSelectedSspUsers } from '../../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import * as SendFormsAndDocumentsActions from '../../../../../store/SendFormsAndDocumentsDuck/duck/action';
import Layout from '../Layout';
import './SelectPatient.scss';
import { TagList } from '../../../../../components';
import formatFullName from '../../../../../utils/formatFullName';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) => void;
}

const SelectPatient = ({ selectedSspUsers, setSelectedSspUsers }: Props): JSX.Element => {
  const history = useHistory();

  const handleSelectUser = (sspUser: ViewSSPUserDetails | null) => {
    if (!sspUser) return;

    /* Handle Select and Deselect User */
    if (selectedSspUsers.find((x) => x.id === sspUser.id)) {
      setSelectedSspUsers(selectedSspUsers.filter((x) => x.id !== sspUser.id));
    } else {
      setSelectedSspUsers([sspUser, ...selectedSspUsers]);
    }
  };

  const handleRemoveUser = (sspUser: ViewSSPUserDetails) => {
    if (selectedSspUsers.find((x) => x.id === sspUser.id)) {
      setSelectedSspUsers(selectedSspUsers.filter((x) => x.id !== sspUser.id));
    }
  };

  return (
    <Layout
      disableCta={selectedSspUsers.length === 0}
      ctaLabel="Next"
      handleCtaClick={() => history.push('/self-service-portal/send-forms-and-documents/select-document')}
      currentStep={0}
      className="SendFormsAndDocuments-select-patient-container"
    >
      {selectedSspUsers.length > 0 ? (
        <div className="SendFormsAndDocuments-select-patient-tag-list">
          <TagList
            tags={selectedSspUsers.map((x) => formatFullName(x.firstname, x.middleinitial, x.lastname))}
            onTagRemove={(tag: string) => {
              const sspUser = selectedSspUsers.find(
                (x) => formatFullName(x.firstname, x.middleinitial, x.lastname) === tag,
              );
              if (sspUser) handleRemoveUser(sspUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <SSPUsersTable onRowClick={handleSelectUser} selectedUsers={selectedSspUsers} />
    </Layout>
  );
};

SelectPatient.displayName = 'SelectPatient';

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'setSelectedSspUsers'> => ({
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) =>
    dispatch(SendFormsAndDocumentsActions.setSelectedSspUsers(selectedSspUsers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatient);
