import { createContext } from 'react';
import { ViewSSPUserDetails } from '../../../../../../../models';
import { ViewAllUsersFormsInterface } from '../../../../../../../models/ViewAllUsersFormsInterface';

interface FormDetailsContextProps {
  currentForm: ViewAllUsersFormsInterface;
  currentCustomer: ViewSSPUserDetails;
}

const FormDetailsContext = createContext<FormDetailsContextProps>({
  currentForm: {} as ViewAllUsersFormsInterface,
  currentCustomer: {} as ViewSSPUserDetails,
});

export default FormDetailsContext;
