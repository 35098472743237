import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from 'MyTypes';
import { useForm } from 'react-hook-form';
import './SSPUsersTable.scss';
import Table, { ColumnConfig } from '../../components/Table/Table';
import { selectSearchResultsData, selectSspUsersData } from '../../store/SspUsersDuck/duck/selector';
import { ViewSSPUserDetails } from '../../models';
import * as SspUsersActions from '../../store/SspUsersDuck/duck/action';
import { CTAButton, InputTextField } from '..';
import TableInfiniteScrollWrapper from '../TableInfiniteScrollWrapper/TableInfiniteScrollWrapper';
import formatFullName from '../../utils/formatFullName';

interface Props {
  searchResults: ViewSSPUserDetails[] | null;
  sspUsers: ViewSSPUserDetails[] | null;
  selectedUsers?: ViewSSPUserDetails[];
  fetchSspUsers: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SspUsersActions.ActionType>;
  fetchAppendSspUsers: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SspUsersActions.ActionType>;
  fetchSearchSspUsers: (query: string) => SspUsersActions.ActionType;
  clearSearchResults: () => Promise<AnyAction>;
  onRowClick?: (sspUser: ViewSSPUserDetails | null) => void;
}
const PAGE_SIZE = 50;

export const SSPUsersTable: FC<Props> = ({
  sspUsers: stateUsers,
  searchResults,
  selectedUsers,
  fetchSspUsers,
  fetchAppendSspUsers,
  fetchSearchSspUsers,
  clearSearchResults,
  onRowClick,
}) => {
  /* ------ Search Users */
  const { register, watch } = useForm();
  const searchValue = watch('search');

  const handleSearch = useCallback(() => {
    fetchSearchSspUsers(searchValue);
  }, [fetchSearchSspUsers, searchValue]);

  const sspUsers = useMemo(() => {
    if (searchResults) return searchResults;
    return stateUsers ?? [];
  }, [stateUsers, searchResults]);

  useEffect(() => {
    if (searchValue === '') clearSearchResults();
  }, [clearSearchResults, fetchSearchSspUsers, searchValue]);

  /** ------------------------------- Reset Users ------------------------------- */
  const hasResetUsersOnMount = useRef(false);
  const [isResettingUsers, setIsResettingUsers] = useState(false);

  const resetUsers = useCallback(async () => {
    setIsResettingUsers(true);
    fetchSspUsers(PAGE_SIZE, undefined, () => setIsResettingUsers(false));
  }, [fetchSspUsers, setIsResettingUsers]);

  useEffect(() => {
    if (!stateUsers || !hasResetUsersOnMount.current) {
      resetUsers();
      hasResetUsersOnMount.current = true;
    }
  }, [stateUsers, resetUsers]);

  /** ------------------------------- Load more users ------------------------------- */
  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState(false);

  const loadMoreUsers = useCallback(async () => {
    if (!stateUsers) return;
    setIsLoadingMoreUsers(true);
    fetchAppendSspUsers(PAGE_SIZE, sspUsers.length, () => setIsLoadingMoreUsers(false));
  }, [fetchAppendSspUsers, sspUsers, stateUsers, setIsLoadingMoreUsers]);

  /** ------------------------------- Columns Config ------------------------------- */
  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Bonafide Customer ID',
      renderColumn: (index) => sspUsers[index].bonafideCustomerId,
      className: 'SSPUsersTable-table-bonafide-customer-id',
    },
    {
      heading: 'Name',
      renderColumn: (index) =>
        formatFullName(sspUsers[index].firstname, sspUsers[index].middleinitial, sspUsers[index].lastname),
      className: 'SSPUsersTable-table-id',
    },
    {
      heading: 'Date of Birth',
      renderColumn: (index) => sspUsers[index].dateofbirth,
      className: 'SSPUsersTable-table-bonafide-dateofbirth',
    },
    {
      heading: 'Email address',
      renderColumn: (index) => sspUsers[index].username,
      className: 'SSPUsersTable-table-email',
    },
    {
      heading: 'Phone',
      renderColumn: (index) => `${sspUsers[index].phoneRegion} ${sspUsers[index].phone}`,
      className: 'SSPUsersTable-table-phone',
    },
    {
      heading: 'Verified',
      renderColumn: (index) => sspUsers[index].verified,
      className: 'SSPUsersTable-table-verified',
    },
    {
      heading: 'Verified At',
      renderColumn: (index) =>
        sspUsers[index].verifiedAt ? dayjs(sspUsers[index].verifiedAt ?? '').format('MM-DD-YYYY HH:mm') : '-',
      className: 'SSPUsersTable-table-verifiedAt',
    },
    {
      heading: 'Last Login',
      renderColumn: (index) =>
        sspUsers[index].lastLogin ? dayjs(sspUsers[index].lastLogin ?? '').format('MM-DD-YYYY HH:mm') : '-',
      className: 'SSPUsersTable-table-lastLogin',
    },
    {
      heading: 'Created At',
      renderColumn: (index) =>
        sspUsers[index].createdAt ? dayjs(sspUsers[index].createdAt ?? '').format('MM-DD-YYYY HH:mm') : '-',
      className: 'SSPUsersTable-table-createdAt',
    },
    {
      heading: 'Updated At',
      renderColumn: (index) =>
        sspUsers[index].updatedAt ? dayjs(sspUsers[index].updatedAt ?? '').format('MM-DD-YYYY HH:mm') : '-',
      className: 'SSPUsersTable-table-updatedAt',
    },
    {
      heading: 'Mongo ID',
      renderColumn: (index) => sspUsers[index]?.id,
      className: 'SSPUsersTable-table-id',
    },
  ];

  const handleOnRowClick = (sspUserId: string) => {
    const user = sspUsers.find((x) => x.id === sspUserId) || null;
    if (onRowClick) onRowClick(user);
  };

  return (
    <>
      <div className="SSPUsersTable-header">
        <InputTextField
          {...register('search')}
          forceHideLabel
          name="search"
          placeholder="Search user..."
          type="text"
          className="SSPUsersTable-header-search-bar"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <div className="SSPUsersTable-header-reset-container">
          <span className="SSPUsersTable-header-reset-container-text">Showing items: &nbsp;</span>
          <span className="SSPUsersTable-header-reset-container-text">
            1&nbsp; - &nbsp;
            {sspUsers.length}
          </span>

          <CTAButton
            invert
            text={isResettingUsers ? 'Resetting...' : 'Reset'}
            onClick={useCallback(() => resetUsers(), [resetUsers])}
            disabled={isResettingUsers}
            className="SSPUsersTable-header-reset-container-refresh-button"
          />
        </div>
      </div>
      {/* @TODO: Add linear gradient bars for initial loading */}
      <Table
        selectedRowKeys={useMemo(() => selectedUsers?.map((x) => x.id), [selectedUsers])}
        getRowKey={useCallback((index) => (sspUsers ?? [])[index]?.id, [sspUsers])}
        columnWidth={280}
        columnsConfig={tableColumnsConfig}
        rowHeight={60}
        itemCount={sspUsers.length}
        onRowClick={handleOnRowClick}
        loadingRows={sspUsers === null || (sspUsers && sspUsers.length === 0)}
        renderFixedSizeList={(ListComponent) => (
          <TableInfiniteScrollWrapper
            hasNextPage
            isNextPageLoading={isLoadingMoreUsers}
            items={sspUsers}
            loadNextPage={loadMoreUsers}
          >
            {({ onItemsRendered, ref }) => <ListComponent ref={ref} onItemsRendered={onItemsRendered} />}
          </TableInfiniteScrollWrapper>
        )}
      />
    </>
  );
};

SSPUsersTable.displayName = 'SSPUsersTable';

const mapStateToProps = (state: RootState): Pick<Props, 'sspUsers' | 'searchResults'> => ({
  sspUsers: selectSspUsersData(state),
  searchResults: selectSearchResultsData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<Props, 'fetchSearchSspUsers' | 'clearSearchResults' | 'fetchSspUsers' | 'fetchAppendSspUsers'> => ({
  fetchSspUsers: async (limit: number, skip?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await SspUsersActions.fetchSspUsers(limit, skip, successCallback, errorCallback)),
  fetchAppendSspUsers: async (limit: number, skip?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await SspUsersActions.fetchAppendSspUsers(limit, skip, successCallback, errorCallback)),
  clearSearchResults: async () => dispatch(await SspUsersActions.clearSearchResults()),
  fetchSearchSspUsers: (query: string) => dispatch(SspUsersActions.fetchSearchResults(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SSPUsersTable);
