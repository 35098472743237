import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './BarChart.scss';
import Skeleton from 'react-loading-skeleton';

interface Props {
  className?: string;
  loading?: boolean;
  series: {
    name: string;
    data: {
      name: string;
      y: number;
      color?: string;
    }[];
  };
}

export const BarChart = memo<Props>(({ series, loading, className }) => {
  const options = {
    chart: {
      type: 'column',
    },

    title: {
      text: '',
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}',
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
    },
    series: [series],
    xAxis: {
      categories: series.data.map(({ name }) => name),
    },
  };

  return (
    <div className={`BarChart ${className || ''}`}>
      {loading ? (
        <div style={{ backgroundColor: 'transparent' }} className="BarChart-loading">
          <Skeleton count={1} />
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
});

BarChart.displayName = 'BarChart';

export default BarChart;
