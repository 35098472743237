import React, { memo } from 'react';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import i18next from 'i18next';
import { connect } from 'react-redux';

import * as SendFormsAndDocumentsActions from 'store/SendFormsAndDocumentsDuck/duck/action';
import { LanguageGlobe } from '../../../../../../../../components';
import { Locale } from '../../../../../../../../models/Locale';
import './SSPForm.scss';
import formComponents from '../formComponents';
import useFormHandlers from '../../hooks/useFormHandlers';
import { FormComponentProps } from '../../types/FormComponentProps';

interface Props {
  mode: FormComponentProps['mode'];
  fetchFormContent: (
    i18n: typeof i18next,
    formComponent: string,
    version: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SendFormsAndDocumentsActions.ActionType>;
}

const SSPForm = memo<Props>(({ fetchFormContent, mode }) => {
  const { form, onSubmit, tForm, tLocalForm, loadingContent, submitting, error, i18n } = useFormHandlers({
    fetchFormContent,
    isPrefill: mode === 'prefill',
  });
  const FormComponent = formComponents[form?.formComponent];

  /** ------------------------------- Show unsupported ------------------------------- */
  if (!FormComponent || form?.editable === false) {
    return <div className="SSPForm-empty">Unsupported Form</div>;
  }

  /** ------------------------------- Handle lazy load ------------------------------- */
  if (loadingContent) {
    return (
      <div className="SSPForm-empty">
        <ClipLoader color="#06bbc9" />
      </div>
    );
  }

  return (
    <div className="SSPForm-container">
      {i18n?.language && (
        <LanguageGlobe
          className="SSPForm-language-globe"
          setLang={(lang: Locale) => i18n?.changeLanguage(lang)}
          currentLang={i18n?.language as Locale}
        />
      )}
      <FormComponent
        mode={mode}
        form={form}
        onSubmit={onSubmit}
        loading={submitting}
        submitError={error}
        tForm={tForm}
        ctaLabel={tLocalForm('cta')}
        ctaLoadingLabel={tLocalForm('ctaLoading')}
      />
    </div>
  );
});

SSPForm.displayName = 'SSPForm';

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchFormContent'> => ({
  fetchFormContent: async (
    i18n: typeof i18next,
    formComponent: string,
    version: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) =>
    dispatch(
      await SendFormsAndDocumentsActions.fetchFormContent(i18n, formComponent, version, successCallback, errorCallback),
    ),
});

export default connect(null, mapDispatchToProps)(SSPForm);
