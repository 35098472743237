import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { CTAButton } from '../../../../../components';
import { ViewSSPUserDetails } from '../../../../../models';
import { Appointment } from '../../../../../models/Appointment';
import { selectSelectedDateTime, selectSelectedSspUsers } from '../../../../../store/AppointmentsDuck/duck/selector';
import * as AppointmentActions from '../../../../../store/AppointmentsDuck/duck/action';
import Layout from '../Layout';
import './Completed.scss';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDateTime: Appointment | null;
  clearSelectedDateTime: () => Promise<AppointmentActions.ActionType>;
  clearSelectedSspUsers: () => Promise<AppointmentActions.ActionType>;
}

const Completed = ({
  selectedSspUsers: reduxSelectedSspUsers,
  selectedDateTime: reduxSelectedDateTime,
  clearSelectedDateTime,
  clearSelectedSspUsers,
}: Props): JSX.Element => {
  const [selectedSspUser] = useState(reduxSelectedSspUsers[0] || null);
  const [selectedDateTime] = useState(reduxSelectedDateTime);
  const history = useHistory();

  /* Redirect back to step 2 if no selectedDateTime */
  useEffect(() => {
    if (!selectedDateTime?.date || !selectedDateTime?.store || !selectedSspUser) {
      history.push('/self-service-portal/create-appointment/select-patient');
    } else if (selectedDateTime?.date && selectedDateTime?.store && selectedSspUser) {
      /* Clear funnel store */
      clearSelectedDateTime();
      clearSelectedSspUsers();
    }
  }, [clearSelectedDateTime, clearSelectedSspUsers, history, selectedDateTime, selectedSspUser]);

  return (
    <Layout currentStep={3} className="CreateAppointment-completed-container">
      <div
        style={{
          mask: `url(/assets/icons/check-circle.svg) no-repeat center`,
          WebkitMask: `url(/assets/icons/check-circle.svg) no-repeat center`,
          maskSize: '6rem',
          WebkitMaskSize: '6rem',
        }}
        className="CreateAppointment-completed-container-icon"
      />
      <span className="CreateAppointment-completed-container-content">
        You have successfully created an appointment for the selected user!
      </span>
      <CTAButton
        onClick={() => history.push('/self-service-portal/create-appointment/select-patient')}
        text="Create another appointment"
      />
    </Layout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers' | 'selectedDateTime'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDateTime: selectSelectedDateTime(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'clearSelectedSspUsers' | 'clearSelectedDateTime'> => ({
  clearSelectedSspUsers: async () => dispatch(await AppointmentActions.clearSelectedSspUsers()),
  clearSelectedDateTime: async () => dispatch(await AppointmentActions.clearSelectedDateTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
