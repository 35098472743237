import { AddressZip } from 'models/AddressZipsResponse';
import { action } from 'typesafe-actions';

export interface ActionType {
  type: 'analytics/FETCH_ADDRESS_ZIPS' | 'analytics/FETCH_ADDRESS_ZIPS_SUCCESS' | 'analytics/FETCH_ADDRESS_ZIPS_FAIL';

  payload: {
    addressZips?: AddressZip[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const FETCH_ADDRESS_ZIPS = 'analytics/FETCH_ADDRESS_ZIPS';
export const FETCH_ADDRESS_ZIPS_SUCCESS = 'analytics/FETCH_ADDRESS_ZIPS_SUCCESS';
export const FETCH_ADDRESS_ZIPS_FAIL = 'analytics/FETCH_ADDRESS_ZIPS_FAIL';

/* ------------- Fetch Address Zips  ------------- */
export const fetchAddressZips = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_ADDRESS_ZIPS, { successCallback, errorCallback });
export const fetchAddressZipsSuccess = (addressZips: AddressZip[]): ActionType =>
  action(FETCH_ADDRESS_ZIPS_SUCCESS, { addressZips });
export const fetchAddressZipsFail = (error?: string): ActionType => action(FETCH_ADDRESS_ZIPS_FAIL, { error });
