import { ActionType } from 'typesafe-actions';
import { Appointment, AppointmentResponseData } from '../../../models/Appointment';
import { ViewAllUsersFormsInterface } from '../../../models/ViewAllUsersFormsInterface';
import { ViewSSPUserDetails } from '../../../models/ViewSSPUserDetails';
import * as AppointmentsActions from './action';

export interface State {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDateTime: Appointment;
  searchUsersFormsResults: ViewAllUsersFormsInterface[] | null;
  allAppointments: AppointmentResponseData[];
}

const initialState: State = {
  allAppointments: [],
  selectedSspUsers: [],
  selectedDateTime: {
    date: null,
    timeslot: null,
    store: null,
    remarks: '',
    adminRemarks: '',
  },
  searchUsersFormsResults: null,
};

type AppointmentsActionsType = ActionType<typeof AppointmentsActions>;

export default function reducer(state: State = initialState, action: AppointmentsActionsType): State {
  switch (action.type) {
    case AppointmentsActions.CLEAR_SEARCH_USERS_APPOINTMENTS_RESULTS:
      return {
        ...state,
        searchUsersFormsResults: initialState.searchUsersFormsResults,
      };
    case AppointmentsActions.CLEAR_SELECTED_SSP_USERS:
      return {
        ...state,
        selectedSspUsers: initialState.selectedSspUsers,
      };
    case AppointmentsActions.CLEAR_SELECTED_DATE_TIME:
      return {
        ...state,
        selectedDateTime: initialState.selectedDateTime,
      };

    case AppointmentsActions.CLEAR_ALL_USERS_APPOINTMENTS:
      return {
        ...state,
        allAppointments: [],
      };
    case AppointmentsActions.FETCH_APPEND_USERS_APPOINTMENTS:
    case AppointmentsActions.FETCH_APPEND_USERS_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        allAppointments: [
          ...(state.allAppointments ?? []),
          ...(action.payload.allAppointments ? (action?.payload?.allAppointments as AppointmentResponseData[]) : []),
        ].filter((value, index, self) => self.findIndex((x) => x.id === value.id) === index),
      };
    case AppointmentsActions.FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS:
    case AppointmentsActions.FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_SUCCESS:
    case AppointmentsActions.FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_FAIL:
    case AppointmentsActions.SET_SELECTED_SSP_USERS:
    case AppointmentsActions.SET_SELECTED_DATE_TIME:
    case AppointmentsActions.FETCH_ALL_USERS_APPOINTMENTS:
    case AppointmentsActions.FETCH_ALL_USERS_APPOINTMENTS_SUCCESS:
    case AppointmentsActions.FETCH_ALL_USERS_APPOINTMENTS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
