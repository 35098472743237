import React from 'react';
import { FormComponentProps } from '../../types/FormComponentProps';
import DMEPOSPaymentResponsibilityAgreementForm from './DMEPOSPaymentResponsibilityAgreementForm/DMEPOSPaymentResponsibilityAgreementForm';
import FormHomeSafetyEnvironmentAssessment from './FormHomeSafetyEnvironmentAssessment/FormHomeSafetyEnvironmentAssessment';
import FittingCommentsShoeOnly from './FittingCommentsShoeOnly/FittingCommentsShoeOnly';
import MedicareCappedRentalNotificationLetter from './MedicareCappedRentalNotificationLetter/MedicareCappedRentalNotificationLetter';
import MedicareDMEPOSSupplierStandardForm from './MedicareDMEPOSSupplierStandardForm/MedicareDMEPOSSupplierStandardForm';
import PatientConfirmationNoDiabetesForShoeIntakeOnly from './PatientConfirmationNoDiabetesForShoeIntakeOnly/PatientConfirmationNoDiabetesForShoeIntakeOnly';
import PatientConsentForm from './PatientConsentForm/PatientConsentForm';
import PatientOrientationForm from './PatientOrientationForm/PatientOrientationForm';
import AdvancedBeneficiaryNotice from './AdvancedBeneficiaryNotice/AdvancedBeneficiaryNotice';
import BodyMeasurementRecord from './BodyMeasurementRecord/BodyMeasurementRecord';
import FormReleaseOfLiabilityItemReturn from './FormReleaseOfLiabilityItemReturn/FormReleaseOfLiabilityItemReturn';
import AssessmentAndCarePlanForFootOrthoticsShoeOnly from './AssessmentAndCarePlanForFootOrthoticsShoeOnly/AssessmentAndCarePlanForFootOrthoticsShoeOnly';
import FootAssessmentFormShoeOnly from './FootAssessmentFormShoeOnly';

const formComponents: { [key: string]: React.FC<FormComponentProps> } = {
  PatientOrientationForm,
  PatientConsentForm,
  MedicareDMEPOSSupplierStandardForm,
  DMEPOSPaymentResponsibilityAgreementForm,
  MedicareCappedRentalNotificationLetter,
  PatientConfirmationNoDiabetesForShoeIntakeOnly,
  FittingCommentsShoeOnly,
  FormHomeSafetyEnvironmentAssessmentDelivery: (props) => (
    <FormHomeSafetyEnvironmentAssessment {...props} variantType="delivery" />
  ),
  FormHomeSafetyEnvironmentAssessmentDropship: (props) => (
    <FormHomeSafetyEnvironmentAssessment {...props} variantType="dropship" />
  ),
  FormHomeSafetyEnvironmentAssessmentPtnPickup: (props) => (
    <FormHomeSafetyEnvironmentAssessment {...props} variantType="ptnPickup" />
  ),
  AdvancedBeneficiaryNotice,
  BodyMeasurementRecord,
  FormReleaseOfLiabilityItemReturn,
  AssessmentAndCarePlanForFootOrthoticsShoeOnly,
  FootAssessmentFormShoeOnly,
};
export default formComponents;
