import { useEffect, useMemo, useState } from 'react';
import { getAllServiceStatus } from '../../../api';
import Config from '../../../Config';
import { MenuItem } from '../../../models';
import { ServiceStatus } from '../../../models/ServiceStatus';

interface ReturnType {
  menuItems: MenuItem[];
  loadingStatus: boolean;
}

const useMenuItems = (): ReturnType => {
  const [allStatus, setAllStatus] = useState<ServiceStatus | null>(null);
  const [loadingStatus, setLoadingStatus] = useState(true);

  useEffect(() => {
    getAllServiceStatus()
      .then((res) => {
        setAllStatus(res);
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  }, []);

  const trueGreen = '#7abd91';
  const falseRed = '#ff6962';

  const barChartIcon = '/assets/icons/bar-chart.svg';
  const errorCircleIcon = '/assets/icons/error-circle.svg';

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        path: Config.baseUrl,
        icon: allStatus?.iportal.status === 'ok' ? barChartIcon : errorCircleIcon,
        iconColor: allStatus?.iportal.status === 'ok' ? trueGreen : falseRed,
        title: 'iPortal',
        description: `Version: ${allStatus?.iportal.version}`,
      },
      {
        path: Config.sspBaseUrl,
        icon: allStatus?.ssp.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'Self Service Portal',
        description: `Version: ${allStatus?.ssp.version}`,
        iconColor: allStatus?.ssp.status === 'ok' ? trueGreen : falseRed,
      },
      {
        path: Config.cdnProxyBaseUrl,
        icon: allStatus?.cdn.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'CDN Proxy',
        description: `Version: ${allStatus?.cdn.version}`,
        iconColor: allStatus?.cdn.status === 'ok' ? trueGreen : falseRed,
      },

      {
        path: Config.contentUrl,
        icon: allStatus?.content.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'Content Service',
        description: `Version: ${allStatus?.content.version}`,
        iconColor: allStatus?.content.status === 'ok' ? trueGreen : falseRed,
      },

      {
        path: Config.pdfBaseUrl,
        icon: allStatus?.pdf.status === 'ok' ? barChartIcon : errorCircleIcon,
        title: 'PDF Service',
        description: `Version: ${allStatus?.pdf.version}`,
        iconColor: allStatus?.pdf.status === 'ok' ? trueGreen : falseRed,
      },
    ],
    [allStatus],
  );

  return { menuItems, loadingStatus };
};

export default useMenuItems;
