import React, { memo, useCallback, useState } from 'react';
import { LangSwitchModal } from '..';
import { LanguageListInterface, Locale } from '../../models';
import './LanguageGlobe.scss';

interface Props {
  currentLang: Locale;
  className?: string;
  setLang: (lang: Locale) => void;
}

export const LanguageGlobe = memo<Props>(({ currentLang, className, setLang }) => {
  const [isLangSwitchOpen, setIsLangSwitchOpen] = useState(false);

  const langList: LanguageListInterface[] = [
    { code: 'en', label: 'English', shortLabel: 'EN' },
    { code: 'es', label: 'Español', shortLabel: 'ES' },
    { code: 'zh-Hant', label: '繁體中文', shortLabel: '繁' },
    { code: 'zh-Hans', label: '简体中文', shortLabel: '簡' },
  ];

  return (
    <>
      <LangSwitchModal
        langList={langList}
        currentLang={currentLang}
        handleLanguageChange={(lang: Locale) => setLang(lang)}
        isOpen={isLangSwitchOpen}
        onRequestClose={useCallback(() => setIsLangSwitchOpen(false), [])}
      />
      <button
        type="button"
        className={`LanguageGlobe-btn ${className || ''}`}
        onClick={useCallback(() => setIsLangSwitchOpen(true), [])}
      >
        <span className="LanguageGlobe-btn-title">{langList.find((x) => x.code === currentLang)?.shortLabel}</span>
        <span className="LanguageGlobe-btn-icon" />
      </button>
    </>
  );
});

LanguageGlobe.displayName = 'LanguageGlobe';

export default LanguageGlobe;
