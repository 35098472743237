import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { ClipLoader } from 'react-spinners';
import { ViewSSPUserDetails } from '../../../../../models';
import { Appointment, AppointmentPayload, AppointmentResponse } from '../../../../../models/Appointment';
import { selectSelectedDateTime, selectSelectedSspUsers } from '../../../../../store/AppointmentsDuck/duck/selector';
import Layout from '../Layout';
import './Review.scss';
import { postSubmitAppointment } from '../../../../../api';
import mergeDateTime from '../../../../../utils/mergeDateTime';
import useStoreLocations from '../../../../../hooks/useStoreLocations';
import { AlertModal } from '../../../../../components';
import formatCamelValue from '../../../../../utils/formatCamelValue';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDateTime: Appointment | null;
}

const Review = ({ selectedSspUsers, selectedDateTime }: Props): JSX.Element => {
  const history = useHistory();
  const selectedSspUser = useMemo(() => selectedSspUsers[0], [selectedSspUsers]);
  const { currentStore } = useStoreLocations(selectedDateTime?.store || undefined);
  const [submitError, setSubmitError] = useState<AppointmentResponse | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const sspUserDetails = selectedSspUser
    ? Object.keys(selectedSspUser).map((key) => ({
        key,
        value: `${selectedSspUser?.[key as keyof ViewSSPUserDetails]}`,
      }))
    : [];

  const appointmentDetails = selectedDateTime
    ? Object.keys(selectedDateTime).map((key) => ({
        key,
        value: `${selectedDateTime?.[key as keyof Appointment]}`,
      }))
    : [];

  const composeAppointmentDetailsWithStoreDetails = [
    ...appointmentDetails.filter((x) => x.key !== 'store'),
    { key: 'store', value: currentStore?.name },
    { key: 'address', value: currentStore?.address },
    { key: 'phone', value: currentStore?.phone },
    { key: 'fax', value: currentStore?.fax },
  ];

  const details = [
    {
      title: 'Appointment Details',
      data: composeAppointmentDetailsWithStoreDetails,
    },
    {
      title: 'User Details',
      data: sspUserDetails,
    },
  ];

  const handleCtaClick = useCallback(() => {
    setSubmitting(true);
    if (
      selectedSspUser?.bonafideCustomerId &&
      selectedDateTime?.date &&
      selectedDateTime?.store &&
      selectedDateTime?.timeslot
    ) {
      const start = mergeDateTime(selectedDateTime.date, selectedDateTime.timeslot);
      const end = dayjs(start).add(1, 'hour').toDate();

      const formattedStartDate = dayjs(start).format('MM/DD/YYYY hh:mm A');
      const formattedEndDate = dayjs(end).format('MM/DD/YYYY hh:mm A');

      const newAppointment: AppointmentPayload = {
        customerId: selectedSspUser.bonafideCustomerId,
        start: formattedStartDate,
        end: formattedEndDate,
        store: selectedDateTime.store,
        remarks: selectedDateTime.remarks,
        adminRemarks: selectedDateTime.adminRemarks,
        storeId: currentStore.id,
        timeslot: selectedDateTime.timeslot,
      };

      postSubmitAppointment(newAppointment)
        .then((res) => {
          if (res.status === 'ok') {
            history.push('/self-service-portal/create-appointment/completed');
          } else {
            setSubmitError(res);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }, [selectedSspUser, selectedDateTime, currentStore.id, history]);

  /* Redirect back to step 2 if no selectedDateTime */
  useEffect(() => {
    if (!selectedDateTime?.timeslot || !selectedDateTime?.date || !selectedDateTime.store) {
      history.push('/self-service-portal/create-appointment/select-date');
    }
  }, [selectedDateTime, history]);

  const composeValueElement = (key: string, value: string) => {
    switch (key) {
      case 'date':
        return <span>{dayjs(value).format('MM/DD/YYYY')}</span>;
      case 'phone':
        return (
          <span>
            <a href={`tel:${value}`}>{value}</a>
          </span>
        );
      case 'remarks':
      case 'adminRemarks':
        return (
          <div
            className="CreateAppointment-review-section-information-content-row-value-remarks"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <Layout
      ctaLabel={submitting ? 'Submitting...' : 'Submit'}
      handleCtaClick={handleCtaClick}
      currentStep={2}
      className="CreateAppointment-review-container"
      disableCta={submitting}
    >
      {submitError?.error ? (
        <AlertModal
          isOpen={submitError !== null}
          description={`Error: ${submitError.error}`}
          onConfirm={() => history.push('/self-service-portal/create-appointment/select-date')}
        />
      ) : (
        <></>
      )}
      {!currentStore.name ? (
        <div className="CreateAppointment-review-container-loading">
          <ClipLoader color="#06bbc9" />
        </div>
      ) : (
        <div className="CreateAppointment-review-section-container">
          {details.map((detail, index) => (
            <div key={detail.title} className="CreateAppointment-review-section">
              <h1>{detail.title}</h1>
              <hr />
              <div className="CreateAppointment-review-section-information-content">
                {detail.data.map((item) => (
                  <div
                    key={item.key}
                    className={`CreateAppointment-review-section-information-content-row ${
                      index === 0 ? 'mod-capitalize' : ''
                    }`}
                  >
                    <span>{formatCamelValue(item.key)}</span>
                    {composeValueElement(item.key, item.value)}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers' | 'selectedDateTime'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDateTime: selectSelectedDateTime(state),
});

export default connect(mapStateToProps)(Review);
