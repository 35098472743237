import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import SSPUsersTable from '../../../../../components/SSPUsersTable/SSPUsersTable';
import { ViewSSPUserDetails } from '../../../../../models';
import { selectSelectedSspUsers } from '../../../../../store/AppointmentsDuck/duck/selector';
import * as AppointmentActions from '../../../../../store/AppointmentsDuck/duck/action';
import Layout from '../Layout';
import './SelectPatient.scss';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) => void;
  clearSelectedDateTime: () => Promise<AppointmentActions.ActionType>;
}

const SelectPatient = ({ selectedSspUsers, setSelectedSspUsers, clearSelectedDateTime }: Props): JSX.Element => {
  const history = useHistory();

  const handleSelectUser = (sspUser: ViewSSPUserDetails | null) => {
    if (!sspUser) return;
    clearSelectedDateTime();

    /* Handle Select and Deselect User */
    if (selectedSspUsers.find((x) => x.id === sspUser.id)) {
      setSelectedSspUsers([]);
    } else {
      setSelectedSspUsers([sspUser]);
    }
  };

  return (
    <Layout
      disableCta={selectedSspUsers.length === 0}
      ctaLabel="Next"
      handleCtaClick={() => history.push('/self-service-portal/create-appointment/select-date')}
      currentStep={0}
      className="CreateAppointment-select-patient-container"
    >
      <SSPUsersTable onRowClick={handleSelectUser} selectedUsers={selectedSspUsers} />
    </Layout>
  );
};

SelectPatient.displayName = 'SelectPatient';

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'setSelectedSspUsers' | 'clearSelectedDateTime'> => ({
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) =>
    dispatch(AppointmentActions.setSelectedSspUsers(selectedSspUsers)),
  clearSelectedDateTime: async () => dispatch(await AppointmentActions.clearSelectedDateTime()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPatient);
