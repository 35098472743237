import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectAppointmentsState = (state: RootState): State => state.appointments;

export const selectSelectedSspUsers = createSelector(
  selectAppointmentsState,
  (appointments) => appointments.selectedSspUsers,
);

export const selectSelectedDateTime = createSelector(
  selectAppointmentsState,
  (appointments) => appointments.selectedDateTime,
);

export const selectAllAppointments = createSelector(
  selectAppointmentsState,
  (appointments) => appointments.allAppointments,
);

export const selectCurrentAppointment = createSelector(
  selectAllAppointments,
  (_: unknown, id: string) => id,
  (form, id) => form && form.find((item) => item.id === id),
);

export const selectSearchUsersFormsResultsData = createSelector(
  selectAppointmentsState,
  (appointments) => appointments.searchUsersFormsResults,
);
