import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import isISOTimestamp from 'simply-utils/dist/dateTime/isISOTimestamp';
import mapKeys from 'lodash/mapKeys';

import parseBonafideDate from 'utils/parseBonafideDate';
import { OrderInterface } from 'models/OrderInterface';
import { getBonafidePatientOrderInformation } from '../api';
import { LabelValue } from '../models';

export interface FlattenOrder extends Omit<OrderInterface, 'orderDetails' | 'paDetails' | 'rxDetails'> {
  [key: string]: any;
}

interface ReturnType {
  currentBonafidePatientOrderInformation: FlattenOrder[] | null;
  currentBonafidePatientOrderInformationDetails: LabelValue[][];
}

const useCurrentBonafidePatientOrderInformation = (customerId?: string | null): ReturnType => {
  const [currentBonafidePatientOrderInformation, setCurrentBonafidePatientOrderInformation] = useState<
    FlattenOrder[] | null
  >(null);

  useEffect(() => {
    if (customerId)
      getBonafidePatientOrderInformation(customerId).then((res) => {
        const flattenOrders = res.data?.map(({ orderDetails, paDetails, rxDetails, ...restOrder }) => ({
          ...restOrder,
          ...mapKeys(orderDetails[0] ?? {}, (v, key) => `detail_${key}`),
          ...mapKeys(paDetails[0] ?? {}, (v, key) => `pa_${key}`),
          ...mapKeys(rxDetails[0] ?? {}, (v, key) => `rx_${key}`),
        }));
        setCurrentBonafidePatientOrderInformation(flattenOrders);
      });
  }, [customerId]);

  const currentBonafidePatientOrderInformationDetails = (): LabelValue[][] => {
    if (!currentBonafidePatientOrderInformation) return [] as LabelValue[][];

    const details: LabelValue[][] = [];

    currentBonafidePatientOrderInformation.forEach((order, index) => {
      const orderDetails: LabelValue[] = [];
      Object.keys(order).forEach((key) => {
        let value = currentBonafidePatientOrderInformation[index][key as keyof FlattenOrder] as any;

        if (isISOTimestamp(value as string)) {
          value = `${dayjs(new Date(value as string)).format('MM-DD-YYYY HH:mm:ss')}`;
        }

        if (value instanceof Date) value = `${dayjs(value ?? '').format('MM-DD-YYYY HH:mm:ss')}`;

        if (value === '') value = undefined;

        orderDetails.push({
          label: key,
          value: `${value}`,
        });
      });

      details.push(orderDetails);
    });

    return details;
  };

  const sortedBonafidePatientOrderInformation =
    currentBonafidePatientOrderInformation?.sort(
      (a, b) =>
        (parseBonafideDate(a?.orderdate)?.getTime() || Infinity) -
        (parseBonafideDate(b?.orderdate)?.getTime() || Infinity),
    ) || [];

  return {
    currentBonafidePatientOrderInformation: sortedBonafidePatientOrderInformation,
    currentBonafidePatientOrderInformationDetails: currentBonafidePatientOrderInformationDetails(),
  };
};

export default useCurrentBonafidePatientOrderInformation;
