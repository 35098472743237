import React, { FC } from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { useStore } from 'react-redux';
import {
  selectUserLoginResponseData,
  selectCurrentUserDetailsData,
} from '../../../store/UserDetailsDuck/duck/selector';
import { RegisterUserDetails } from '../../../models';

interface PrivateRouteProps extends RouteProps {
  component: FC<RouteComponentProps>;
  accessLevels?: RegisterUserDetails['accessLevel'][];
}

export const PrivateRoute = ({ component: Component, accessLevels, ...rest }: PrivateRouteProps): JSX.Element => {
  const store = useStore().getState();
  const loginDetails = selectUserLoginResponseData(store);
  const userDetails = selectCurrentUserDetailsData(store);

  const renderComponent = (routeProps: RouteComponentProps) => {
    // Redirect to home page if current access level is not whitelisted
    if (accessLevels && !(userDetails?.accessLevel && accessLevels.includes(userDetails?.accessLevel))) {
      return <Redirect to={{ pathname: '/home', state: { from: routeProps.location } }} />;
    }
    // Redirect to login page if unauthorized
    if (!(loginDetails?.token && loginDetails.status === 'ok')) {
      return <Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />;
    }
    return <Component {...routeProps} />;
  };

  return <Route {...rest} render={(routeProps) => renderComponent(routeProps)} />;
};

export default PrivateRoute;
