import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import isISOTimestamp from 'simply-utils/dist/dateTime/isISOTimestamp';

import { getBonafideCustomerDetails } from '../api';
import { LabelValue } from '../models';
import { CustomerInterface } from '../models/CustomerInterface';
import parseBonafideDate from '../utils/parseBonafideDate';

const DATE_FIELDS: (keyof CustomerInterface)[] = ['dateofbirth'];
type DateFields = typeof DATE_FIELDS;

interface ReturnType {
  currentBonafideCustomer: CustomerInterface | null;
  currentBonafideCustomerDetails: LabelValue[];
}

const useCurrentBonafideCustomerDetails = (customerId?: string | null): ReturnType => {
  const [currentBonafideCustomer, setCurrentBonafideCustomer] = useState<CustomerInterface | null>(null);

  useEffect(() => {
    if (customerId)
      getBonafideCustomerDetails(customerId).then((res) => {
        setCurrentBonafideCustomer(res.data);
      });
  }, [customerId]);

  const currentBonafideCustomerDetails = (): LabelValue[] => {
    if (!currentBonafideCustomer) return [] as LabelValue[];

    const details: LabelValue[] = [];
    Object.keys(currentBonafideCustomer || {}).forEach((key) => {
      let value = currentBonafideCustomer[key as keyof CustomerInterface] as any;

      if (isISOTimestamp(value as string)) {
        value = `${dayjs(new Date(value as string)).format('MM-DD-YYYY HH:mm:ss')}`;
      }

      if (value instanceof Date) value = `${dayjs(value ?? '').format('MM-DD-YYYY HH:mm:ss')}`;

      if (DATE_FIELDS.includes(key as DateFields[number])) {
        value = `${dayjs(parseBonafideDate(value as string)).format('MM-DD-YYYY')}`;
      }

      if (value === '') value = undefined;

      details.push({
        label: key,
        value: `${value}`,
      });
    });

    return details;
  };

  return {
    currentBonafideCustomer,
    currentBonafideCustomerDetails: currentBonafideCustomerDetails(),
  };
};

export default useCurrentBonafideCustomerDetails;
