import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import Config from 'Config';
import { setToken } from './api/axios';
import MainRouter from './router';
import { store, persistor } from './store';

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    const token = store.getState().userDetails.loginResponse?.token;
    if (token) setToken(token);
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <MainRouter />
          {Config.nodeEnv === 'development' ? <ReactQueryDevtools initialIsOpen={false} /> : ''}
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
