import React, { memo, useCallback, useMemo, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { InputTextField } from 'components/InputTextField/InputTextField';
import RadioField from 'components/RadioField/RadioField';
import MeasurementInputGroup from 'components/MeasurementInputGroup/MeasurementInputGroup';
import DatePicker from 'components/DatePicker/DatePicker';
import i18nNamespaces from '../../../i18n/i18nNamespaces';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './BodyMeasurementRecord.scss';
import FormSection from '../../FormSection/FormSection';
import useMeasurementOptions from '../../../hooks/useMeasurementOptions';
import getFormYesNoOptions from '../../../utils/getFormYesNoOptions';
import { dataMapWithUnit, skipUnitFields, withUnit } from '../../../utils/unitUtils';

interface Props<T = any> extends FormComponentProps<T> {
  datePlaceholder?: string;
  firstNamePlaceholder?: string;
  lastNamePlaceholder?: string;
  middleInitialPlaceholder?: string;
}

export const BodyMeasurementRecord = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    datePlaceholder,
    firstNamePlaceholder,
    lastNamePlaceholder,
    middleInitialPlaceholder,
  }) => {
    const { t: tLocalForm } = useTranslation(i18nNamespaces.FORM);
    const { getMeasurementOptions: getLengthUnitsOptions, tMeasurement } = useMeasurementOptions('length');
    const { getMeasurementOptions: getSmallLengthsUnitsOptions } = useMeasurementOptions('length', ['cm', 'in']);
    const { getMeasurementOptions: getBodyWeightUnitsOptions } = useMeasurementOptions('massWeight', ['kg', 'lb']);

    const yesNoOptions = useMemo(() => getFormYesNoOptions(tForm), [tForm]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        patientLastName: form?.lastName ?? '',
        patientFirstName: form?.firstName ?? '',
        patientMiddleInitial: form?.middleInitial ?? '',
        previousObtainedDate: format(new Date(), 'yyyy-MM-dd'),
      } as unknown as { [key: string]: string },
    });
    const painValue = watch('pain');

    const onSubmitWithDataMapping = useCallback(
      (data) => {
        const { fitterMust, mastectomy, braces, compression, ...rest } = data;
        const mappedData = {
          ...skipUnitFields(rest),
          // Map fitterMust checkboxes
          ...Object.values(fitterMust as string[]).reduce<Record<string, boolean>>(
            (acc, v) => ({ ...acc, [v]: true }),
            {},
          ),
          weight: withUnit('weight', data),
          height: withUnit('height', data),
          mastectomy: {
            ...skipUnitFields(mastectomy),
            upperBreast: withUnit('upperBreast', mastectomy),
            underBreast: withUnit('underBreast', mastectomy),
          },
          braces: dataMapWithUnit(braces),
          compression: dataMapWithUnit(compression),
        };
        return onSubmit(mappedData);
      },
      [onSubmit],
    );

    const renderMeasurementInput = (
      type: 'length' | 'smallLength' | 'bodyWeight',
      name: string,
      unitName: string,
      label?: string,
      exampleNum?: string | number,
      required = true,
    ) => {
      const numberValue = watch(name);
      const unitValue = watch(unitName);
      const getMeasurementOptions = (() => {
        switch (type) {
          case 'bodyWeight':
            return getBodyWeightUnitsOptions;
          case 'smallLength':
            return getSmallLengthsUnitsOptions;
          case 'length':
          default:
            return getLengthUnitsOptions;
        }
      })();
      const unitPlaceholderTKey = (() => {
        switch (type) {
          case 'bodyWeight':
            return 'kilogram_plural';
          case 'smallLength':
            return 'inch_plural';
          case 'length':
          default:
            return 'centimeter_plural';
        }
      })();
      const autoSelectedUnit = (() => {
        switch (type) {
          case 'bodyWeight':
            return 'kg';
          case 'smallLength':
            return 'in';
          case 'length':
          default:
            return 'cm';
        }
      })();
      return (
        <MeasurementInputGroup
          name={name}
          unitName={unitName}
          numberValue={numberValue}
          unitValue={unitValue}
          setValue={setValue}
          register={register}
          getMeasurementOptions={getMeasurementOptions}
          label={label ?? tForm(name)}
          placeholder={exampleNum !== undefined ? `${tLocalForm('eg')} ${exampleNum}` : ''}
          unitPlaceholder={`${tLocalForm('eg')} ${tMeasurement(unitPlaceholderTKey)}`}
          errors={errors}
          required={required}
          autoSelectedUnit={autoSelectedUnit}
        />
      );
    };

    const renderBasicInfoSection = () => (
      <FormSection>
        <InputTextField {...register('customerId')} label={tForm('id')} type="text" status="disabled" />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        <div className="BodyMeasurementRecord-field-name">
          <div className="BodyMeasurementRecord-field-name-label">{tForm('patientName')}</div>
          <div className="BodyMeasurementRecord-field-name-subfields">
            <InputTextField
              {...register('patientLastName')}
              label={tForm('lastName')}
              placeholder={lastNamePlaceholder}
              type="text"
              status="disabled"
            />
            <InputTextField
              {...register('patientFirstName')}
              label={tForm('firstName')}
              placeholder={firstNamePlaceholder}
              type="text"
              status="disabled"
            />
            <InputTextField
              {...register('patientMiddleInitial')}
              label={tForm('middleName')}
              placeholder={middleInitialPlaceholder}
              type="text"
              status="disabled"
            />
          </div>
        </div>
        <RadioField
          {...register('fitterMust', { required: true })}
          label={tForm('fitterMust')}
          options={[
            { label: tForm('washHands'), value: 'hasWashedHands', required: true },
            { label: tForm('newGloves'), value: 'HasUsedNewGlove', required: true },
          ]}
          disabled={loading}
          status={errors?.fitterMust ? 'error' : 'active'}
          type="checkbox"
        />
        <InputTextField
          {...register('itemPrescribed', { required: true })}
          label={tForm('itemPrescribed')}
          type="text"
          maxLength={100}
          status={errors?.itemPrescribed ? 'error' : 'active'}
        />
        <InputTextField
          {...register('prescriber', { required: true })}
          label={tForm('prescriber')}
          type="text"
          maxLength={50}
          status={errors?.prescriber ? 'error' : 'active'}
        />
      </FormSection>
    );

    const renderAssessmentSection = () => {
      const hasPain = painValue === 'yes';
      return (
        <FormSection title={tForm('assessment')}>
          <RadioField
            {...register('pain', { required: true })}
            label={tForm('pain')}
            options={yesNoOptions}
            disabled={loading}
            status={errors?.pain ? 'error' : 'active'}
          />
          <InputTextField
            {...register('painDuration', { required: hasPain })}
            label={`${tForm('howLong')}?`}
            type="text"
            maxLength={50}
            status={errors?.painDuration ? 'error' : hasPain ? 'active' : 'disabled'}
          />
          <InputTextField
            {...register('painPosition', { required: hasPain })}
            label={`${tForm('where')}?`}
            type="text"
            maxLength={50}
            status={errors?.painPosition ? 'error' : hasPain ? 'active' : 'disabled'}
          />
          {renderMeasurementInput('bodyWeight', 'weight', 'weightUnit', tForm('weight'), 70)}
          {renderMeasurementInput('length', 'height', 'heightUnit', tForm('height'), 180)}
          <InputTextField
            {...register('otherRelevantConditions')}
            label={tForm('otherRelevantConditions')}
            type="text"
            maxLength={80}
            status={errors?.otherRelevantConditions ? 'error' : 'active'}
          />
          <InputTextField
            {...register('additionalClinicalDocuments')}
            label={tForm('additionalClinicalDocuments')}
            placeholder={`${tForm('eg')} ${tForm('xRayReports')}`}
            type="text"
            maxLength={50}
            status={errors?.additionalClinicalDocuments ? 'error' : 'active'}
          />
        </FormSection>
      );
    };

    const renderMeasurementsForMastectomySuppliesSection = () => {
      const ns = 'mastectomy';
      const breastSurgeryOptions = [
        { label: tForm('right'), value: 'right' },
        { label: tForm('left'), value: 'left' },
        { label: tForm('bl'), value: 'bl' },
      ];
      return (
        <FormSection title={tForm('measurementsForMastectomySupplies')}>
          <RadioField
            {...register(`${ns}.breastSurgeryOn`, { required: true })}
            label={tForm('breastSurgeryOn')}
            options={breastSurgeryOptions}
            disabled={loading}
            status={errors?.breastSurgeryOn ? 'error' : 'active'}
          />
          {renderMeasurementInput('smallLength', `${ns}.upperBreast`, `${ns}.upperBreastUnit`, tForm('upperbreast'))}
          {renderMeasurementInput('smallLength', `${ns}.underBreast`, `${ns}.underBreastUnit`, tForm('underbreast'))}
          <InputTextField
            {...register(`${ns}.itemSelectedAndSize`, { required: true })}
            label={tForm('itemSelectedSize')}
            type="text"
            maxLength={100}
            status={errors?.itemSelectedAndSize ? 'error' : 'active'}
          />
        </FormSection>
      );
    };

    const renderSubSection = (title: string, children: ReactNode) => (
      <div className="BodyMeasurementRecord-subsection">
        <div className="BodyMeasurementRecord-subsection-title">{title}</div>
        <div className="BodyMeasurementRecord-subsection-content">{children}</div>
      </div>
    );

    const renderMeasurementsForBracesSection = () => {
      const ns = 'braces';
      return (
        <FormSection title={tForm('measurementsForBraces')}>
          {renderSubSection(
            `${tForm('cervicalCollar')}:`,
            renderMeasurementInput('smallLength', `${ns}.neck`, `${ns}.neckUnit`, tForm('neck')),
          )}
          {renderSubSection(
            `${tForm('backSupports')}:`,
            <>
              {renderMeasurementInput('smallLength', `${ns}.backWaist`, `${ns}.backWaistUnit`, tForm('waist'))}
              {renderMeasurementInput('smallLength', `${ns}.backHip`, `${ns}.backHipUnit`, tForm('hip'))}
              {renderMeasurementInput(
                'smallLength',
                `${ns}.backWaistToHip`,
                `${ns}.backWaistToHipUnit`,
                tForm('waistToHip'),
              )}
            </>,
          )}
          {renderSubSection(
            `${tForm('postureBrace')}:`,
            <>
              {renderMeasurementInput('smallLength', `${ns}.postureWaist`, `${ns}.postureWaistUnit`, tForm('waist'))}
              {renderMeasurementInput('smallLength', `${ns}.postureHip`, `${ns}.postureHipUnit`, tForm('hip'))}
            </>,
          )}
          {renderSubSection(
            `${tForm('wristSupports')} / ${tForm('thumbSpica')}:`,
            renderMeasurementInput(
              'smallLength',
              `${ns}.wristCircumference`,
              `${ns}.wristCircumferenceUnit`,
              tForm('wristCircumference'),
            ),
          )}
          {renderSubSection(
            `${tForm('kneeSupports')}:`,
            <>
              {renderMeasurementInput(
                'smallLength',
                `${ns}.patellaCircumference`,
                `${ns}.patellaCircumferenceUnit`,
                `${tForm('circumference')}: ${tForm('patella')} (${tForm('kneeCap')})`,
              )}
              {renderMeasurementInput(
                'smallLength',
                `${ns}.patellaCircumferenceAbove`,
                `${ns}.patellaCircumferenceAboveUnit`,
                `2" ${tForm('above')}`,
              )}
              {renderMeasurementInput(
                'smallLength',
                `${ns}.patellaCircumferenceBelow`,
                `${ns}.patellaCircumferenceBelowUnit`,
                `2" ${tForm('below')}`,
              )}
            </>,
          )}
          {renderSubSection(
            `${tForm('ankleSupports')}:`,
            renderMeasurementInput('smallLength', `${ns}.ankle`, `${ns}.ankleUnit`, tForm('ankle')),
          )}
        </FormSection>
      );
    };

    const renderMeasurementsForCompressionStockingsSection = () => {
      const ns = 'compression';
      return (
        <FormSection title={tForm('measurementsForCompressionStockings')}>
          {renderSubSection(
            `${tForm('kneeHigh')}:`,
            <>
              {renderMeasurementInput('smallLength', `${ns}.kneeHighAnkle`, `${ns}.kneeHighAnkleUnit`, tForm('ankle'))}
              {renderMeasurementInput('smallLength', `${ns}.kneeHighCalf`, `${ns}.kneeHighCalfUnit`, tForm('calf'))}
            </>,
          )}
          {renderSubSection(
            `${tForm('thighLength')}:`,
            <>
              {renderMeasurementInput(
                'smallLength',
                `${ns}.thighLengthAnkle`,
                `${ns}.thighLengthAnkleUnit`,
                tForm('ankle'),
              )}
              {renderMeasurementInput(
                'smallLength',
                `${ns}.thighLengthCalf`,
                `${ns}.thighLengthCalfUnit`,
                tForm('calf'),
              )}
              {renderMeasurementInput('smallLength', `${ns}.midThigh`, `${ns}.midThighUnit`, tForm('midThigh'))}
              {renderMeasurementInput('smallLength', `${ns}.highThigh`, `${ns}.highThighUnit`, tForm('highThigh'))}
            </>,
          )}
          {renderSubSection(
            `${tForm('pantyHose')}:`,
            <>
              {renderMeasurementInput(
                'smallLength',
                `${ns}.pantyHoseWaist`,
                `${ns}.pantyHoseWaistUnit`,
                tForm('waist'),
              )}
              {renderMeasurementInput(
                'smallLength',
                `${ns}.pantyHoseHeight`,
                `${ns}.pantyHoseHeightUnit`,
                tForm('height'),
              )}
            </>,
          )}
        </FormSection>
      );
    };

    const renderSignOffSection = () => {
      const hasObtainedSimilarItemBefore = watch('obtainedSimilarItemBefore') === 'yes';
      const previousObtainedDateValue = watch(`previousObtainedDate`);
      const [yesOption, noOption] = yesNoOptions;
      const obtainedSimilarItemBeforeOptions = [
        {
          ...yesOption,
          nodeAfter: (
            <DatePicker
              {...register(`previousObtainedDate`, { required: hasObtainedSimilarItemBefore })}
              forceHideLabel
              placeholder="Select date..."
              maxDate={new Date()}
              date={new Date(previousObtainedDateValue)}
              onChange={(date: Date) => setValue('previousObtainedDate', format(date, 'yyyy-MM-dd'))}
              status={hasObtainedSimilarItemBefore ? 'active' : 'disabled'}
              disabled={!hasObtainedSimilarItemBefore}
              className="BodyMeasurementRecord-input-date-picker"
              calendarAlign="left"
            />
          ),
          className: 'BodyMeasurementRecord-radio-with-input',
        },
        noOption,
      ];
      return (
        <FormSection>
          <RadioField
            {...register('obtainedSimilarItemBefore', { required: true })}
            label={tForm('obtainedSimilarItemBefore')}
            options={obtainedSimilarItemBeforeOptions}
            disabled={loading}
            status={errors?.obtainedSimilarItemBefore ? 'error' : 'active'}
          />
          <InputTextField
            {...register('itemSelected', { required: true })}
            label={tForm('itemSelectedThisTime')}
            type="text"
            maxLength={80}
            status={errors?.itemSelected ? 'error' : 'active'}
          />
          <InputTextField
            {...register('comment')}
            label={tForm('comment')}
            type="textarea"
            maxLength={160}
            status={errors?.comment ? 'error' : 'active'}
          />
          <InputTextField
            {...register('by', { required: true })}
            label={tForm('by')}
            type="text"
            maxLength={50}
            status={errors?.by ? 'error' : 'active'}
          />
        </FormSection>
      );
    };

    return (
      <FormDetailsLayout
        className="BodyMeasurementRecord"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmitWithDataMapping)}
        loading={loading}
        submitError={submitError}
      >
        {renderBasicInfoSection()}
        {renderAssessmentSection()}
        {renderMeasurementsForMastectomySuppliesSection()}
        {renderMeasurementsForBracesSection()}
        {renderMeasurementsForCompressionStockingsSection()}
        {renderSignOffSection()}
      </FormDetailsLayout>
    );
  },
);

BodyMeasurementRecord.displayName = 'BodyMeasurementRecord';

export default BodyMeasurementRecord;
