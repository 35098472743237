import copy from 'copy-to-clipboard';
import React, { memo, ReactNode, useCallback } from 'react';
import { Card, CTAButton } from '../../components';
import './ManageUserLayout.scss';

interface Props {
  children: ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  responseContent: string | null;
  loading?: boolean;
}

export const ManageUserLayout = memo<Props>(({ children, onSubmit, responseContent, loading }) => {
  return (
    <Card className="ManageUserLayout">
      <form className="ManageUserLayout-form" onSubmit={onSubmit}>
        {children}
      </form>
      <div className="ManageUserLayout-response">
        <div className="ManageUserLayout-response-content">
          <code>{responseContent}</code>
        </div>
        <CTAButton
          disabled={loading}
          onClick={useCallback(() => copy(responseContent || ''), [responseContent])}
          invert
          type="button"
          text="Copy to clipboard"
        />
      </div>
    </Card>
  );
});

ManageUserLayout.displayName = 'ManageUserLayout';

export default ManageUserLayout;
