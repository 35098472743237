import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import { MenuItem } from '../../models';
import './Users.scss';

const Users = (): JSX.Element => {
  const menuItems: MenuItem[] = [
    {
      path: '/users/all-users',
      icon: '/assets/icons/users.svg',
      title: 'View All Users',
      description: 'List of all iPortal users',
    },
    {
      path: '/users/register-user',
      icon: '/assets/icons/user.svg',
      title: 'Register User',
      description: 'Create a new user account',
    },
    {
      path: '/users/reset-user-password',
      icon: '/assets/icons/reset.svg',
      title: 'Reset User Password',
      description: 'Generate a new password for a user',
    },
  ];

  return (
    <DashboardLayout title="Users">
      <MenuGrid menuItems={menuItems} />
    </DashboardLayout>
  );
};

export default Users;
