import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import i18next from 'i18next';

import * as SendFormsAndDocumentsActions from 'store/SendFormsAndDocumentsDuck/duck/action';
import { postSubmitForm } from '../../../../../../../api';
import { SubmitFormDetails } from '../../../../../../../models/SubmitFormDetails';
import FormDetailsContext from '../context/FormDetailsContext';
import i18nNamespaces from '../i18n/i18nNamespaces';
import getFormI18nNamespace from '../utils/getFormI18nNamespace';

interface Props {
  fetchFormContent: (
    i18n: typeof i18next,
    formComponent: string,
    version: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SendFormsAndDocumentsActions.ActionType>;
  isPrefill?: boolean;
}

const useFormHandlers = ({ fetchFormContent, isPrefill = false }: Props) => {
  const { currentForm: form } = useContext(FormDetailsContext);
  const history = useHistory();

  const version = form?.version ?? 'v1';
  const formI18nNamespace = getFormI18nNamespace(form);
  const { t: tForm, i18n } = useTranslation(formI18nNamespace);
  const { t: tLocalForm } = useTranslation(i18nNamespaces.FORM);

  /** ------------------------------- API Calling States ------------------------------- */
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /** ------------------------------- Get Content ------------------------------- */
  const [loadingContent, setLoadingContent] = useState(false);

  useEffect(() => {
    if (!form?.formComponent) return;
    setLoadingContent(true);
    const finallyCallback = () => setLoadingContent(false);
    // @TODO: Handle error
    fetchFormContent(i18n, form?.formComponent, version, finallyCallback, finallyCallback);
  }, [fetchFormContent, form, i18n, version]);

  /** ------------------------------- Call Submit API ------------------------------- */
  const onSubmit = useCallback(
    (data: SubmitFormDetails['data']) => {
      setSubmitting(true);
      postSubmitForm(form?.id, { data, isPrefill })
        .then((res) => {
          if (res.status === 'ok') history.go(0);
        })
        .catch((err) => {
          setError(err.error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [form?.id, history, isPrefill],
  );

  return { form, onSubmit, tForm, tLocalForm, loadingContent, submitting, error, i18n };
};

export default useFormHandlers;
