import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectUserDetails = (state: RootState): State => state.userDetails;

export const selectUserLoginResponseData = createSelector(
  selectUserDetails,
  (userDetails) => userDetails.loginResponse,
);

export const selectCurrentUserDetailsData = createSelector(
  selectUserDetails,
  (userDetails) => userDetails.currentUserDetails,
);

export const selectUserToken = createSelector(selectUserLoginResponseData, (loginResponse) => loginResponse?.token);
