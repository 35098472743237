import React, { memo } from 'react';
import Modal, { Styles, Props as ModalProps } from 'react-modal';
import './LangSwitchModal.scss';
import { Locale, LanguageListInterface } from '../../models';

interface Props extends ModalProps {
  currentLang: Locale;
  langList: LanguageListInterface[];
  handleLanguageChange: (lang: Locale) => void;
}

export const LangSwitchModal = memo<Props>(
  ({ currentLang, langList, handleLanguageChange, onRequestClose, ...restProps }) => {
    const modalStyle: Styles = {
      ...restProps.style,
      content: {
        width: Math.min(300, window.innerWidth - 40),
        height: 'fit-content',
        borderRadius: 20,
        margin: 'auto',
        left: 0,
        right: 0,
        padding: 0,
        ...restProps.style?.content,
      },
      overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, ...restProps.style?.overlay },
    };

    return (
      <Modal {...restProps} style={modalStyle} onRequestClose={onRequestClose}>
        <ul className="LangSwitchModal-list">
          {langList.map((lang) => {
            return (
              <li
                key={lang.code as string}
                className={`LangSwitchModal-list-item ${currentLang === lang.code ? 'mod-active' : ''}`}
              >
                <button
                  type="button"
                  className="LangSwitchModal-list-item-button"
                  onClick={(e) => {
                    handleLanguageChange(lang.code as Locale);
                    if (onRequestClose) onRequestClose(e);
                  }}
                >
                  {lang.label}
                </button>
              </li>
            );
          })}
        </ul>
      </Modal>
    );
  },
);

LangSwitchModal.displayName = 'LangSwitchModal';

export default LangSwitchModal;
