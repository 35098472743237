import { RootState } from 'MyTypes';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ApplicationVersion } from '..';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import NavigationLink from './components/NavigationLink';
import './MainNavigation.scss';

interface Props {
  currentUser: CurrentUserDetails | null;
}

const MainNavigation = memo<Props>(({ currentUser }) => {
  const { device } = useWindowDimensions();

  const [showContents, setShowContents] = useState(device === 'desktop');
  const [openMainNavigation, setOpenMainNavigation] = useState<'open' | 'close'>(
    device === 'desktop' ? 'open' : 'close',
  );

  const history = useHistory();
  const currentPath = history.location.pathname;

  const currentAccessLevel = currentUser?.accessLevel ?? '';

  // Prevent looking like conditional rendering
  const routes = !currentAccessLevel
    ? []
    : [
        {
          path: '/home',
          icon: '/assets/icons/home.svg',
          label: 'Home',
        },
        {
          path: '/users',
          icon: '/assets/icons/users.svg',
          label: 'Users',
          accessLevels: ['admin'],
          subRoutes: [
            {
              path: '/users/all-users',
              label: 'View All Users',
            },
            {
              path: '/users/register-user',
              label: 'Register User',
            },
            {
              path: '/users/reset-user-password',
              label: 'Reset User Password',
            },
          ],
        },
        {
          path: '/self-service-portal',
          icon: '/assets/icons/package.svg',
          label: 'Self Service Portal',
          accessLevels: ['admin', 'user'],
          subRoutes: [
            {
              path: '/self-service-portal/all-users',
              label: 'View All Users',
            },
            {
              path: '/self-service-portal/appointments',
              label: 'Appointments',
            },
            {
              path: '/self-service-portal/create-appointment/select-patient',
              label: 'Create Appointment',
            },
            {
              path: '/self-service-portal/manage-appointment-timeslots',
              label: 'Manage Appointment Timeslots',
            },
            {
              path: '/self-service-portal/forms-and-documents',
              label: 'View All Users Forms & Documents',
            },
            {
              path: '/self-service-portal/send-forms-and-documents/select-patient',
              label: 'Send Forms & Documents',
            },
          ],
        },
        {
          path: '/reports',
          icon: '/assets/icons/table.svg',
          label: 'Reports',
        },
        {
          path: '/analytics',
          icon: '/assets/icons/analytics.svg',
          label: 'Analytics',
          subRoutes: [
            {
              path: '/analytics/patient-orders-heatmap',
              label: 'Patient Orders Heatmap',
            },
            {
              path: '/analytics/ssp-users-metrics',
              label: 'Self Service Portal Users Metrics',
            },
          ],
        },
        {
          path: '/settings',
          icon: '/assets/icons/settings.svg',
          label: 'Settings',
          subRoutes: [
            {
              path: '/settings/change-user-password',
              label: 'Change Password',
            },
            {
              path: '/',
              label: 'Logout',
            },
          ],
        },
      ].filter((route) => {
        if (route.accessLevels && !route.accessLevels.includes(currentAccessLevel)) return false;
        return true;
      });

  const handleBurgerMenuClick = useCallback(() => {
    setOpenMainNavigation(openMainNavigation === 'open' ? 'close' : 'open');

    if (openMainNavigation !== 'open') {
      setTimeout(() => {
        setShowContents(true);
      }, 400);
    } else {
      setShowContents(false);
    }
  }, [openMainNavigation]);

  useEffect(() => {
    if (device === 'desktop') {
      setOpenMainNavigation('open');
      setShowContents(true);
    }
  }, [device]);

  return (
    <>
      <div className={`MainNavigation mod-${openMainNavigation}`}>
        <div className="MainNavigation-burger-menu">
          <Hamburger
            color="#11a3b5"
            toggled={openMainNavigation === 'open'}
            size={25}
            onToggle={handleBurgerMenuClick}
          />
        </div>
        {showContents ? (
          <>
            <div className="MainNavigation-container">
              {routes.map((route) => (
                <NavigationLink
                  key={route.path}
                  activeSubRoute={route.subRoutes?.find((x) => currentPath.includes(x.path)) || null}
                  active={currentPath.startsWith(route.path)}
                  {...route}
                />
              ))}
            </div>
            <ApplicationVersion opacity="0.3" />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

MainNavigation.displayName = 'MainNavigation';

const mapStateToProps = (state: RootState) => ({
  currentUser: state.userDetails.currentUserDetails,
});

export default connect(mapStateToProps)(MainNavigation);
