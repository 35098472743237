import React, { Fragment, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavigationRoutes, SubRoutes } from '../../../models';

interface Props extends NavigationRoutes {
  active: boolean;
  activeSubRoute: SubRoutes | null;
}

const NavigationLink = memo<Props>(({ path, icon, label, subRoutes, active, activeSubRoute }) => {
  const [openSubRoutes, setOpenSubRoutes] = useState(true);

  return (
    <>
      <Link
        key={path}
        className={`MainNavigation-link ${active ? 'mod-active' : ''} ${
          active && openSubRoutes ? 'mod-openSubRoutes' : ''
        }`}
        to={path}
      >
        <div
          style={{
            mask: `url(${icon}) no-repeat center`,
            WebkitMask: `url(${icon}) no-repeat center`,
          }}
          className="MainNavigation-link-icon"
        />
        <span>{label}</span>
        {subRoutes && (
          <input
            onClick={() => setOpenSubRoutes(!openSubRoutes)}
            className={`MainNavigation-link-btn-dropdown ${active ? 'mod-active' : ''} ${
              active && openSubRoutes ? 'mod-openSubRoutes' : ''
            }`}
            type="button"
          />
        )}
      </Link>
      {openSubRoutes && active && (
        <div className="MainNavigation-link-subRoutes">
          {subRoutes?.map((subRoute) => (
            <Fragment key={subRoute.path}>
              <div className="subRoute-line" />
              <Link
                to={subRoute.path}
                className={`subRoute ${activeSubRoute?.path === subRoute.path ? 'mod-active' : ''}`}
                key={subRoute.label}
              >
                <div
                  style={{
                    mask: `url(/assets/icons/bullet.svg) no-repeat center`,
                    WebkitMask: `url(/assets/icons/bullet.svg) no-repeat center`,
                    WebkitMaskSize: '35%',
                  }}
                  className="subRoute-dot"
                />
                <span>{subRoute.label}</span>
              </Link>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
});

NavigationLink.displayName = 'NavigationLink';

export default NavigationLink;
