import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { Report, ReportsListResponse } from 'models/Report';
import * as ReportsActions from './action';
import { getAllReports } from '../../../api';

export function* handleGetReports(
  action: ReportsActions.ActionType,
  successAction: (FETCH_REPORTS_SUCCESS: Report[]) => ReportsActions.ActionType,
  failAction: (error?: string | undefined) => ReportsActions.ActionType,
): Generator<CallEffect<ReportsListResponse> | PutEffect<Action<string>>, void, ReportsListResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getAllReports);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetReports(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(ReportsActions.FETCH_REPORTS, (action: ReportsActions.ActionType) =>
    handleGetReports(action, ReportsActions.fetchReportsSuccess, ReportsActions.fetchReportsFail),
  );
}
