import React from 'react';
import { Card, CTAButton, Stepper } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import './Layout.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  className: string;
  currentStep: number;
  ctaLabel?: string;
  ctaType?: 'button' | 'submit';
  disableCta?: boolean;
  overflow?: true;
  handleCtaClick?: () => void;
}

const Layout = ({
  children,
  className,
  currentStep,
  ctaLabel,
  ctaType,
  disableCta,
  overflow,
  handleCtaClick,
}: Props): JSX.Element => (
  <DashboardLayout title="Create Appointment">
    <Card className={`CreateAppointment-layout ${overflow ? 'mod-overflow' : ''}`}>
      <Stepper
        currentStep={currentStep}
        steps={['Select Patient', 'Select Date and Time', 'Review & Submit', 'Completed']}
      />
      <div className={`CreateAppointment-layout-children ${className}`}>{children}</div>
      {ctaLabel && (
        <div className="CreateAppointment-layout-cta-button-container">
          <CTAButton
            type={ctaType}
            disabled={disableCta}
            onClick={handleCtaClick}
            className="CreateAppointment-layout-cta-button"
            text={ctaLabel}
          />
        </div>
      )}
    </Card>
  </DashboardLayout>
);

export default Layout;
