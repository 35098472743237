import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import './Home.scss';
import useMenuItems from './hooks/useMenuItems';

const Home = (): JSX.Element => {
  const { menuItems, loadingStatus } = useMenuItems();

  return (
    <DashboardLayout title="Home">
      <div className="Home">
        <div className="Home-service-status">
          <h2>API Service Status</h2>
          <MenuGrid loading={loadingStatus} menuItems={menuItems} />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Home;
