import React, { useCallback, useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { Card } from 'components';
import { DashboardLayout } from 'layouts';
import './Reports.scss';
import { downloadReport } from 'api';
import { ClipLoader } from 'react-spinners';
import { RootState } from 'MyTypes';
import { Report } from 'models/Report';
import * as ReportsActions from 'store/ReportsDuck/duck/action';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectReportsList } from 'store/ReportsDuck/duck/selector';

interface Props {
  reports: Report[] | null;
  fetchReports: (successCallback?: () => void, errorCallback?: () => void) => Promise<ReportsActions.ActionType>;
}

const Reports = ({ reports, fetchReports }: Props) => {
  const [loadingList, setLoadingList] = useState<string[]>([]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const handleDownloadReport = useCallback(
    (id) => {
      setLoadingList([...loadingList, id]);
      downloadReport(id)
        .then(({ data: { fileName, csv } }) => {
          fileDownload(csv, fileName);
        })
        .finally(() => {
          setLoadingList(loadingList.filter((item) => item !== id));
        });
    },
    [loadingList],
  );

  const Loading = (): JSX.Element => (
    <div className="Reports-loading">
      <ClipLoader color="#06bbc9" />
    </div>
  );

  return (
    <DashboardLayout title="Reports">
      <div className="Reports-container">
        {reports ? (
          reports.map(({ id, title, description }) => (
            <Card key={id} className="Reports-item" onClick={() => handleDownloadReport(id)}>
              <div className="Reports-item-content">
                <span>{title}</span>
                <span>{description}</span>
              </div>
              {loadingList.includes(id) && (
                <div className="Reports-item-loading">
                  <ClipLoader color="#92e8ee" />
                </div>
              )}
            </Card>
          ))
        ) : (
          <Loading />
        )}
      </div>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'reports'> => ({
  reports: selectReportsList(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchReports'> => ({
  fetchReports: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await ReportsActions.fetchReports(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
