import React, { FC, ReactNode } from 'react';
import MainNavigation from '../../components/MainNavigation/MainNavigation';

import TopBar from './components/TopBar/TopBar';
import './DashboardLayout.scss';

interface Props {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  toolbar?: JSX.Element;
}

const DashboardLayout: FC<Props> = ({ title, subtitle, children, toolbar }) => {
  return (
    <div className="DashboardLayout">
      <MainNavigation />
      <div className="DashboardLayout-container">
        <TopBar />
        <div className="DashboardLayout-container-heading">
          <div className="DashboardLayout-titles">
            {title && <h1 className={`DashboardLayout-title ${subtitle ? '' : 'mod-titleMargin'}`}>{title}</h1>}
            {subtitle && (
              <h2 className={`DashboardLayout-subtitle ${subtitle ? 'mod-titleMargin' : ''}`}>{subtitle}</h2>
            )}
          </div>
          {toolbar && (
            <div className="DashboardLayout-toolbar">
              <div className="DashboardLayout-toolbar-container">{toolbar}</div>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

DashboardLayout.displayName = 'DashboardLayout';

export default DashboardLayout;
