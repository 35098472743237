import React from 'react';
import { MenuGrid } from '../../components';
import { DashboardLayout } from '../../layouts';
import { MenuItem } from '../../models';
import './SelfServicePortal.scss';

const SelfServicePortal = (): JSX.Element => {
  const menuItems: MenuItem[] = [
    {
      path: '/self-service-portal/all-users',
      icon: '/assets/icons/users.svg',
      title: 'View All Users',
      description: 'List of all Self Service Portal users',
    },
    {
      path: '/self-service-portal/appointments',
      icon: '/assets/icons/calendar.svg',
      title: 'Appointments',
      description: 'View and manage patient appointments',
    },
    {
      path: '/self-service-portal/create-appointment/select-patient',
      icon: '/assets/icons/clock.svg',
      title: 'Create Appointment',
      description: 'Create an appointment to selected patient',
    },
    {
      path: '/self-service-portal/manage-appointment-timeslots',
      icon: '/assets/icons/watch.svg',
      title: 'Manage Appointment Timeslots',
      description: 'Create, edit or delete appointment timeslots',
    },
    {
      path: '/self-service-portal/forms-and-documents',
      icon: '/assets/icons/folder.svg',
      title: 'View All Users Forms & Documents',
      description: 'List of all Users Forms & Documents',
    },
    {
      path: '/self-service-portal/send-forms-and-documents/select-patient',
      icon: '/assets/icons/document.svg',
      title: 'Send Forms And Documents',
      description: 'Send forms and documents to selected patient',
    },
  ];

  return (
    <DashboardLayout title="Self Service Portal">
      <MenuGrid menuItems={menuItems} />
    </DashboardLayout>
  );
};

export default SelfServicePortal;
