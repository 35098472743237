import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nNamespaces from './i18nNamespaces';
import languages from './languages';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = Object.values(languages).reduce(
  (resObj, lng) => ({
    ...resObj,
    [lng]: Object.values(i18nNamespaces).reduce(
      (lngObj, namespace: string) => ({
        ...lngObj,
        // eslint-disable-next-line global-require, import/no-dynamic-require
        [namespace]: require(`./${lng}/${namespace}.json`) || {},
      }),
      {},
    ),
  }),
  {},
);

// To serve the case that FormDetails can be displayed in multiple languages,
// this i18next instance is isolated for FormDetails only.
// Reference: https://luxiyalu.com/how-to-have-multiple-instances-of-i18next-for-component-library/
const i18nInstance = i18n.use(initReactI18next).createInstance({
  resources,

  lng: languages.EN, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  fallbackLng: languages.EN,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  debug: process.env.NODE_ENV === 'development',
});

i18nInstance.init();

// Bind i18n to global scope for debug use
if (process.env.NODE_ENV === 'development') {
  // @ts-expect-error: Just for dev use
  window.formDetailsI18n = i18nInstance;
}

export default i18nInstance;
