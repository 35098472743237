import { ActionType } from 'typesafe-actions';
import { FormsAndDocumentsMap } from '../../../models/FormsAndDocumentsMap';
import { ViewAllUsersFormsInterface } from '../../../models/ViewAllUsersFormsInterface';
import { ViewSSPUserDetails } from '../../../models/ViewSSPUserDetails';
import * as SendFormsAndDocumentsActions from './action';

export interface State {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDocument: FormsAndDocumentsMap | null;
  selectedSspUserFormProps: { [sspUserId: string]: Partial<FormsAndDocumentsMap> };
  allUsersFormsAndDocuments: ViewAllUsersFormsInterface[] | null;
  searchUsersFormsResults: ViewAllUsersFormsInterface[] | null;
}

const initialState: State = {
  allUsersFormsAndDocuments: null,
  selectedSspUsers: [],
  selectedDocument: null,
  selectedSspUserFormProps: {},
  searchUsersFormsResults: null,
};

type SendFormsAndDocumentsActionsType = ActionType<typeof SendFormsAndDocumentsActions>;

export default function reducer(state: State = initialState, action: SendFormsAndDocumentsActionsType): State {
  switch (action.type) {
    case SendFormsAndDocumentsActions.CLEAR_SELECTED_SSP_USER_FORM_PROPS:
      return {
        ...state,
        selectedSspUserFormProps: initialState.selectedSspUserFormProps,
      };
    case SendFormsAndDocumentsActions.SET_SELECTED_SSP_USER_FORM_PROPS:
      return {
        ...state,
        selectedSspUserFormProps: {
          ...state.selectedSspUserFormProps,
          ...action?.payload?.selectedSspUserFormProps,
        },
      };
    case SendFormsAndDocumentsActions.CLEAR_SEARCH_USERS_FORMS_RESULTS:
      return {
        ...state,
        searchUsersFormsResults: initialState.searchUsersFormsResults,
      };
    case SendFormsAndDocumentsActions.CLEAR_SELECTED_SSP_USERS:
      return {
        ...state,
        selectedSspUsers: initialState.selectedSspUsers,
      };
    case SendFormsAndDocumentsActions.CLEAR_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocument: initialState.selectedDocument,
      };

    case SendFormsAndDocumentsActions.CLEAR_ALL_USERS_FORMS:
      return {
        ...state,
        allUsersFormsAndDocuments: [],
      };
    case SendFormsAndDocumentsActions.FETCH_APPEND_USERS_FORMS:
    case SendFormsAndDocumentsActions.FETCH_APPEND_USERS_FORMS_SUCCESS:
      return {
        ...state,
        allUsersFormsAndDocuments: [
          ...(state.allUsersFormsAndDocuments ?? []),
          ...(action.payload.allUsersFormsAndDocuments
            ? (action?.payload?.allUsersFormsAndDocuments as ViewAllUsersFormsInterface[])
            : []
          ).filter((value, index, self) => self.findIndex((x) => x.id === value.id) === index),
        ],
      };
    case SendFormsAndDocumentsActions.FETCH_SEARCH_USERS_FORMS_RESULTS:
    case SendFormsAndDocumentsActions.FETCH_SEARCH_USERS_FORMS_RESULTS_SUCCESS:
    case SendFormsAndDocumentsActions.FETCH_SEARCH_USERS_FORMS_RESULTS_FAIL:
    case SendFormsAndDocumentsActions.SET_SELECTED_SSP_USERS:
    case SendFormsAndDocumentsActions.SET_SELECTED_DOCUMENT:
    case SendFormsAndDocumentsActions.FETCH_ALL_USERS_FORMS:
    case SendFormsAndDocumentsActions.FETCH_ALL_USERS_FORMS_SUCCESS:
    case SendFormsAndDocumentsActions.FETCH_ALL_USERS_FORMS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
