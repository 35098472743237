import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CTAButton, InputTextField, InputDropdownField } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import { RegisterUserDetails } from '../../../../models';
import { postRegisterUser } from '../../../../api';
import ManageUserLayout from '../../../../layouts/ManageUserLayout/ManageUserLayout';

interface FormData extends RegisterUserDetails {
  confirmPassword: string;
}

const RegisterUser = (): JSX.Element => {
  const [registerResponse, setRegisterUserResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>();

  const validateConfirmPassword = (confirmPassword: string) => {
    const { password } = getValues();
    if (confirmPassword !== password) return 'Passwords do not match';
    return true;
  };

  const onSubmit = (data: FormData) => {
    setLoading(true);
    postRegisterUser(data)
      .then((res) => {
        setRegisterUserResponse(JSON.stringify(res.error || res.response));
        setLoading(false);
      })
      .catch((err) => {
        setRegisterUserResponse(err);
        setLoading(false);
      });
  };

  return (
    <DashboardLayout title="Register User">
      <ManageUserLayout onSubmit={handleSubmit(onSubmit)} responseContent={registerResponse} loading={loading}>
        <InputTextField
          label="Email address"
          placeholder="john.appleseed@nymannings.com"
          type="email"
          status={errors?.username ? 'error' : 'active'}
          {...register('username', { required: true })}
        />
        <InputTextField
          type="password"
          label="Password"
          minLength={10}
          placeholder="•••••••••••••••"
          disableAutoComplete
          status={errors?.password ? 'error' : 'active'}
          {...register('password', { required: true })}
        />
        <InputTextField
          type="password"
          label="Confirm Password"
          minLength={10}
          placeholder="•••••••••••••••"
          disableAutoComplete
          disablePaste
          bottomLabel={errors?.confirmPassword?.message}
          status={errors?.confirmPassword ? 'error' : 'active'}
          {...register('confirmPassword', {
            required: true,
            validate: validateConfirmPassword,
          })}
        />
        <InputDropdownField
          label="Access Level"
          placeholder="Select user access level"
          items={['user', 'admin']}
          status={errors?.accessLevel ? 'error' : 'active'}
          {...register('accessLevel', { required: true })}
        />
        <CTAButton type="submit" text={loading ? 'Registering...' : 'Register'} disabled={loading} />
      </ManageUserLayout>
    </DashboardLayout>
  );
};

export default RegisterUser;
