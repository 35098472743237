import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import * as AppointmentsActions from './action';
import { getAppointments } from '../../../api';
import { AppointmentResponse, AppointmentResponseData } from '../../../models/Appointment';

export function* handleGetUsersAppointments(
  action: AppointmentsActions.ActionType,
  successAction: (allAppointments: AppointmentResponseData[]) => AppointmentsActions.ActionType,
  failAction: (error?: string | undefined) => AppointmentsActions.ActionType,
): Generator<CallEffect<AppointmentResponse> | PutEffect<Action<string>>, void, AppointmentResponse> {
  const {
    payload: { month, year, successCallback, errorCallback },
  } = action;

  try {
    if (!month || !year) return;
    const { data } = yield call(getAppointments, month, year);
    if (!data) return;
    yield put(successAction(data));

    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetAllUsersAppointments(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(AppointmentsActions.FETCH_ALL_USERS_APPOINTMENTS, (action: AppointmentsActions.ActionType) =>
    handleGetUsersAppointments(
      action,
      AppointmentsActions.fetchUsersAppointmentsSuccess,
      AppointmentsActions.fetchUsersAppointmentsFail,
    ),
  );

  yield takeLatest(AppointmentsActions.FETCH_APPEND_USERS_APPOINTMENTS, (action: AppointmentsActions.ActionType) =>
    handleGetUsersAppointments(
      action,
      AppointmentsActions.fetchAppendUsersAppointmentsSuccess,
      AppointmentsActions.fetchAppendUsersAppointmentsFail,
    ),
  );
}
