import { useEffect, useState } from 'react';
import { getAllStoreLocations } from '../api';
import { StoreLocation } from '../models/StoreLocations';

interface ReturnType {
  storeLocations: StoreLocation[];
  storeNames: string[];
  currentStore: StoreLocation;
}

const useStoreLocations = (name?: string): ReturnType => {
  const [storeLocations, setStoreLocations] = useState<StoreLocation[]>([]);

  useEffect(() => {
    getAllStoreLocations().then((res) => {
      setStoreLocations(res.data);
    });
  }, []);

  const storeNames = storeLocations.map((store) => store.name);

  const currentStore = storeLocations.find((store) => store.name === name) || ({} as StoreLocation);

  return { storeLocations, storeNames, currentStore };
};

export default useStoreLocations;
