import React, { memo } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './NavigationTabs.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  tabs: string[];
  tabFontColors?: (string | undefined)[];
  tabIndex: number;
  flexAllTabs?: true;
  onChange: (newTabIndex: number) => void;
}

export const NavigationTabs = memo<Props>(({ tabs, tabFontColors, tabIndex, flexAllTabs, onChange }) => {
  const { device } = useWindowDimensions();

  return (
    <Tabs
      variant={device === 'mobile' ? 'scrollable' : 'fullWidth'}
      TabIndicatorProps={{ style: { backgroundColor: tabFontColors ? tabFontColors[tabIndex] : undefined } }}
      className="NavigationTabs-tabs"
      value={tabIndex}
      onChange={(_, value) => onChange(value)}
    >
      {tabs.map((x, index) => (
        <Tab
          key={`${x}-${index + 1}`}
          className={`NavigationTabs-tab ${flexAllTabs && device !== 'mobile' ? 'mod-flexAllTabs' : ''}`}
          value={index}
          label={x}
          style={{ color: tabFontColors ? tabFontColors[index] : undefined }}
        />
      ))}
    </Tabs>
  );
});

NavigationTabs.displayName = 'NavigationTabs';

export default NavigationTabs;
