import React from 'react';
// @ts-expect-error: @TODO: Fix error: no exported module `HeatMap`
import { Map, GoogleApiWrapper, GoogleAPI, HeatMap } from 'google-maps-react';
import './GoogleMapsHeatMap.scss';
import Config from 'Config';
import mapStyles from './MapStyles';

interface Props {
  google: GoogleAPI;
  heatmapPositions: { lat: number; lng: number }[];
}
const GoogleMaps = ({ google, ...restProps }: Props): JSX.Element => {
  return (
    // @ts-expect-error: @TODO: Fix type error of `styles`
    <Map styles={mapStyles} google={google} {...restProps}>
      <HeatMap positions={restProps.heatmapPositions} opacity={0.7} radius={50} maxIntensity={100} />
    </Map>
  );
};

export const GoogleMapsHeatMap = ({ ...restProps }): JSX.Element => {
  const Wrapper = GoogleApiWrapper({
    apiKey: Config.googleMapsApiKey,
    libraries: ['visualization'],
    LoadingContainer: () => restProps.loadingElement,
  })(GoogleMaps);

  return (
    <div className="GoogleMapsHeatMap">
      <Wrapper heatmapPositions={restProps.heatmapPositions} {...restProps} />
    </div>
  );
};

export default GoogleMapsHeatMap;
