/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useCurrentBonafidePatientOrderInformation, {
  FlattenOrder,
} from 'hooks/useCurrentBonafidePatientOrderInformation';
import { CTAButton, InputDropdownField } from '../../../../../../../components';
import { FormsAndDocumentsMap } from '../../../../../../../models/FormsAndDocumentsMap';
import './ReviewContent.scss';

const serializeOrderSelectValue = (x: FlattenOrder): string => `#${x.orderno} - ${x._id}`;
const parseOrderSelectValue = (value: string) => {
  const [paOrderNoWithPrefix, id] = value?.split(' - ');
  const paOrderNo = paOrderNoWithPrefix?.replace(/^#/, '');
  return [paOrderNo, id];
};

interface Props {
  userInfo: {
    id: string;
    name: string;
    email: string;
    phone: string;
    bonafideCustomerId: string;
  };
  form: FormsAndDocumentsMap;
  onSelectedDocumentUpdate?: (userId: string, updatedProps: Partial<FormsAndDocumentsMap>) => void;
}

export const ReviewContent = ({ userInfo, form, onSelectedDocumentUpdate }: Props): JSX.Element => {
  const [currentPreview, setCurrentPreview] = useState(0);

  const { currentBonafidePatientOrderInformation } = useCurrentBonafidePatientOrderInformation(
    userInfo?.bonafideCustomerId,
  );

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const handleSelectedOrderChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const { value } = e.target;
      setValue('selectedOrder', value);
      const [selectedPaOrderNo, selectedOrderId] = parseOrderSelectValue(value);
      if (onSelectedDocumentUpdate) onSelectedDocumentUpdate(userInfo?.id, { selectedOrderId, selectedPaOrderNo });
    },
    [onSelectedDocumentUpdate, setValue, userInfo],
  );

  const renderOrderDropdown = () => (
    <>
      <div className="review-user-information-section-title">Order Information</div>
      <hr className="review-user-information-hairline" />
      <div className="review-user-information-row">
        <InputDropdownField
          label={
            <>
              Associated Order&nbsp;
              <Link
                to={`/self-service-portal/all-users/${userInfo?.id ?? ''}`}
                className="review-user-information-row-input-label-cta-link"
              >
                (View all orders)
              </Link>
            </>
          }
          placeholder="Select an order associated with this form"
          items={currentBonafidePatientOrderInformation?.map(serializeOrderSelectValue) ?? []}
          status={errors?.selectedOrder ? 'error' : 'active'}
          {...register('selectedOrder', { required: true })}
          onChange={handleSelectedOrderChange}
        />
      </div>
    </>
  );

  return (
    <div className="SendFormsAndDocuments-review-container-accordions-content">
      <div className="review-user-information">
        {!!form?.paOrderBindingRequired && renderOrderDropdown()}
        <div className="review-user-information-section-title">User Information</div>
        <hr className="review-user-information-hairline" />
        <div className="review-user-information-row">
          <span>Name</span>
          <span>{userInfo.name}</span>
        </div>
        <div className="review-user-information-row">
          <span>Email</span>
          <span>{userInfo.email}</span>
        </div>
        <div className="review-user-information-row">
          <span>Phone</span>
          <span>{userInfo.phone}</span>
        </div>
        <div className="review-user-information-section-title">Form Details</div>
        <hr className="review-user-information-hairline" />
        <div className="review-user-information-row">
          <span>File Name</span>
          <span>{form.fileName}</span>
        </div>
        <div className="review-user-information-row">
          <span>File URL</span>
          <span>
            <a target="__blank" href={form.pdf}>
              {form.pdf}
            </a>
          </span>
        </div>
      </div>
      <div className="review-previews">
        <div className="review-previews-container">
          {currentPreview ? (
            <div className="review-previews-container-html" />
          ) : (
            <iframe className="review-previews-container-pdf" title="preview-pdf " src={form.pdf} />
          )}
        </div>
        <div className="review-previews-cta-container">
          <CTAButton invert={currentPreview === 1} onClick={() => setCurrentPreview(0)} text="PDF" />
          <CTAButton invert={currentPreview === 0} onClick={() => setCurrentPreview(1)} text="HTML" />
        </div>
      </div>
    </div>
  );
};

export default ReviewContent;
