import React, { memo, ChangeEvent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import parseHTML from 'html-react-parser';

import { InputTextField } from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import RadioField from 'components/RadioField/RadioField';
import PhoneInputWrapper from 'components/PhoneInputWrapper/PhoneInputWrapper';
import { DatePicker } from 'components/DatePicker/DatePicker';
import parseCustomerDob from 'utils/parseCustomerDob';
import formatUSPhoneNumber from 'utils/formatUSPhoneNumber';
import isValidUSPhoneNumber from 'utils/isValidUSPhoneNumber';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './PatientConfirmationNoDiabetesForShoeIntakeOnly.scss';
import getFormGenderOptions from '../../../utils/getFormGenderOptions';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const PatientConfirmationNoDiabetesForShoeIntakeOnly = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
    mode = 'all',
  }) => {
    const patientEnabled = mode === 'patient' || mode === 'all';
    const adminEnabled = mode === 'admin' || mode === 'all';

    const genderRadioOptions = getFormGenderOptions(tForm);

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        orderNo: form?.paOrderData?.orderNo,
        dob: form?.paOrderData?.dob ? parseCustomerDob(form?.paOrderData?.dob) : '',
        csrDate: adminEnabled ? format(new Date(), 'yyyy-MM-dd') : '',
        gender: form?.paOrderData?.gender,
      } as { [key: string]: string },
    });
    const { signature: signatureValue } = getValues();
    const csrDateValue = watch('csrDate');

    const handleCsrDateChange = useCallback(
      (date: Date) => setValue('csrDate', format(date, 'yyyy-MM-dd')),
      [setValue],
    );

    const validatePhone = (phone: string) => {
      if (!isValidUSPhoneNumber(phone)) return 'Phone Invalid';
      return true;
    };

    const renderStatement = () => {
      const statement = tForm('statement', {
        returnObjects: true,
      }) as string[];
      // eslint-disable-next-line react/no-array-index-key
      return Array.isArray(statement) ? statement.map((paragraph, i) => <p key={i}>{paragraph}</p>) : null;
    };

    const renderPatientSignatureFieldsGroup = () => (
      <>
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
      </>
    );

    const renderAdminFields = () => (
      <>
        <InputTextField
          {...register('physicianName', { required: true })}
          label={tForm('primaryPhysician')}
          placeholder={namePlaceholder}
          type="text"
          maxLength={50}
        />
        <PhoneInputWrapper hasError={!!errors?.physicianPhone} hasErrorMessage={!!errors?.physicianPhone?.message}>
          {({ className, inputClassName, elementAfter }) => (
            <InputTextField
              {...register('physicianPhone', { required: true, validate: validatePhone })}
              label={tForm('primaryPhysicianTel')}
              placeholder="(201) 555-5555"
              type="text"
              className={className}
              inputClassName={inputClassName}
              elementAfter={elementAfter}
              status={errors?.physicianPhone ? 'error' : 'active'}
              bottomLabel={errors?.physicianPhone?.message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValue('physicianPhone', formatUSPhoneNumber(e.target.value));
              }}
              onBlur={(e) => setValue('physicianPhone', formatUSPhoneNumber(e.target.value))}
            />
          )}
        </PhoneInputWrapper>
        <InputTextField {...register('csr', { required: true })} label={tForm('csr')} type="text" maxLength={50} />

        <DatePicker
          {...register('csrDate', { required: true })}
          placeholder="Select date..."
          label={tForm('csrDate')}
          minDate={new Date()}
          date={new Date(csrDateValue)}
          onChange={handleCsrDateChange}
        />
      </>
    );

    return (
      <FormDetailsLayout
        className="PatientConfirmationNoDiabetesForShoeIntakeOnly"
        id={form?.id}
        title={parseHTML(tForm('title')?.replace('\n', '<br/>'))}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        <InputTextField
          {...register('name')}
          label={tForm('patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField {...register('orderNo')} label={tForm('order')} type="text" status="disabled" />
        <InputTextField {...register('customerId')} label={tForm('patientId')} type="text" status="disabled" />
        <InputTextField {...register('dob')} label={tForm('dob')} type="text" status="disabled" />
        {patientEnabled && (
          <RadioField
            {...register('gender', { required: true })}
            label={tForm('gender')}
            options={genderRadioOptions}
            disabled={loading}
            status={errors?.gender ? 'error' : 'active'}
          />
        )}
        {renderStatement()}
        <br />
        {patientEnabled && renderPatientSignatureFieldsGroup()}
        {adminEnabled && renderAdminFields()}
      </FormDetailsLayout>
    );
  },
);

PatientConfirmationNoDiabetesForShoeIntakeOnly.displayName = 'PatientConfirmationNoDiabetesForShoeIntakeOnly';

export default PatientConfirmationNoDiabetesForShoeIntakeOnly;
