import React, { memo, ReactNode } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Accordion.scss';

interface Props {
  children: ReactNode;
  defaultExpanded?: true;
  title: string;
  subtitle?: string;
}

export const Accordion = memo<Props>(({ children, title, subtitle, defaultExpanded }) => {
  return (
    <MuiAccordion className="Accordion" defaultExpanded={defaultExpanded} square disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="Accordion-title-container">
          <span className="Accordion-title-container-title">{title}</span>
          <span className="Accordion-title-container-subtitle">{subtitle}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
});

Accordion.displayName = 'Accordion';

export default Accordion;
