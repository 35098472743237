import React from 'react';
import { Accordion } from '../../../../../../../components';
import { KeyValuePairInterface } from '../../../../../../../models/KeyValuePairInterface';

interface Props {
  currentFormDetails: KeyValuePairInterface[];
  userInfoDetails: KeyValuePairInterface[];
}
const Details = ({ currentFormDetails, userInfoDetails }: Props): JSX.Element => {
  return (
    <>
      <Accordion defaultExpanded title="Form Details">
        <div className="FormDetails-container-information-content">
          {currentFormDetails.map((item) => (
            <div key={item.key} className="FormDetails-container-information-content-row">
              <span>{item.key}</span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </Accordion>
      <Accordion defaultExpanded title="User Information">
        <div className="FormDetails-container-information-content">
          {userInfoDetails.map((item) => (
            <div key={item.key} className="FormDetails-container-information-content-row">
              <span>{item.key}</span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </Accordion>
    </>
  );
};

export default Details;
