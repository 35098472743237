import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import * as UsersActions from './action';
import { getAllUsers, getSearchUsers } from '../../../api';
import { ViewUserDetails, ViewUserDetailsResponse } from '../../../models/ViewUserDetails';

export function* handleGetUsers(
  action: UsersActions.ActionType,
  successAction: (users: ViewUserDetails[]) => UsersActions.ActionType,
  failAction: (error?: string | undefined) => UsersActions.ActionType,
): Generator<CallEffect<ViewUserDetailsResponse> | PutEffect<Action<string>>, void, ViewUserDetailsResponse> {
  const {
    payload: { limit, skip, successCallback, errorCallback },
  } = action;

  try {
    if (!limit) return;
    const { data } = yield call(getAllUsers, limit, skip);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* handleGetSearchUsers(
  action: UsersActions.ActionType,
): Generator<CallEffect<ViewUserDetailsResponse> | PutEffect<Action<string>>, void, ViewUserDetailsResponse> {
  const {
    payload: { query },
  } = action;
  try {
    const { data } = yield call(getSearchUsers, query || '');
    if (!data) return;
    yield put(UsersActions.fetchSearchResultsSuccess(data));
  } catch (error) {
    yield put(UsersActions.fetchSearchResultsFail(`${error}`));
  }
}

export function* watchGetUsers(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(UsersActions.FETCH_USERS, (action: UsersActions.ActionType) =>
    handleGetUsers(action, UsersActions.fetchUsersSuccess, UsersActions.fetchUsersFail),
  );

  yield takeLatest(UsersActions.FETCH_APPEND_USERS, (action: UsersActions.ActionType) =>
    handleGetUsers(action, UsersActions.fetchAppendUsersSuccess, UsersActions.fetchAppendUsersFail),
  );
}

export function* watchGetSearchUsers(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(UsersActions.FETCH_SEARCH_RESULTS, handleGetSearchUsers);
}
