import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectSspUsersState = (state: RootState): State => state.sspUsers;

export const selectSspUsersData = createSelector(selectSspUsersState, (sspUsers) => sspUsers.sspUsers);

export const selectSearchResultsData = createSelector(selectSspUsersState, (sspUsers) => sspUsers.searchResults);

export const selectSspUser = createSelector(
  selectSspUsersData,
  (_: any, id: string) => id,
  (sspUsers, id) => sspUsers && sspUsers.find((item: any) => item.id === id),
);

export const selectSspUserByCustomerId = createSelector(
  selectSspUsersData,
  (_: any, customerId: string) => customerId,
  (sspUsers, customerId) => sspUsers && sspUsers.find((item) => item.bonafideCustomerId === customerId),
);
