import { getSspUsersMetrics } from 'api';
import { BarChart, BasicLineChart, PieChart } from 'components';
import { DashboardLayout } from 'layouts';
import { ISspUsersMetrics } from 'models';
import React from 'react';
import { useQuery } from 'react-query';
import fillSeriesDataWithEmptyValues from 'utils/fillSeriesDataWithEmptyValues';
import './SSPUsersMetrics.scss';

const SSPUsersMetrics = () => {
  const { data, isLoading, isSuccess } = useQuery('getSspUsersMetrics', () => getSspUsersMetrics());

  const { newSspUsers, sexMetrics, ageMetrics } = data?.data || ({} as ISspUsersMetrics);

  return (
    <DashboardLayout title="Self Service Portal Users Metrics">
      <div className="SSPUsersMetrics">
        <h2>New Users</h2>
        <BasicLineChart
          data={isSuccess ? fillSeriesDataWithEmptyValues(newSspUsers) : []}
          yAxisTitle="No. of New Users"
          xAxisTitle="Date"
          seriesName="No. of New Users"
          loading={isLoading}
        />
        <div className="SSPUsersMetrics-split">
          <div className="SSPUsersMetrics-split-item">
            <h2>Sex</h2>
            <PieChart
              loading={isLoading}
              series={{
                name: 'Sex',
                data: [
                  {
                    name: 'Male',
                    y: sexMetrics?.M,
                    color: '#BAE3F2',
                  },
                  {
                    name: 'Female',
                    y: sexMetrics?.F,
                    color: '#F5C0B5',
                  },
                ],
              }}
            />
          </div>
          <div className="SSPUsersMetrics-split-item">
            <h2>Age</h2>
            <BarChart
              loading={isLoading}
              series={{
                name: 'Age',
                data: [
                  {
                    name: 'Underage',
                    y: ageMetrics?.underage,
                    color: '#F7DAE5',
                  },
                  {
                    name: 'Young Adult',
                    y: ageMetrics?.youngAdult,
                    color: '#FFF2E1',
                  },
                  {
                    name: 'Middle Aged',
                    y: ageMetrics?.middleAged,
                    color: '#C3E2E6',
                  },
                  {
                    name: 'Senior',
                    y: ageMetrics?.senior,
                    color: '#D0CCE0',
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SSPUsersMetrics;
