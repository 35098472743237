import { action } from 'typesafe-actions';
import { CurrentUserDetails } from '../../../models/CurrentUserDetails';
import { LoginResponse } from '../../../models/LoginResponse';

export interface ActionType {
  type:
    | 'userDetails/SET_LOGIN_DETAILS'
    | 'userDetails/CLEAR_LOGIN_DETAILS'
    | 'userDetails/SET_CURRENT_USER_DETAILS'
    | 'userDetails/CLEAR_CURRENT_USER_DETAILS';
  payload: {
    loginResponse?: LoginResponse;
    currentUserDetails?: CurrentUserDetails;
  };
}

export const SET_LOGIN_DETAILS = 'userDetails/SET_LOGIN_DETAILS';
export const CLEAR_LOGIN_DETAILS = 'userDetails/CLEAR_LOGIN_DETAILS';
export const SET_CURRENT_USER_DETAILS = 'userDetails/SET_CURRENT_USER_DETAILS';
export const CLEAR_CURRENT_USER_DETAILS = 'userDetails/CLEAR_CURRENT_USER_DETAILS';

export const setLoginDetails = (loginResponse: LoginResponse): ActionType =>
  action(SET_LOGIN_DETAILS, { loginResponse });

export const clearLoginDetails = (): Promise<ActionType> => Promise.resolve(action(CLEAR_LOGIN_DETAILS)).then();

export const setCurrentUserDetails = (currentUserDetails: CurrentUserDetails): ActionType =>
  action(SET_CURRENT_USER_DETAILS, { currentUserDetails });

export const clearCurrentUserDetails = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_CURRENT_USER_DETAILS)).then();
