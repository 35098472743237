import React, { memo } from 'react';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import './Stepper.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface Props {
  steps: string[];
  currentStep: number;
}

export const Stepper = memo<Props>(({ steps, currentStep }) => {
  const { device } = useWindowDimensions();

  return (
    <div className="Stepper">
      <MuiStepper activeStep={currentStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>

      {device === 'mobile' && <div className="Stepper-mobile-label">{steps[currentStep]}</div>}
    </div>
  );
});

Stepper.displayName = 'Stepper';

export default Stepper;
