import React, { memo, MouseEventHandler, MutableRefObject, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import useClickOutside from '../../hooks/useClickOutside';
import './ContextMenu.scss';

export interface MenuItemConfig {
  path: string;
  label: string;
  icon: string;
  key?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

interface Props {
  menuItems: MenuItemConfig[];
  toggleRef: string | MutableRefObject<null | HTMLElement>;
  onClickOutside: (target: HTMLElement) => unknown;
  isItemActive?: (item: MenuItemConfig) => boolean;
}

export const ContextMenu = memo<Props>(({ menuItems, isItemActive, toggleRef, onClickOutside }) => {
  const menuRef = useRef<null | HTMLDivElement>(null);
  // Close dropdown when user clicks outside
  useClickOutside(
    useMemo(() => [menuRef, toggleRef], [toggleRef]),
    onClickOutside,
  );

  return (
    <div className="ContextMenu" ref={menuRef}>
      {menuItems.map((item) => {
        const { path, label, icon, key, onClick } = item;
        return (
          <Link
            key={key ?? path}
            to={path}
            className={`ContextMenu-item ${isItemActive && isItemActive(item) ? 'mod-active' : ''}`}
            onClick={onClick}
          >
            <span>{label}</span>
            <span
              className="ContextMenu-item-icon"
              style={{
                mask: `url(${icon}) no-repeat center`,
                WebkitMask: `url(${icon}) no-repeat center`,
                maskSize: 14,
                WebkitMaskSize: 14,
              }}
            />
          </Link>
        );
      })}
    </div>
  );
});

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;
