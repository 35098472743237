import React, { memo } from 'react';
import './TagList.scss';

interface Props {
  tags: string[];
  icon?: 'remove' | 'add';
  onTagRemove?: (tag: string) => void;
  onTagClick?: (tag: string) => void;
}

export const TagList = memo<Props>(({ tags, onTagRemove, onTagClick, icon }) => {
  return (
    <div className="TagList">
      {tags.map((tag) => (
        <div
          className={`TagList-item ${onTagClick ? 'mod-clickable' : ''}`}
          onClick={() => {
            if (onTagClick) onTagClick(tag);
          }}
          key={tag}
        >
          {tag}
          {onTagRemove && (
            <input className="TagList-item-btn mod-remove" type="button" onClick={() => onTagRemove(tag)} />
          )}
          {onTagClick && <input className={`TagList-item-btn mod-${icon}`} type="button" />}
        </div>
      ))}
    </div>
  );
});

TagList.displayName = 'TagList';

export default TagList;
