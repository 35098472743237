import { ActionType } from 'typesafe-actions';
import { CurrentUserDetails } from '../../../models/CurrentUserDetails';
import { LoginResponse } from '../../../models/LoginResponse';
import * as UserDetailsActions from './action';

export interface State {
  loginResponse: LoginResponse | null;
  currentUserDetails: CurrentUserDetails | null;
}

const initialState: State = {
  loginResponse: null,
  currentUserDetails: null,
};

type UserDetailsActionsType = ActionType<typeof UserDetailsActions>;

export default function reducer(state: State = initialState, action: UserDetailsActionsType): State {
  switch (action.type) {
    case UserDetailsActions.CLEAR_CURRENT_USER_DETAILS:
      return {
        ...state,
        currentUserDetails: initialState.currentUserDetails,
      };
    case UserDetailsActions.SET_CURRENT_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case UserDetailsActions.CLEAR_LOGIN_DETAILS:
      return {
        ...state,
        loginResponse: initialState.loginResponse,
      };
    case UserDetailsActions.SET_LOGIN_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
