import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { RootState } from 'MyTypes';

import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { Accordion, AlertModal } from '../../../../../components';
import { ViewSSPUserDetails } from '../../../../../models';
import { FormsAndDocumentsMap } from '../../../../../models/FormsAndDocumentsMap';
import {
  selectSelectedDocument,
  selectSelectedSspUserFormProps,
  selectSelectedSspUsers,
} from '../../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import Layout from '../Layout';
import ReviewContent from './components/ReviewContent/ReviewContent';
import './Review.scss';
import { SendNewFormsAndDocumentsInterface } from '../../../../../models/SendNewFormsAndDocumentsInterface';
import { postSendNewForms } from '../../../../../api';
import * as SendFormsAndDocumentsActions from '../../../../../store/SendFormsAndDocumentsDuck/duck/action';
import formatFullName from '../../../../../utils/formatFullName';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDocument: FormsAndDocumentsMap | null;
  selectedSspUserFormProps: {
    [sspUserId: string]: Partial<FormsAndDocumentsMap>;
  };
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) => void;
  setSelectedSSPUsersFormProps: (selectedSspUserFormProps: {
    [sspUserId: string]: Partial<FormsAndDocumentsMap>;
  }) => void;
  clearSelectedSSPUsersFormProps: () => void;
}

const Review: React.FC<Props> = ({
  selectedSspUsers,
  selectedDocument,
  setSelectedSspUsers,
  selectedSspUserFormProps,
  setSelectedSSPUsersFormProps,
  clearSelectedSSPUsersFormProps,
}) => {
  const history = useHistory();

  /* Submit loading state */
  const [loading, setLoading] = useState(false);
  /* Submit error state */
  const [error, setError] = useState<string | null>(null);
  /* Submit alert open state */
  const [isPreSubmitAlertOpen, setIsPreSubmitAlertOpen] = useState<boolean>(false);

  /* Redirect back to step 2 if no selectedDocument */
  useEffect(() => {
    if (!selectedDocument) {
      history.push('/self-service-portal/send-forms-and-documents/select-document');
    }
  }, [history, selectedDocument, selectedSspUsers.length]);

  const submitForms = useCallback(
    (forcedSubmit?: boolean) => {
      if (!selectedDocument) return;

      let shouldAbortSubmit = false;
      const forms: SendNewFormsAndDocumentsInterface[] = selectedSspUsers.map((x) => {
        const userSpecificFormProps = selectedSspUserFormProps[x.id];
        if (selectedDocument.paOrderBindingRequired && !userSpecificFormProps?.selectedOrderId) {
          setIsPreSubmitAlertOpen(true);
          if (!forcedSubmit) shouldAbortSubmit = true;
        }
        return {
          ...userSpecificFormProps,
          customerId: x.bonafideCustomerId,
          name: formatFullName(x.firstname, x.middleinitial, x.lastname),
          firstName: x.firstname,
          middleInitial: x.middleinitial,
          lastName: x.lastname,
          phone: x.phone,
          username: x.username,
          formTitle: selectedDocument.fileName,
          formPdf: selectedDocument.pdf,
          formComponent: selectedDocument.formComponent,
        };
      });

      if (shouldAbortSubmit) {
        return;
      }

      setLoading(true);
      postSendNewForms(forms).then((res) => {
        setLoading(false);
        if (res.error) {
          setError(res.error);
          return;
        }
        setSelectedSspUsers([]);
        clearSelectedSSPUsersFormProps();
        history.push('/self-service-portal/send-forms-and-documents/completed');
      });
    },
    [
      clearSelectedSSPUsersFormProps,
      history,
      selectedDocument,
      selectedSspUserFormProps,
      selectedSspUsers,
      setSelectedSspUsers,
    ],
  );
  const handleSubmit = useCallback(() => submitForms(), [submitForms]);

  const handlePreSubmitAlertConfirm = useCallback(() => {
    submitForms(true);
    setIsPreSubmitAlertOpen(false);
  }, [submitForms]);

  const handlePreSubmitAlertCancel = useCallback(() => setIsPreSubmitAlertOpen(false), []);

  const updateSelectedDocument = useCallback(
    (sspUserId: string, updatedProps: Partial<FormsAndDocumentsMap>) => {
      setSelectedSSPUsersFormProps({ [sspUserId]: updatedProps });
    },
    [setSelectedSSPUsersFormProps],
  );

  if (!selectedDocument) return null;

  const reviewDetails = selectedSspUsers.map((x) => ({
    userInfo: {
      id: x.id,
      name: formatFullName(x.firstname, x.middleinitial, x.lastname),
      email: x.username,
      phone: `${x.phoneRegion} ${x.phone}`,
      bonafideCustomerId: x.bonafideCustomerId,
    },
    form: { ...selectedDocument },
    autofill: {
      name: formatFullName(x.firstname, x.middleinitial, x.lastname),
      date: dayjs(new Date()).format('MM/DD/YYYY'),
    },
    onSelectedDocumentUpdate: updateSelectedDocument,
  }));

  return (
    <>
      <AlertModal
        description="Error"
        onConfirm={() => setError(null)}
        errorMessage={error || undefined}
        isOpen={typeof error === 'string'}
      />
      <AlertModal
        description="Some of your forms are without an order selected. Are you ok to submit?"
        onConfirm={handlePreSubmitAlertConfirm}
        onCancel={handlePreSubmitAlertCancel}
        isOpen={isPreSubmitAlertOpen}
      />
      <Layout
        overflow
        disableCta={loading}
        ctaLabel={loading ? 'Submitting...' : 'Submit'}
        handleCtaClick={handleSubmit}
        currentStep={2}
        className="SendFormsAndDocuments-review-container"
      >
        <div className="SendFormsAndDocuments-review-container-accordions">
          {reviewDetails.map((x, i) => (
            <Accordion key={`review-submission-${i + 1}`} title={x.userInfo.name} defaultExpanded>
              <ReviewContent {...x} />
            </Accordion>
          ))}
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (
  state: RootState,
): Pick<Props, 'selectedSspUsers' | 'selectedDocument' | 'selectedSspUserFormProps'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDocument: selectSelectedDocument(state),
  selectedSspUserFormProps: selectSelectedSspUserFormProps(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<Props, 'setSelectedSspUsers' | 'setSelectedSSPUsersFormProps' | 'clearSelectedSSPUsersFormProps'> => ({
  setSelectedSspUsers: (selectedSspUsers: ViewSSPUserDetails[]) =>
    dispatch(SendFormsAndDocumentsActions.setSelectedSspUsers(selectedSspUsers)),
  setSelectedSSPUsersFormProps: async (selectedSspUserFormProps: {
    [sspUserId: string]: Partial<FormsAndDocumentsMap>;
  }) => dispatch(await SendFormsAndDocumentsActions.setSelectedSSPUsersFormProps(selectedSspUserFormProps)),
  clearSelectedSSPUsersFormProps: async () =>
    dispatch(await SendFormsAndDocumentsActions.clearSelectedSSPUsersFormProps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
