import { action } from 'typesafe-actions';
import { ViewSSPUserDetails } from '../../../models';

export interface ActionType {
  type:
    | 'sspUsers/CLEAR_SSP_USERS'
    | 'sspUsers/FETCH_SEARCH_RESULTS'
    | 'sspUsers/FETCH_SEARCH_RESULTS_SUCCESS'
    | 'sspUsers/FETCH_SEARCH_RESULTS_FAIL'
    | 'sspUsers/CLEAR_SEARCH_RESULTS'
    | 'sspUsers/FETCH_SSP_USERS'
    | 'sspUsers/FETCH_SSP_USERS_SUCCESS'
    | 'sspUsers/FETCH_SSP_USERS_FAIL'
    | 'sspUsers/FETCH_APPEND_SSP_USERS'
    | 'sspUsers/FETCH_APPEND_SSP_USERS_SUCCESS'
    | 'sspUsers/FETCH_APPEND_SSP_USERS_FAIL';
  payload: {
    query?: string;
    error?: string;
    sspUsers?: ViewSSPUserDetails[];
    searchResults?: ViewSSPUserDetails[];
    limit?: number;
    skip?: number;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}
export const FETCH_SSP_USERS = 'sspUsers/FETCH_SSP_USERS';
export const FETCH_SSP_USERS_SUCCESS = 'sspUsers/FETCH_SSP_USERS_SUCCESS';
export const FETCH_SSP_USERS_FAIL = 'sspUsers/FETCH_SSP_USERS_FAIL';

export const FETCH_APPEND_SSP_USERS = 'sspUsers/FETCH_APPEND_SSP_USERS';
export const FETCH_APPEND_SSP_USERS_SUCCESS = 'sspUsers/FETCH_APPEND_SSP_USERS_SUCCESS';
export const FETCH_APPEND_SSP_USERS_FAIL = 'sspUsers/FETCH_APPEND_SSP_USERS_FAIL';

export const CLEAR_SSP_USERS = 'sspUsers/CLEAR_SSP_USERS';

export const FETCH_SEARCH_RESULTS = 'sspUsers/FETCH_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'sspUsers/FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_FAIL = 'sspUsers/FETCH_SEARCH_RESULTS_FAIL';
export const CLEAR_SEARCH_RESULTS = 'sspUsers/CLEAR_SEARCH_RESULTS';

export const clearSspUsers = (): Promise<ActionType> => Promise.resolve(action(CLEAR_SSP_USERS)).then();

/* ------------- Fetch SSP Users  ------------- */
export const fetchSspUsers = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_SSP_USERS, { limit, skip, successCallback, errorCallback });
export const fetchSspUsersSuccess = (sspUsers: ViewSSPUserDetails[]): ActionType =>
  action(FETCH_SSP_USERS_SUCCESS, { sspUsers });
export const fetchSspUsersFail = (error?: string): ActionType => action(FETCH_SSP_USERS_FAIL, { error });

/* ------------- Fetch More Users Forms ------------- */
export const fetchAppendSspUsers = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_APPEND_SSP_USERS, { limit, skip, successCallback, errorCallback });
export const fetchAppendSspUsersSuccess = (sspUsers: ViewSSPUserDetails[]): ActionType =>
  action(FETCH_APPEND_SSP_USERS_SUCCESS, { sspUsers });
export const fetchAppendSspUsersFail = (error?: string): ActionType => action(FETCH_APPEND_SSP_USERS_FAIL, { error });

/* ------------- Search API ------------- */
export const fetchSearchResults = (query: string): ActionType => action(FETCH_SEARCH_RESULTS, { query });
export const fetchSearchResultsSuccess = (searchResults?: ViewSSPUserDetails[]): ActionType =>
  action(FETCH_SEARCH_RESULTS_SUCCESS, { searchResults });
export const fetchSearchResultsFail = (error?: string): ActionType => action(FETCH_SEARCH_RESULTS_FAIL, { error });
export const clearSearchResults = (): Promise<ActionType> => Promise.resolve(action(CLEAR_SEARCH_RESULTS)).then();
