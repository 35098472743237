import React, { useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { Card, NavigationTabs } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import './Appointments.scss';
import * as AppointmentActions from '../../../../store/AppointmentsDuck/duck/action';
import { selectAllAppointments } from '../../../../store/AppointmentsDuck/duck/selector';
import { AppointmentResponseData } from '../../../../models/Appointment';
import useStoreLocations from '../../../../hooks/useStoreLocations';
import { BigCalendarEvent } from '../../../../models/BigCalendarEvent';
import formatFullName from '../../../../utils/formatFullName';

const localizer = momentLocalizer(moment);

interface Props {
  allAppointments: AppointmentResponseData[];
  fetchUsersAppointments: (
    month?: number,
    year?: number,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<AppointmentActions.ActionType>;
}
const Appointments = ({ allAppointments, fetchUsersAppointments }: Props): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState({
    date: new Date(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const { storeNames } = useStoreLocations();

  /* Fetch appointments */
  useEffect(() => {
    setLoading(true);
    fetchUsersAppointments(
      currentView.month,
      currentView.year,
      () => setLoading(false),
      () => setLoading(false),
    );
  }, [currentView.month, currentView.year, fetchUsersAppointments]);

  const tabs = useMemo(() => ['all', ...storeNames], [storeNames]);

  /* Compose react-big-calendar events */
  const appointments: BigCalendarEvent[] = useMemo(
    () =>
      allAppointments
        ?.filter((appointment) => {
          if (tabs[tabIndex] === 'all') {
            return true;
          }
          return appointment.store === tabs[tabIndex];
        })
        ?.map((appointment) => ({
          title: `(#${appointment.customerId}) ${formatFullName(
            appointment.firstname,
            appointment.middleinitial,
            appointment.lastname,
          )}`,
          start: new Date(appointment.start),
          end: new Date(appointment.end),
          resource: appointment.id,
          colorCode: `${(() => {
            switch (tabs.findIndex((tab) => tab === appointment.store)) {
              case 1:
                return '#e6a99f'; // $PastelPink
              case 2:
                return '#a6b889'; // $PastelGreen
              case 3:
                return '#76ada8'; // $PastelCyan
              default:
                return '';
            }
          })()}`,
        })),
    [allAppointments, tabIndex, tabs],
  );

  return (
    <DashboardLayout title="Appointments">
      <Card noPadding className="Appointments">
        {loading && storeNames.length === 0 && (
          <div className="Appointments-loading">
            <ClipLoader color="#06bbc9" />
          </div>
        )}
        <div className="Appointments-container">
          <NavigationTabs
            flexAllTabs
            onChange={(e) => setTabIndex(e)}
            tabIndex={tabIndex}
            tabs={['all', ...storeNames]}
            tabFontColors={[undefined, '#e6a99f', '#a6b889', '#76ada8']}
          />
          <Calendar
            onSelectEvent={(event) => history.push(`/self-service-portal/appointments/${event.resource}`)}
            defaultDate={currentView.date}
            onNavigate={(date: Date) => setCurrentView({ date, month: date.getMonth() + 1, year: date.getFullYear() })}
            className="Appointments-calendar"
            localizer={localizer}
            events={appointments}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event: BigCalendarEvent) => ({ style: { backgroundColor: event.colorCode } })}
          />
        </div>
      </Card>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'allAppointments'> => ({
  allAppointments: selectAllAppointments(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchUsersAppointments'> => ({
  fetchUsersAppointments: async (
    month?: number,
    year?: number,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => dispatch(await AppointmentActions.fetchUsersAppointments(month, year, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
