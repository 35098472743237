import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { postResetPassword } from '../../../../api';
import { CTAButton, InputTextField } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import ManageUserLayout from '../../../../layouts/ManageUserLayout/ManageUserLayout';
import { ResetUserPasswordDetails } from '../../../../models/ResetUserPasswordDetails';
import './ResetUserPassword.scss';

const ResetUserPassword = (): JSX.Element => {
  const [responseContent, setResponseContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetUserPasswordDetails>();

  const onSubmit = (data: ResetUserPasswordDetails) => {
    setLoading(true);
    postResetPassword(data)
      .then((res) => {
        setResponseContent(JSON.stringify(res.error || res.message));
        setLoading(false);
      })
      .catch((err) => {
        setResponseContent(err);
        setLoading(false);
      });
  };

  return (
    <DashboardLayout title="Reset User Password">
      <ManageUserLayout onSubmit={handleSubmit(onSubmit)} responseContent={responseContent} loading={loading}>
        <InputTextField
          label="Email address"
          placeholder="john.appleseed@nymannings.com"
          type="email"
          status={errors?.username ? 'error' : 'active'}
          {...register('username', { required: true })}
        />
        <CTAButton type="submit" text={loading ? 'Resetting...' : 'Reset'} disabled={loading} />
      </ManageUserLayout>
    </DashboardLayout>
  );
};

export default ResetUserPassword;
