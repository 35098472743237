import { Report } from 'models/Report';
import { ActionType } from 'typesafe-actions';
import * as ReportsActions from './action';

export interface State {
  reports: Report[] | null;
}

const initialState: State = {
  reports: null,
};

type ReportsActionsType = ActionType<typeof ReportsActions>;

export default function reducer(state: State = initialState, action: ReportsActionsType): State {
  switch (action.type) {
    case ReportsActions.FETCH_REPORTS:
    case ReportsActions.FETCH_REPORTS_SUCCESS:
    case ReportsActions.FETCH_REPORTS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
