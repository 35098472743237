import React, { useEffect, useMemo } from 'react';
import { Card, GoogleMapsHeatMap } from 'components';
import { AddressZip } from 'models/AddressZipsResponse';
import { Dispatch } from 'redux';
import { RootState } from 'MyTypes';
import { selectAddressZips } from 'store/AnalyticsDuck/duck/selector';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import * as AnalyticsActions from '../../../../store/AnalyticsDuck/duck/action';
import { DashboardLayout } from '../../../../layouts';
import './PatientOrdersHeatmap.scss';

interface Props {
  addressZips: AddressZip[] | null;
  fetchAddressZips: (successCallback?: () => void, errorCallback?: () => void) => Promise<AnalyticsActions.ActionType>;
}

const PatientOrdersHeatmap = ({ addressZips: initialAddressZips, fetchAddressZips }: Props): JSX.Element => {
  useEffect(() => {
    fetchAddressZips();
  }, [fetchAddressZips]);

  const addressZips = useMemo(() => {
    if (initialAddressZips) {
      return initialAddressZips
        .filter((x) => x.latLng.lat && x.latLng.lng)
        .map((x: AddressZip) => Array(Math.round(x.count / 2)).fill(x.latLng))
        .reduce((acc: AddressZip[], val: AddressZip[]) => acc.concat(val), []);
    }

    return initialAddressZips ?? [];
  }, [initialAddressZips]);

  const Loading = (): JSX.Element => (
    <div className="PatientOrdersHeatmap-loading">
      <ClipLoader color="#06bbc9" />
    </div>
  );

  return (
    <DashboardLayout title="Patient Orders Heatmap">
      <Card noPadding>
        {addressZips.length === 0 ? (
          <Loading />
        ) : (
          <GoogleMapsHeatMap
            loadingElement={<Loading />}
            streetViewControl={false}
            zoom={11}
            initialCenter={{
              lat: 40.7147419,
              lng: -73.9986923,
            }}
            heatmapPositions={addressZips.length > 0 ? addressZips : []}
          />
        )}
      </Card>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'addressZips'> => ({
  addressZips: selectAddressZips(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchAddressZips'> => ({
  fetchAddressZips: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await AnalyticsActions.fetchAddressZips(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientOrdersHeatmap);
