import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import * as SspUsersActions from './action';
import { getSearchSspUsers, getAllSspUsers } from '../../../api';
import { ViewSSPUserDetails, ViewSSPUserDetailsResponse } from '../../../models';

export function* handleGetSspUsers(
  action: SspUsersActions.ActionType,
  successAction: (sspUsers: ViewSSPUserDetails[]) => SspUsersActions.ActionType,
  failAction: (error?: string | undefined) => SspUsersActions.ActionType,
): Generator<CallEffect<ViewSSPUserDetailsResponse> | PutEffect<Action<string>>, void, ViewSSPUserDetailsResponse> {
  const {
    payload: { limit, skip, successCallback, errorCallback },
  } = action;

  try {
    if (!limit) return;
    const { data } = yield call(getAllSspUsers, limit, skip);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* handleGetSearchSspUsers(
  action: SspUsersActions.ActionType,
): Generator<CallEffect<ViewSSPUserDetailsResponse> | PutEffect<Action<string>>, void, ViewSSPUserDetailsResponse> {
  const {
    payload: { query },
  } = action;
  try {
    const { data } = yield call(getSearchSspUsers, query || '');
    if (!data) return;
    yield put(SspUsersActions.fetchSearchResultsSuccess(data));
  } catch (error) {
    yield put(SspUsersActions.fetchSearchResultsFail(`${error}`));
  }
}

export function* watchGetSspUsers(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(SspUsersActions.FETCH_SSP_USERS, (action: SspUsersActions.ActionType) =>
    handleGetSspUsers(action, SspUsersActions.fetchSspUsersSuccess, SspUsersActions.fetchSspUsersFail),
  );

  yield takeLatest(SspUsersActions.FETCH_APPEND_SSP_USERS, (action: SspUsersActions.ActionType) =>
    handleGetSspUsers(action, SspUsersActions.fetchAppendSspUsersSuccess, SspUsersActions.fetchAppendSspUsersFail),
  );
}

export function* watchGetSearchSspUsers(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(SspUsersActions.FETCH_SEARCH_RESULTS, handleGetSearchSspUsers);
}
