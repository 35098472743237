import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectSendFormsAndDocumentsState = (state: RootState): State => state.sendFormsAndDocuments;

export const selectSelectedSspUsers = createSelector(
  selectSendFormsAndDocumentsState,
  (sendFormsAndDocuments) => sendFormsAndDocuments.selectedSspUsers,
);

export const selectSelectedDocument = createSelector(
  selectSendFormsAndDocumentsState,
  (sendFormsAndDocuments) => sendFormsAndDocuments.selectedDocument,
);

export const selectSelectedSspUserFormProps = createSelector(
  selectSendFormsAndDocumentsState,
  (sendFormsAndDocuments) => sendFormsAndDocuments.selectedSspUserFormProps,
);

export const selectAllUsersForms = createSelector(
  selectSendFormsAndDocumentsState,
  (sendFormsAndDocuments) => sendFormsAndDocuments.allUsersFormsAndDocuments,
);

export const selectCurrentUserForm = createSelector(
  selectAllUsersForms,
  (_: unknown, id: string) => id,
  (form, id) => form && form.find((item) => item.id === id),
);

export const selectSearchUsersFormsResultsData = createSelector(
  selectSendFormsAndDocumentsState,
  (sendFormsAndDocuments) => sendFormsAndDocuments.searchUsersFormsResults,
);
