import dayjs from 'dayjs';
import { SeriesData } from '../models/SeriesData';

const fillSeriesDataWithEmptyValues = (data: SeriesData): SeriesData => {
  const newData: SeriesData = [];

  let startDay = data[0][0];
  const endDay = new Date(dayjs().format('YYYY-MM-DD')).getTime();
  let isLastDay = startDay === endDay;

  const dayTick = 1000 * 60 * 60 * 24;

  newData.push([startDay, 0]);

  while (!isLastDay) {
    startDay += dayTick;
    newData.push([startDay, 0]);

    isLastDay = startDay >= endDay;
  }

  for (let i = 0; i < data.length; i++)
    for (let j = 0; j < newData.length; j++) {
      if (newData[j][0] === data[i][0]) {
        // eslint-disable-next-line prefer-destructuring
        newData[j][1] = data[i][1];
        break;
      }
    }

  return newData;
};

export default fillSeriesDataWithEmptyValues;
