import i18next from 'i18next';
import { action } from 'typesafe-actions';
import { ViewSSPUserDetails } from '../../../models';
import { FormsAndDocumentsMap } from '../../../models/FormsAndDocumentsMap';
import { ViewAllUsersFormsInterface } from '../../../models/ViewAllUsersFormsInterface';

export interface ActionType {
  type:
    | 'sendFormsAndDocuments/SET_SELECTED_SSP_USERS'
    | 'sendFormsAndDocuments/CLEAR_SELECTED_SSP_USERS'
    | 'sendFormsAndDocuments/SET_SELECTED_DOCUMENT'
    | 'sendFormsAndDocuments/CLEAR_SELECTED_DOCUMENT'
    | 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS'
    | 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS_SUCCESS'
    | 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS_FAIL'
    | 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS'
    | 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS_SUCCESS'
    | 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS_FAIL'
    | 'sendFormsAndDocuments/CLEAR_ALL_USERS_FORMS'
    | 'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS'
    | 'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS_SUCCESS'
    | 'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS_FAIL'
    | 'sendFormsAndDocuments/CLEAR_SEARCH_USERS_FORMS_RESULTS'
    | 'sendFormsAndDocuments/SET_SELECTED_SSP_USER_FORM_PROPS'
    | 'sendFormsAndDocuments/CLEAR_SELECTED_SSP_USER_FORM_PROPS'
    | 'sendFormsAndDocuments/FETCH_FORM_CONTENT';
  payload: {
    query?: string;
    searchUsersFormsResults?: ViewAllUsersFormsInterface[];
    allUsersFormsAndDocuments?: ViewAllUsersFormsInterface[];
    selectedSspUsers?: ViewSSPUserDetails[];
    selectedDocument?: FormsAndDocumentsMap;
    selectedSspUserFormProps?: { [sspUserId: string]: Partial<FormsAndDocumentsMap> };
    limit?: number;
    skip?: number;
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
    i18n?: typeof i18next;
    formComponent?: string;
    version?: string;
  };
}

export const SET_SELECTED_SSP_USERS = 'sendFormsAndDocuments/SET_SELECTED_SSP_USERS';
export const SET_SELECTED_DOCUMENT = 'sendFormsAndDocuments/SET_SELECTED_DOCUMENT';

export const CLEAR_SELECTED_SSP_USERS = 'sendFormsAndDocuments/CLEAR_SELECTED_SSP_USERS';
export const CLEAR_SELECTED_DOCUMENT = 'sendFormsAndDocuments/CLEAR_SELECTED_DOCUMENT';

export const FETCH_ALL_USERS_FORMS = 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS';
export const FETCH_ALL_USERS_FORMS_SUCCESS = 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS_SUCCESS';
export const FETCH_ALL_USERS_FORMS_FAIL = 'sendFormsAndDocuments/FETCH_ALL_USERS_FORMS_FAIL';

export const FETCH_APPEND_USERS_FORMS = 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS';
export const FETCH_APPEND_USERS_FORMS_SUCCESS = 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS_SUCCESS';
export const FETCH_APPEND_USERS_FORMS_FAIL = 'sendFormsAndDocuments/FETCH_APPEND_USERS_FORMS_FAIL';

export const CLEAR_ALL_USERS_FORMS = 'sendFormsAndDocuments/CLEAR_ALL_USERS_FORMS';

export const FETCH_SEARCH_USERS_FORMS_RESULTS = 'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS';
export const FETCH_SEARCH_USERS_FORMS_RESULTS_SUCCESS =
  'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS_SUCCESS';
export const FETCH_SEARCH_USERS_FORMS_RESULTS_FAIL = 'sendFormsAndDocuments/FETCH_SEARCH_USERS_FORMS_RESULTS_FAIL';
export const CLEAR_SEARCH_USERS_FORMS_RESULTS = 'sendFormsAndDocuments/CLEAR_SEARCH_USERS_FORMS_RESULTS';

export const SET_SELECTED_SSP_USER_FORM_PROPS = 'sendFormsAndDocuments/SET_SELECTED_SSP_USER_FORM_PROPS';
export const CLEAR_SELECTED_SSP_USER_FORM_PROPS = 'sendFormsAndDocuments/CLEAR_SELECTED_SSP_USER_FORM_PROPS';

export const FETCH_FORM_CONTENT = 'sendFormsAndDocuments/FETCH_FORM_CONTENT';

/* ------------- Send Forms & Documents Funnel ------------- */
export const setSelectedSspUsers = (selectedSspUsers: ViewSSPUserDetails[]): ActionType =>
  action(SET_SELECTED_SSP_USERS, { selectedSspUsers });

export const setSelectedDocument = (selectedDocument: FormsAndDocumentsMap): ActionType =>
  action(SET_SELECTED_DOCUMENT, { selectedDocument });

export const clearSelectedSspUsers = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SELECTED_SSP_USERS)).then();

export const clearSelectedDocument = (): Promise<ActionType> => Promise.resolve(action(CLEAR_SELECTED_DOCUMENT)).then();

export const setSelectedSSPUsersFormProps = (selectedSspUserFormProps: {
  [sspUserId: string]: Partial<FormsAndDocumentsMap>;
}): Promise<ActionType> =>
  Promise.resolve(
    action(SET_SELECTED_SSP_USER_FORM_PROPS, {
      selectedSspUserFormProps,
    }),
  ).then();

export const clearSelectedSSPUsersFormProps = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SELECTED_SSP_USER_FORM_PROPS)).then();

/* ------------- Fetch Users Forms ------------- */
export const fetchAllUsersForms = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_ALL_USERS_FORMS, { limit, skip, successCallback, errorCallback });
export const fetchAllUsersFormsSuccess = (allUsersFormsAndDocuments: ViewAllUsersFormsInterface[]): ActionType =>
  action(FETCH_ALL_USERS_FORMS_SUCCESS, { allUsersFormsAndDocuments });
export const fetchAllUsersFormsFail = (error?: string): ActionType => action(FETCH_ALL_USERS_FORMS_FAIL, { error });

/* ------------- Fetch More Users Forms ------------- */
export const fetchAppendUsersForms = (
  limit: number,
  skip?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_APPEND_USERS_FORMS, { limit, skip, successCallback, errorCallback });
export const fetchAppendUsersFormsSuccess = (allUsersFormsAndDocuments: ViewAllUsersFormsInterface[]): ActionType =>
  action(FETCH_APPEND_USERS_FORMS_SUCCESS, { allUsersFormsAndDocuments });
export const fetchAppendUsersFormsFail = (error?: string): ActionType =>
  action(FETCH_APPEND_USERS_FORMS_FAIL, { error });

export const clearAllUsersForms = (): Promise<ActionType> => Promise.resolve(action(CLEAR_ALL_USERS_FORMS)).then();

/* ------------- Search API ------------- */
export const fetchSearchUsersForms = (
  query: string,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_SEARCH_USERS_FORMS_RESULTS, { query, successCallback, errorCallback });
export const fetchSearchUsersFormsSuccess = (searchUsersFormsResults?: ViewAllUsersFormsInterface[]): ActionType =>
  action(FETCH_SEARCH_USERS_FORMS_RESULTS_SUCCESS, { searchUsersFormsResults });
export const fetchSearchUsersFormsFail = (error?: string): ActionType =>
  action(FETCH_SEARCH_USERS_FORMS_RESULTS_FAIL, { error });
export const clearSearchUsersForms = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SEARCH_USERS_FORMS_RESULTS)).then();

export const fetchFormContent = (
  i18n: typeof i18next,
  formComponent: string,
  version: string,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_FORM_CONTENT, { i18n, formComponent, version, successCallback, errorCallback });
