import React, { useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import dayjs from 'dayjs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Card, NavigationTabs } from '../../../../../../components';
import { DashboardLayout } from '../../../../../../layouts';
import './SSPUserDetail.scss';
import UserProfileDetailsContainer from '../../../../../../components/UserProfileDetailsContainer/UserProfileDetailsContainer';
import useCurrentSspUserDetails from '../../../../../../hooks/useCurrentSspUserDetails';
import useCurrentBonafideCustomerDetails from '../../../../../../hooks/useCurrentBonafideCustomerDetails';
import useCurrentBonafidePatientOrderInformation from '../../../../../../hooks/useCurrentBonafidePatientOrderInformation';
import formatFullName from '../../../../../../utils/formatFullName';

interface Props {
  sspUserId: string;
}

const SSPUserDetail = ({ sspUserId }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { device } = useWindowDimensions();

  const { sspUser, accountDetails } = useCurrentSspUserDetails(sspUserId);
  const { currentBonafideCustomer, currentBonafideCustomerDetails } = useCurrentBonafideCustomerDetails(
    sspUser?.bonafideCustomerId,
  );
  const { currentBonafidePatientOrderInformation, currentBonafidePatientOrderInformationDetails } =
    useCurrentBonafidePatientOrderInformation(sspUser?.bonafideCustomerId);

  if (!sspUser || !currentBonafideCustomer || !currentBonafidePatientOrderInformation) {
    return (
      <DashboardLayout>
        <Card className="SSPUserDetail-loading">
          <ClipLoader size={50} color="#06bbc9" />
        </Card>
      </DashboardLayout>
    );
  }

  const fetchItemsPerRowByDevice = () => {
    switch (device) {
      case 'mobile':
        return 1;
      case 'tablet':
        return 2;
      default:
        return 4;
    }
  };

  const AccountAndCustomerDetails = () => (
    <>
      <UserProfileDetailsContainer
        defaultExpanded
        title="Account Details"
        details={accountDetails}
        itemsPerRow={fetchItemsPerRowByDevice()}
      />
      <UserProfileDetailsContainer
        defaultExpanded
        title="Customer Details"
        details={currentBonafideCustomerDetails}
        itemsPerRow={fetchItemsPerRowByDevice()}
      />
    </>
  );

  const PatientOrderDetails = () => (
    <>
      {currentBonafidePatientOrderInformationDetails.map((x, index) => {
        const orderItem = currentBonafidePatientOrderInformation[index];
        return (
          <UserProfileDetailsContainer
            key={`${index + 1}`}
            subtitle={`(Order Date: ${`${dayjs(orderItem?.orderdate ?? '').format('MM-DD-YYYY')}`})`}
            title={`Order Details #${orderItem.orderno} - ${orderItem._id}`}
            details={x}
            itemsPerRow={fetchItemsPerRowByDevice()}
          />
        );
      })}
    </>
  );

  return (
    <DashboardLayout title={formatFullName(sspUser.firstname, sspUser.middleinitial, sspUser.lastname)}>
      <NavigationTabs
        tabs={['Account and Customer Details', 'Patient Order Details']}
        tabIndex={tabIndex}
        onChange={(e) => setTabIndex(e)}
      />
      <Card className="SSPUserDetail">{tabIndex ? <PatientOrderDetails /> : <AccountAndCustomerDetails />}</Card>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return {
    sspUserId: id,
  };
};

export default connect(mapStateToProps)(SSPUserDetail);
