import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { postChangeUserPassword } from '../../../../api';
import { CTAButton, InputTextField } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import ManageUserLayout from '../../../../layouts/ManageUserLayout/ManageUserLayout';
import { ChangeUserPasswordDetails } from '../../../../models/ChangeUserPasswordDetails';
import './ChangeUserPassword.scss';

interface FormData extends ChangeUserPasswordDetails {
  currentPassword: string;
  confirmNewPassword: string;
}

export const ChangeUserPassword: FC = () => {
  const [responseContent, setResponseContent] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>();

  const validateConfirmPassword = (confirmNewPassword: string) => {
    const { newPassword } = getValues();
    if (confirmNewPassword !== newPassword) return 'Passwords do not match';
    return true;
  };

  const onSubmit = (data: FormData) => {
    setLoading(true);
    postChangeUserPassword(data)
      .then((res) => {
        setResponseContent(JSON.stringify(res.error || res.status));
        setLoading(false);
      })
      .catch((err) => {
        setResponseContent(err);
        setLoading(false);
      });
  };

  return (
    <DashboardLayout title="Change User Password">
      <ManageUserLayout onSubmit={handleSubmit(onSubmit)} responseContent={responseContent} loading={loading}>
        <InputTextField
          type="password"
          label="Current Password"
          minLength={10}
          placeholder="•••••••••••••••"
          disableAutoComplete
          status={errors?.currentPassword ? 'error' : 'active'}
          {...register('currentPassword', { required: true })}
        />
        <InputTextField
          type="password"
          label="New Password"
          minLength={10}
          placeholder="•••••••••••••••"
          disableAutoComplete
          status={errors?.newPassword ? 'error' : 'active'}
          {...register('newPassword', { required: true })}
        />
        <InputTextField
          type="password"
          label="Confirm New Password"
          minLength={10}
          placeholder="•••••••••••••••"
          disableAutoComplete
          disablePaste
          bottomLabel={errors?.confirmNewPassword?.message}
          status={errors?.confirmNewPassword ? 'error' : 'active'}
          {...register('confirmNewPassword', {
            required: true,
            validate: validateConfirmPassword,
          })}
        />
        <CTAButton type="submit" text={loading ? 'Changing...' : 'Change'} disabled={loading} />
      </ManageUserLayout>
    </DashboardLayout>
  );
};

ChangeUserPassword.displayName = 'ChangeUserPassword';

export default ChangeUserPassword;
