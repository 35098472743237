import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'MyTypes';
import { useForm } from 'react-hook-form';
import { DashboardLayout } from '../../../../layouts';
import './ViewAllUsers.scss';
import { TableInfiniteScrollWrapper, Card, CTAButton, AlertModal, InputTextField } from '../../../../components';
import Table, { ColumnConfig } from '../../../../components/Table/Table';
import { selectSearchResultsData, selectUsersData } from '../../../../store/UsersDuck/duck/selector';
import { deleteUser } from '../../../../api';
import { ViewUserDetails } from '../../../../models/ViewUserDetails';
import * as UsersActions from '../../../../store/UsersDuck/duck/action';

const PAGE_SIZE = 50;

interface Props {
  users: ViewUserDetails[] | null;
  searchResults: ViewUserDetails[] | null;
  fetchUsers: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UsersActions.ActionType>;
  fetchAppendUsers: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UsersActions.ActionType>;
  fetchSearchUsers: (query: string) => UsersActions.ActionType;
  clearSearchResults: () => Promise<UsersActions.ActionType>;
}

const ViewAllUsers: FC<Props> = ({
  users: stateUsers,
  searchResults,
  fetchUsers,
  fetchAppendUsers,
  fetchSearchUsers,
  clearSearchResults,
}) => {
  /* ------ Search Users */
  const { register, watch } = useForm();
  const searchValue = watch('search');

  const handleSearch = useCallback(() => {
    fetchSearchUsers(searchValue);
  }, [fetchSearchUsers, searchValue]);

  const users = useMemo(() => {
    if (searchResults) return searchResults;
    return stateUsers ?? [];
  }, [stateUsers, searchResults]);

  useEffect(() => {
    if (searchValue === '') clearSearchResults();
  }, [clearSearchResults, fetchSearchUsers, searchValue]);

  /** ------------------------------- Reset Users ------------------------------- */
  const hasResetUsersOnMount = useRef(false);
  const [isResettingUsers, setIsResettingUsers] = useState(false);

  const resetUsers = useCallback(async () => {
    setIsResettingUsers(true);
    fetchUsers(PAGE_SIZE, undefined, () => setIsResettingUsers(false));
  }, [fetchUsers]);

  // Fetch and set users on mount
  useEffect(() => {
    if (!stateUsers || !hasResetUsersOnMount.current) {
      resetUsers();
      hasResetUsersOnMount.current = true;
    }
  }, [stateUsers, resetUsers]);

  /** ------------------------------- Load more users ------------------------------- */
  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState(false);

  const loadMoreUsers = useCallback(async () => {
    if (!stateUsers) return;
    setIsLoadingMoreUsers(true);
    fetchAppendUsers(PAGE_SIZE, users.length, () => setIsLoadingMoreUsers(false));
  }, [fetchAppendUsers, stateUsers, users.length]);

  /** ------------------------------- Remove User ------------------------------- */
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const [removingIndex, setRemovingIndex] = useState<null | number>(null);
  const removingUser = removingIndex !== null ? users[removingIndex] : null;
  const closeRemoveAlert = useCallback(() => {
    if (!isDeleting) {
      setRemovingIndex(null);
      setDeleteError('');
    }
  }, [isDeleting]);

  const handleConfirmRemoveUser = useCallback(async () => {
    // @TODO: Show error
    if (typeof removingIndex !== 'number') return;
    const user = users[removingIndex];

    setIsDeleting(true);
    try {
      const res = await deleteUser(user.username);
      if (res.status === 'error') {
        setDeleteError(res.error as string);
        return;
      }

      resetUsers();
      closeRemoveAlert();
    } catch (error) {
      setDeleteError((error as Error).message);
    } finally {
      setIsDeleting(false);
    }
  }, [closeRemoveAlert, resetUsers, removingIndex, users]);

  /** ------------------------------- Columns Config ------------------------------- */
  const tableColumnsConfig: ColumnConfig[] = [
    {
      heading: 'Email address',
      renderColumn: (index) => users[index].username,
      flex: 3,
      className: 'ViewAllUsers-table-email',
    },
    {
      heading: 'Role',
      renderColumn: (index) => users[index].accessLevel,
      flex: '0 0 120px',
      className: 'ViewAllUsers-table-role',
    },
    {
      heading: 'Last Login',
      renderColumn: (index) =>
        users[index].lastLogin ? dayjs(users[index].lastLogin ?? '').format('MM-DD-YYYY HH:mm') : '-',
      className: 'ViewAllUsers-table-lastLogin',
      flex: 2,
    },
    {
      heading: '',
      // eslint-disable-next-line react/display-name
      renderColumn: (index) => <div className="ViewAllUsers-table-more-icon" onClick={() => setRemovingIndex(index)} />,
      flex: '0 0 120px',
      textAlign: 'right',
    },
  ];

  return (
    <DashboardLayout title="View All Users">
      <Card className="ViewAllUsers" noPadding>
        <div className="ViewAllUsers-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search user..."
            type="text"
            className="ViewAllUsers-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <div className="ViewAllUsers-header-reset-container">
            <span className="ViewAllUsers-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="ViewAllUsers-header-reset-container-text">
              1&nbsp; - &nbsp;
              {users.length}
            </span>

            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={useCallback(() => resetUsers(), [resetUsers])}
              disabled={isResettingUsers}
              className="ViewAllUsers-header-reset-container-refresh-button"
            />
          </div>
        </div>
        {/* @TODO: Add linear gradient bars for initial loading */}
        <Table
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          itemCount={users.length}
          getRowKey={useCallback((index) => (users ?? [])[index]?.username, [users])}
          loadingRows={users === null || (users && users.length === 0)}
          renderFixedSizeList={(ListComponent) => (
            <TableInfiniteScrollWrapper
              hasNextPage
              isNextPageLoading={isLoadingMoreUsers}
              items={users}
              loadNextPage={loadMoreUsers}
            >
              {({ onItemsRendered, ref }) => <ListComponent ref={ref} onItemsRendered={onItemsRendered} />}
            </TableInfiniteScrollWrapper>
          )}
        />
      </Card>
      <AlertModal
        isOpen={removingIndex !== null}
        onRequestClose={closeRemoveAlert}
        onConfirm={handleConfirmRemoveUser}
        onCancel={closeRemoveAlert}
        confirmDisabled={isDeleting}
        cancelDisabled={isDeleting}
        confirmLabel={isDeleting ? 'Removing...' : 'OK'}
        description={`Are you sure you want to delete user "${removingUser?.username ?? ''}"?`}
        errorMessage={deleteError}
      />
    </DashboardLayout>
  );
};

ViewAllUsers.displayName = 'ViewAllUsers';

const mapStateToProps = (state: RootState): Pick<Props, 'users' | 'searchResults'> => ({
  users: selectUsersData(state),
  searchResults: selectSearchResultsData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<Props, 'fetchUsers' | 'fetchAppendUsers' | 'fetchSearchUsers' | 'clearSearchResults'> => ({
  fetchUsers: async (limit: number, skip?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UsersActions.fetchUsers(limit, skip, successCallback, errorCallback)),
  fetchAppendUsers: async (limit: number, skip?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UsersActions.fetchAppendUsers(limit, skip, successCallback, errorCallback)),
  fetchSearchUsers: (query: string) => dispatch(UsersActions.fetchSearchResults(query)),
  clearSearchResults: async () => dispatch(await UsersActions.clearSearchResults()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllUsers);
