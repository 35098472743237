import React, { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './PieChart.scss';
import Skeleton from 'react-loading-skeleton';

interface Props {
  className?: string;
  loading?: boolean;
  series: {
    name: string;
    data: {
      name: string;
      y: number;
      color?: string;
    }[];
  };
}

export const PieChart = memo<Props>(({ series, loading, className }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: undefined,
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [{ ...series }],
  };

  return (
    <div className={`PieChart ${className || ''}`}>
      {loading ? (
        <div style={{ backgroundColor: 'transparent' }} className="PieChart-loading">
          <Skeleton count={1} />
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
});

PieChart.displayName = 'PieChart';

export default PieChart;
