import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { DashboardLayout } from '../../../../layouts';
import './SSPViewAllUsers.scss';
import { Card } from '../../../../components';
import SSPUsersTable from '../../../../components/SSPUsersTable/SSPUsersTable';

const SSPViewAllUsers: FC = () => {
  const history = useHistory();
  return (
    <DashboardLayout title="View All Users">
      <Card className="SSPViewAllUsers" noPadding>
        <SSPUsersTable
          onRowClick={(sspUser) => sspUser && history.push(`${history.location.pathname}/${sspUser.id}`)}
        />
      </Card>
    </DashboardLayout>
  );
};

export default SSPViewAllUsers;
