import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import { ClipLoader } from 'react-spinners';
import { DatePicker, InputDropdownField, InputRtfTextareaField, InputTextField } from '../../../../../components';
import { ViewSSPUserDetails } from '../../../../../models';
import { selectSelectedDateTime, selectSelectedSspUsers } from '../../../../../store/AppointmentsDuck/duck/selector';
import * as AppointmentsActions from '../../../../../store/AppointmentsDuck/duck/action';
import Layout from '../Layout';
import './SelectDate.scss';
import { Appointment } from '../../../../../models/Appointment';
import useStoreLocations from '../../../../../hooks/useStoreLocations';
import { getTimeslotDetails } from '../../../../../api';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import formatFullName from '../../../../../utils/formatFullName';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDateTime: Appointment;
  setSelectedDateTime: (selectedDateTime: Appointment) => void;
}

const SelectDate = ({ selectedSspUsers, selectedDateTime, setSelectedDateTime }: Props): JSX.Element => {
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);
  const [timeslots, setTimeslots] = useState<string[] | null>(null);

  const { storeNames, currentStore, storeLocations } = useStoreLocations(selectedDateTime.store || undefined);

  /* Redirect back to step 1 if no selectedSspUsers */
  useEffect(() => {
    if (!selectedSspUsers || selectedSspUsers.length === 0) {
      history.push('/self-service-portal/create-appointment/select-patient');
    }
  }, [history, selectedSspUsers]);

  const handleFetchTimeslots = useCallback((date: Date, storeId: string) => {
    const midnightDate = new Date(date);
    midnightDate.setHours(0, 0, 0, 0);
    const formattedDate = dayjs(midnightDate).format('MM/DD/YYYY hh:mm A');

    setTimeslots(null);
    getTimeslotDetails(storeId, formattedDate).then((res) => {
      if (res.status === 'ok') {
        setTimeslots(res.data.timeslots.map((x) => x.time));
      } else {
        setTimeslots([]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedDateTime.date && currentStore.id) {
      handleFetchTimeslots(selectedDateTime.date, currentStore.id);
    }
  }, [currentStore.id, handleFetchTimeslots, selectedDateTime.date]);

  const patientName = useMemo(() => {
    const patient = selectedSspUsers[0];
    return formatFullName(patient?.firstname, patient?.middleinitial, patient?.lastname);
  }, [selectedSspUsers]);

  const handleCtaClick = useCallback(() => {
    const { date, store, timeslot } = selectedDateTime;
    let newErrorsList: string[] = [];

    if (!store) newErrorsList = [...newErrorsList, 'store'];
    if (!date) newErrorsList = [...newErrorsList, 'date'];
    if (!timeslot) newErrorsList = [...newErrorsList, 'timeslot'];

    setErrors(newErrorsList);
    if (newErrorsList.length > 0) return;

    history.push('/self-service-portal/create-appointment/review');
  }, [history, selectedDateTime]);

  const removeError = useCallback(
    (field: 'store' | 'start' | 'timeslot' | 'date') => {
      setErrors([...errors.filter((x) => x !== field)]);
    },
    [errors],
  );

  const handleStoreChange = useCallback(
    (store: string) => {
      const { id } = storeLocations.find((x) => x.name === store) || { id: null };
      if (selectedDateTime.date && id) handleFetchTimeslots(selectedDateTime.date, id);
      setSelectedDateTime({ ...selectedDateTime, store, timeslot: null });
      removeError('store');
    },
    [handleFetchTimeslots, removeError, selectedDateTime, setSelectedDateTime, storeLocations],
  );

  const handleDateChange = useCallback(
    (date: Date) => {
      if (currentStore.id && date) handleFetchTimeslots(date, currentStore.id);
      setSelectedDateTime({ ...selectedDateTime, date, timeslot: null });
      removeError('date');
    },
    [currentStore.id, handleFetchTimeslots, removeError, selectedDateTime, setSelectedDateTime],
  );

  const handleTimeslotChange = useCallback(
    (timeslot: string) => {
      setSelectedDateTime({ ...selectedDateTime, timeslot });
      removeError('timeslot');
    },
    [removeError, selectedDateTime, setSelectedDateTime],
  );

  const handleRemarksChange = useCallback(
    (remarks: string) => {
      setSelectedDateTime({ ...selectedDateTime, remarks });
    },
    [selectedDateTime, setSelectedDateTime],
  );

  const handleAdminRemarksChange = useCallback(
    (adminRemarks: string) => {
      setSelectedDateTime({ ...selectedDateTime, adminRemarks });
    },
    [selectedDateTime, setSelectedDateTime],
  );

  return (
    <Layout
      ctaLabel="Next"
      handleCtaClick={handleCtaClick}
      currentStep={1}
      className="CreateAppointment-select-date-container"
      ctaType="submit"
    >
      {storeNames.length === 0 ? (
        <div className="CreateAppointment-select-date-container-loading">
          <ClipLoader color="#06bbc9" />
        </div>
      ) : (
        <>
          <InputTextField type="text" label="Patient Name" value={patientName} disabled name="patient-name" />
          <InputDropdownField
            name="store"
            placeholder="Select store..."
            label=" Store Location *"
            items={storeNames}
            status={errors.includes('store') ? 'error' : undefined}
            onChange={({ target: { value: store } }) => {
              handleStoreChange(store);
            }}
            value={selectedDateTime.store || ''}
          />
          <DatePicker
            placeholder="Select date..."
            label="Appointment Date *"
            name="date"
            minDate={new Date()}
            status={errors.includes('date') ? 'error' : undefined}
            maxDate={dayjs().add(1, 'month').toDate()}
            date={selectedDateTime.date}
            onChange={(date: Date) => {
              handleDateChange(date);
            }}
            forceHideBottomLabel
          />
          <InputDropdownField
            name="timeslot"
            placeholder={
              !timeslots
                ? ''
                : timeslots.length > 0
                ? 'Select timeslot...'
                : 'No timeslots available, please select a different date or store'
            }
            label="Timeslot*"
            items={timeslots || []}
            status={errors.includes('timeslot') ? 'error' : undefined}
            onChange={({ target: { value: timeslot } }) => {
              handleTimeslotChange(timeslot);
            }}
            value={selectedDateTime.timeslot || ''}
            disabled={timeslots === null}
          />
          <InputRtfTextareaField
            value={selectedDateTime.remarks}
            onChange={(remarks) => handleRemarksChange(remarks)}
            label="Remarks"
          />
          <InputRtfTextareaField
            value={selectedDateTime.adminRemarks}
            onChange={(adminRemarks) => handleAdminRemarksChange(adminRemarks)}
            label="Admin Remarks (For Admin Only)"
          />
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers' | 'selectedDateTime'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDateTime: selectSelectedDateTime(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'setSelectedDateTime'> => ({
  setSelectedDateTime: (selectedDateTime: Appointment) =>
    dispatch(AppointmentsActions.setSelectedDateTime(selectedDateTime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);
