import dayjs from 'dayjs';

const mergeDateTime = (date: Date, time: string): Date => {
  const ampm = time.split(':')[1].split(' ')[1];
  const minute = Number(time.split(':')[1].split(' ')[0]);
  let hour = Number(time.split(':')[0]);

  if (hour !== 12 && ampm === 'PM') hour += 12;

  const datetime = dayjs(date).set('hour', hour).set('minute', minute).set('second', 0).toDate();

  datetime.setMilliseconds(0);

  return datetime;
};

export default mergeDateTime;
