import React, { memo, ReactNode } from 'react';
import './Card.scss';

interface Props {
  children: ReactNode;
  noPadding?: boolean;
  className?: string;
  onClick?: () => void;
}

export const Card = memo<Props>(({ children, onClick, noPadding = false, className = '' }) => {
  return (
    <div onClick={onClick} className={`Card ${noPadding ? 'mod-no-padding' : ''} ${className}`}>
      {children}
    </div>
  );
});

Card.displayName = 'Card';

export default Card;
