import { AddressZip } from 'models/AddressZipsResponse';
import { ActionType } from 'typesafe-actions';
import * as AnalyticsActions from './action';

export interface State {
  addressZips: AddressZip[] | null;
}

const initialState: State = {
  addressZips: null,
};

type AnalyticsActionsType = ActionType<typeof AnalyticsActions>;

export default function reducer(state: State = initialState, action: AnalyticsActionsType): State {
  switch (action.type) {
    case AnalyticsActions.FETCH_ADDRESS_ZIPS:
    case AnalyticsActions.FETCH_ADDRESS_ZIPS_SUCCESS:
    case AnalyticsActions.FETCH_ADDRESS_ZIPS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
