import dayjs from 'dayjs';
import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Table, { ColumnConfig } from '../../../../components/Table/Table';
import { InputTextField, CTAButton, TableInfiniteScrollWrapper, Card, AlertModal } from '../../../../components';
import { DashboardLayout } from '../../../../layouts';
import * as SendFormsAndDocumentsActions from '../../../../store/SendFormsAndDocumentsDuck/duck/action';
import './FormsAndDocuments.scss';
import {
  selectAllUsersForms,
  selectSearchUsersFormsResultsData,
} from '../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import { ViewAllUsersFormsInterface } from '../../../../models/ViewAllUsersFormsInterface';
import { deleteUserForm } from '../../../../api';

interface Props {
  allUsersFormsAndDocuments: ViewAllUsersFormsInterface[] | null;
  searchUsersFormsResults: ViewAllUsersFormsInterface[] | null;
  fetchAllUsersForms: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SendFormsAndDocumentsActions.ActionType>;
  fetchAppendUsersForms: (
    limit: number,
    skip?: number,
    callback?: () => void,
    errorCallback?: () => void,
  ) => Promise<SendFormsAndDocumentsActions.ActionType>;
  fetchSearchUsersForms: (
    query: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => SendFormsAndDocumentsActions.ActionType;
  clearSearchUsersForms: () => Promise<SendFormsAndDocumentsActions.ActionType>;
}

const PAGE_SIZE = 50;
const FormsAndDocuments = ({
  allUsersFormsAndDocuments: stateFormsAndDocuments,
  searchUsersFormsResults,
  fetchSearchUsersForms,
  clearSearchUsersForms,
  fetchAllUsersForms,
  fetchAppendUsersForms,
}: Props): JSX.Element => {
  const history = useHistory();
  /* ------ Search Users */
  const { register, watch } = useForm();
  const searchValue = watch('search');

  const handleSearch = useCallback(() => {
    setIsLoadingMoreUsers(true);
    fetchSearchUsersForms(searchValue, () => setIsLoadingMoreUsers(false));
  }, [fetchSearchUsersForms, searchValue]);

  const allUsersFormsAndDocuments = useMemo(() => {
    if (searchUsersFormsResults) return searchUsersFormsResults;
    return stateFormsAndDocuments ?? [];
  }, [searchUsersFormsResults, stateFormsAndDocuments]);

  useEffect(() => {
    if (searchValue === '') clearSearchUsersForms();
  }, [clearSearchUsersForms, searchValue]);

  /** ------------------------------- Reset Users ------------------------------- */
  const hasResetUsersOnMount = useRef(false);
  const [isResettingUsers, setIsResettingUsers] = useState(false);

  const resetUsers = useCallback(async () => {
    setIsResettingUsers(true);
    fetchAllUsersForms(PAGE_SIZE, undefined, () => setIsResettingUsers(false));
  }, [fetchAllUsersForms]);

  useEffect(() => {
    if (!stateFormsAndDocuments || !hasResetUsersOnMount.current) {
      fetchAllUsersForms(PAGE_SIZE);
      hasResetUsersOnMount.current = true;
    }
  }, [fetchAllUsersForms, stateFormsAndDocuments]);

  /** ------------------------------- Load more users ------------------------------- */
  const [isLoadingMoreUsers, setIsLoadingMoreUsers] = useState(false);

  const loadMoreForms = useCallback(async () => {
    if (!stateFormsAndDocuments) return;
    setIsLoadingMoreUsers(true);
    fetchAppendUsersForms(PAGE_SIZE, allUsersFormsAndDocuments.length, () => setIsLoadingMoreUsers(false));
  }, [stateFormsAndDocuments, fetchAppendUsersForms, allUsersFormsAndDocuments.length]);

  /** ------------------------------- Remove Users Form ------------------------------- */
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const [removingIndex, setRemovingIndex] = useState<null | number>(null);
  const removingUser = removingIndex !== null ? allUsersFormsAndDocuments[removingIndex] : null;
  const closeRemoveAlert = useCallback(() => {
    if (!isDeleting) {
      setRemovingIndex(null);
      setDeleteError('');
    }
  }, [isDeleting]);

  const handleConfirmRemoveUser = useCallback(async () => {
    // @TODO: Show error
    if (typeof removingIndex !== 'number') return;
    const user = allUsersFormsAndDocuments[removingIndex];

    setIsDeleting(true);
    try {
      const res = await deleteUserForm(user.id);
      if (res.status === 'error') {
        setDeleteError(res.error as string);
        return;
      }

      resetUsers();
      closeRemoveAlert();
    } catch (error) {
      setDeleteError((error as Error).message);
    } finally {
      setIsDeleting(false);
    }
  }, [removingIndex, allUsersFormsAndDocuments, resetUsers, closeRemoveAlert]);

  /** ------------------------------- Columns Config ------------------------------- */
  const tableColumnsConfig: ColumnConfig[] = useMemo(
    () => [
      {
        heading: 'Name',
        renderColumn: (index) => allUsersFormsAndDocuments[index]?.name,
        className: 'FormsAndDocuments-table-id',
      },
      {
        heading: 'Phone',
        renderColumn: (index) => allUsersFormsAndDocuments[index]?.phone,
        className: 'FormsAndDocuments-table-id',
      },
      {
        heading: 'Email address',
        renderColumn: (index) => allUsersFormsAndDocuments[index].username,
        className: 'FormsAndDocuments-table-email',
      },
      {
        heading: 'Form Title',
        renderColumn: (index) => allUsersFormsAndDocuments[index].formTitle,
        className: 'FormsAndDocuments-table-form-title',
      },
      {
        heading: 'Admin Signing Only',
        renderColumn: (index) => allUsersFormsAndDocuments[index].adminSigningOnly,
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Admin Pre-fill Required',
        renderColumn: (index) => allUsersFormsAndDocuments[index].adminPrefillRequired,
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Admin Pre-filled',
        renderColumn: (index) => allUsersFormsAndDocuments[index].adminPrefilled,
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Patient Signed',
        renderColumn: (index) => allUsersFormsAndDocuments[index].signed,
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Admin Review Required',
        renderColumn: (index) => allUsersFormsAndDocuments[index].adminReviewRequired,
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Admin Signed',
        renderColumn: (index) =>
          allUsersFormsAndDocuments[index].adminReviewRequired || allUsersFormsAndDocuments[index].adminPrefillRequired
            ? allUsersFormsAndDocuments[index].adminSigned
            : '',
        className: 'FormsAndDocuments-table-form-signed',
      },
      {
        heading: 'Form PDF',
        renderColumn: (index) => allUsersFormsAndDocuments[index].formPdf,
        className: 'FormsAndDocuments-table-form-pdf',
      },
      {
        heading: 'Form Component',
        renderColumn: (index) => allUsersFormsAndDocuments[index].formComponent,
        className: 'FormsAndDocuments-table-form-component',
      },
      {
        heading: 'Created At',
        renderColumn: (index) =>
          allUsersFormsAndDocuments[index].createdAt
            ? dayjs(allUsersFormsAndDocuments[index].createdAt ?? '').format('MM-DD-YYYY HH:mm')
            : '-',
        className: 'FormsAndDocuments-table-createdAt',
      },
      {
        heading: 'Updated At',
        renderColumn: (index) =>
          allUsersFormsAndDocuments[index].updatedAt
            ? dayjs(allUsersFormsAndDocuments[index].updatedAt ?? '').format('MM-DD-YYYY HH:mm')
            : '-',
        className: 'FormsAndDocuments-table-updatedAt',
      },
      {
        heading: 'Bonafide Customer ID',
        renderColumn: (index) => allUsersFormsAndDocuments[index].customerId,
        className: 'FormsAndDocuments-table-bonafide-customer-id',
      },
      {
        heading: 'Mongo ID',
        renderColumn: (index) => allUsersFormsAndDocuments[index]?.id,
        className: 'FormsAndDocuments-table-id',
      },
      {
        heading: '',
        // eslint-disable-next-line react/display-name
        renderColumn: (index) => (
          <div className="FormsAndDocuments-table-more-icon" onClick={() => setRemovingIndex(index)} />
        ),
        className: 'FormsAndDocuments-table-more-icon-cell',
      },
    ],
    [allUsersFormsAndDocuments],
  );

  const handleOnRowClick = (mongoId: string) => {
    history.push(`/self-service-portal/forms-and-documents/${mongoId}`);
  };

  return (
    <DashboardLayout title="View All Users Forms & Documents">
      <Card className="FormsAndDocuments" noPadding>
        <div className="FormsAndDocuments-header">
          <InputTextField
            {...register('search')}
            forceHideLabel
            name="search"
            placeholder="Search..."
            type="text"
            className="FormsAndDocuments-header-search-bar"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <div className="FormsAndDocuments-header-reset-container">
            <span className="FormsAndDocuments-header-reset-container-text">Showing items: &nbsp;</span>
            <span className="FormsAndDocuments-header-reset-container-text">
              1&nbsp; - &nbsp;
              {allUsersFormsAndDocuments.length}
            </span>
            <CTAButton
              invert
              text={isResettingUsers ? 'Resetting...' : 'Reset'}
              onClick={resetUsers}
              disabled={isResettingUsers}
              className="FormsAndDocuments-header-reset-container-refresh-button"
            />
          </div>
        </div>
        {/* @TODO: Add linear gradient bars for initial loading */}
        <Table
          className="FormsAndDocuments-table"
          itemCount={allUsersFormsAndDocuments.length}
          getRowKey={useCallback((index) => (allUsersFormsAndDocuments ?? [])[index]?.id, [allUsersFormsAndDocuments])}
          columnWidth={280}
          columnsConfig={tableColumnsConfig}
          rowHeight={60}
          onRowClick={handleOnRowClick}
          loadingRows={allUsersFormsAndDocuments === null}
          renderFixedSizeList={(ListComponent) => (
            <TableInfiniteScrollWrapper
              hasNextPage
              isNextPageLoading={isLoadingMoreUsers}
              items={allUsersFormsAndDocuments}
              loadNextPage={loadMoreForms}
            >
              {({ onItemsRendered, ref, itemCount, renderRow }) => (
                <ListComponent
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  itemCount={itemCount}
                  renderRow={renderRow}
                />
              )}
            </TableInfiniteScrollWrapper>
          )}
        />
      </Card>
      <AlertModal
        isOpen={removingIndex !== null}
        onRequestClose={closeRemoveAlert}
        onConfirm={handleConfirmRemoveUser}
        onCancel={closeRemoveAlert}
        confirmDisabled={isDeleting}
        cancelDisabled={isDeleting}
        confirmLabel={isDeleting ? 'Removing...' : 'OK'}
        description={`Are you sure you want to delete "${removingUser?.username ?? ''}" ${removingUser?.formTitle}?`}
        errorMessage={deleteError}
      />
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'allUsersFormsAndDocuments' | 'searchUsersFormsResults'> => ({
  allUsersFormsAndDocuments: selectAllUsersForms(state),
  searchUsersFormsResults: selectSearchUsersFormsResultsData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<Props, 'fetchAllUsersForms' | 'fetchAppendUsersForms' | 'clearSearchUsersForms' | 'fetchSearchUsersForms'> => ({
  fetchAllUsersForms: async (limit: number, skip?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await SendFormsAndDocumentsActions.fetchAllUsersForms(limit, skip, successCallback, errorCallback)),
  fetchAppendUsersForms: async (
    limit: number,
    skip?: number,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => dispatch(await SendFormsAndDocumentsActions.fetchAppendUsersForms(limit, skip, successCallback, errorCallback)),
  clearSearchUsersForms: async () => dispatch(await SendFormsAndDocumentsActions.clearSearchUsersForms()),
  fetchSearchUsersForms: (query: string, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(SendFormsAndDocumentsActions.fetchSearchUsersForms(query, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsAndDocuments);
