import { combineReducers } from 'redux';
import { SendFormsAndDocumentsReducer } from './SendFormsAndDocumentsDuck';
import { SspUsersReducer } from './SspUsersDuck';
import { UserDetailsReducer } from './UserDetailsDuck';
import { UsersReducer } from './UsersDuck';
import { AppointmentReducer } from './AppointmentsDuck';
import { AnalyticsReducer } from './AnalyticsDuck';
import { ReportsReducer } from './ReportsDuck';

export const rootReducer = combineReducers({
  userDetails: UserDetailsReducer,
  users: UsersReducer,
  sspUsers: SspUsersReducer,
  sendFormsAndDocuments: SendFormsAndDocumentsReducer,
  appointments: AppointmentReducer,
  analytics: AnalyticsReducer,
  reports: ReportsReducer,
});

export default rootReducer;
