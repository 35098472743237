import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { ClipLoader } from 'react-spinners';
import formatCamelValue from '../../../../../../utils/formatCamelValue';
import { AppointmentResponseData } from '../../../../../../models/Appointment';
import { selectCurrentAppointment } from '../../../../../../store/AppointmentsDuck/duck/selector';
import { DashboardLayout } from '../../../../../../layouts';
import { AlertModal, Card, CTAButton } from '../../../../../../components';
import './AppointmentDetails.scss';
import { deleteAppointment, getAppointmentDetails, getCurrentSspUserDetails } from '../../../../../../api';
import useStoreLocations from '../../../../../../hooks/useStoreLocations';
import formatFullName from '../../../../../../utils/formatFullName';

interface Props {
  id: string;
  appointment?: AppointmentResponseData;
}
const AppointmentDetails = ({ id, appointment: reduxAppointmentState }: Props): JSX.Element => {
  const history = useHistory();

  const [appointment, setAppointment] = useState(reduxAppointmentState);
  const { currentStore } = useStoreLocations(appointment?.store);

  /* Loading states */
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [viewUserLoading, setViewUserLoading] = useState(false);

  /* Delete appointment modal state */
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    customerId: bonafideCustomerId,
    username,
    firstname,
    middleinitial,
    lastname,
    dateofbirth,
    phone,
    phoneRegion,
    start,
    end,
    remarks,
    adminRemarks,
    createdAt,
  } = appointment || {};

  const appointmentInfo: AppointmentResponseData | Record<string, unknown> = {
    createdAt,
    start,
    end,
    remarks,
    adminRemarks,
    store: currentStore.name,
    address: currentStore.address,
    phone: currentStore.phone,
    fax: currentStore.fax,
  };

  const customerInfo: AppointmentResponseData | Record<string, unknown> = {
    bonafideCustomerId,
    username,
    firstname,
    middleinitial,
    lastname,
    dateofbirth,
    phone,
    phoneRegion,
  };

  const details = [
    {
      title: 'Appointment Details',
      data: appointmentInfo
        ? Object.keys(appointmentInfo).map((key) => ({
            key,
            value: `${appointmentInfo?.[key as keyof AppointmentResponseData]}`,
          }))
        : [],
    },
    {
      title: 'User Details',
      data: customerInfo
        ? Object.keys(customerInfo).map((key) => ({
            key,
            value: `${customerInfo?.[key as keyof AppointmentResponseData]}`,
          }))
        : [],
    },
  ];

  const title = formatFullName(appointment?.firstname, appointment?.middleinitial, appointment?.lastname);
  const time = `${dayjs(appointment?.start).format('MM/DD/YYYY hh:mm A')} - ${dayjs(appointment?.end).format(
    'MM/DD/YYYY hh:mm A',
  )}`;

  const composeValueElement = (key: string, value: string) => {
    switch (key) {
      case 'start':
      case 'end':
      case 'createdAt':
        return <span>{dayjs(value).format('MM/DD/YYYY hh:mm A')}</span>;
      case 'phone':
        return (
          <span>
            <a className="AppointmentDetails-section-information-content-row-value-anchor" href={`tel:${value}`}>
              {value}
            </a>
          </span>
        );
      case 'remarks':
      case 'adminRemarks':
        return (
          <div
            className="AppointmentDetails-section-information-content-row-value-remarks"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );
      default:
        return <span>{value}</span>;
    }
  };

  /* Delete Appointment */
  const handleDeleteAppointment = useCallback(() => {
    if (!appointment || !currentStore) return;
    setDeleteLoading(true);

    const timeslot = dayjs(appointment?.start).format('hh:mm A');

    deleteAppointment(appointment.id, timeslot, appointment?.start, currentStore.id)
      .then((res) => {
        if (res.status === 'ok') {
          history.push('/self-service-portal/appointments');
        }
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }, [appointment, currentStore, history]);

  /* View User Details */
  const handleViewUserDetails = useCallback(() => {
    if (!appointment?.customerId) return;
    setViewUserLoading(true);
    getCurrentSspUserDetails(undefined, appointment.customerId)
      .then((res) => {
        if (res.id) {
          history.push(`/self-service-portal/all-users/${res.id}`);
        }
      })
      .finally(() => {
        setViewUserLoading(false);
      });
  }, [appointment, history]);

  /* Fetch Appointment Details if appointment not found in redux store */
  useEffect(() => {
    if (appointment) return;
    getAppointmentDetails(id).then((res) => {
      if (res.status === 'ok') {
        setAppointment(res.data);
      } else {
        history.push('/self-service-portal/appointments');
      }
    });
  }, [appointment, history, id]);

  const renderToolbar = () => (
    <div className="AppointmentDetails-toolbar">
      <CTAButton onClick={() => setDeleteModalOpen(true)} text="Delete Appointment" disabled={deleteLoading} />
      <CTAButton
        onClick={handleViewUserDetails}
        text={viewUserLoading ? 'Loading...' : 'View User Details'}
        disabled={viewUserLoading}
      />
    </div>
  );

  return (
    <DashboardLayout title={title} subtitle={time} toolbar={renderToolbar()}>
      <Card className="AppointmentDetails">
        <AlertModal
          isOpen={deleteModalOpen}
          description="Are you sure you want to delete this appointment?"
          onConfirm={handleDeleteAppointment}
          confirmLabel={deleteLoading ? 'Deleting...' : 'Confirm'}
          onCancel={() => setDeleteModalOpen(false)}
        />
        <div className="AppointmentDetails-section-container">
          {!currentStore.id ? (
            <div className="AppointmentDetails-section-container-loading">
              <ClipLoader color="#06bbc9" />
            </div>
          ) : (
            <>
              {details.map((detail) => (
                <div key={detail.title} className="AppointmentDetails-section">
                  <h1>{detail.title}</h1>
                  <hr />
                  <div className="AppointmentDetails-section-information-content">
                    {detail.data.map((item) => (
                      <div key={item.key} className="AppointmentDetails-section-information-content-row">
                        <span>{formatCamelValue(item.key)}</span>
                        {composeValueElement(item.key, item.value)}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: RootState, props: any): Pick<Props, 'appointment' | 'id'> => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return {
    id,
    appointment: selectCurrentAppointment(state, id),
  };
};

export default connect(mapStateToProps)(AppointmentDetails);
