import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { CTAButton } from '../../../../../components';
import { FormsAndDocumentsMap } from '../../../../../models/FormsAndDocumentsMap';
import { ViewSSPUserDetails } from '../../../../../models/ViewSSPUserDetails';
import {
  selectSelectedDocument,
  selectSelectedSspUsers,
} from '../../../../../store/SendFormsAndDocumentsDuck/duck/selector';
import * as SendFormsAndDocumentsActions from '../../../../../store/SendFormsAndDocumentsDuck/duck/action';
import Layout from '../Layout';
import './Completed.scss';

interface Props {
  selectedSspUsers: ViewSSPUserDetails[];
  selectedDocument: FormsAndDocumentsMap | null;
  clearSelectedSspUsers: () => Promise<SendFormsAndDocumentsActions.ActionType>;
  clearSelectedDocument: () => Promise<SendFormsAndDocumentsActions.ActionType>;
}

const Completed = ({
  selectedSspUsers: reduxSelectedSspUsers,
  selectedDocument: reduxSelectedDocument,
  clearSelectedSspUsers,
  clearSelectedDocument,
}: Props): JSX.Element => {
  const [selectedSspUser] = useState(reduxSelectedSspUsers);
  const [selectedDocument] = useState(reduxSelectedDocument);
  const history = useHistory();

  /* Redirect back to step 2 if no selectedDocument */
  useEffect(() => {
    if (!selectedDocument) {
      history.push('/self-service-portal/send-forms-and-documents/select-document');
    } else if (selectedSspUser.length === 0 && selectedSspUser && selectedDocument) {
      clearSelectedSspUsers();
      clearSelectedDocument();
    }
  }, [selectedDocument, selectedSspUser, history, clearSelectedSspUsers, clearSelectedDocument]);

  const renderMessage = () =>
    selectedDocument?.adminPrefillRequired || selectedDocument?.adminSigningOnly ? (
      <>
        You have successfully assigned the forms to the selected users!
        <br />
        <br />
        <em>ACTION REQUIRED: The documents will NOT be viewable by patients until admin pre-fill/sign it.</em>
      </>
    ) : (
      <>You have successfully sent the forms to the selected users!</>
    );

  return (
    <Layout currentStep={3} className="SendFormsAndDocuments-completed-container">
      <div
        style={{
          mask: `url(/assets/icons/check-circle.svg) no-repeat center`,
          WebkitMask: `url(/assets/icons/check-circle.svg) no-repeat center`,
          maskSize: '6rem',
          WebkitMaskSize: '6rem',
        }}
        className="SendFormsAndDocuments-completed-container-icon"
      />
      <span className="SendFormsAndDocuments-completed-container-content">{renderMessage()}</span>
      <CTAButton
        onClick={() => history.push('/self-service-portal/send-forms-and-documents/select-patient')}
        text="Send another form"
      />
    </Layout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'selectedSspUsers' | 'selectedDocument'> => ({
  selectedSspUsers: selectSelectedSspUsers(state),
  selectedDocument: selectSelectedDocument(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'clearSelectedSspUsers' | 'clearSelectedDocument'> => ({
  clearSelectedSspUsers: async () => dispatch(await SendFormsAndDocumentsActions.clearSelectedSspUsers()),
  clearSelectedDocument: async () => dispatch(await SendFormsAndDocumentsActions.clearSelectedDocument()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Completed);
