import { MutableRefObject, useEffect } from 'react';

type Ref = MutableRefObject<null | HTMLElement>;

const useClickOutside = (
  /** Class name or ref object */
  insideRef: string | Ref | (string | Ref)[],
  onClickOutside: (target: HTMLElement) => unknown,
): void => {
  useEffect(() => {
    const refs = Array.isArray(insideRef) ? insideRef : [insideRef];
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isNotInRefs = refs.every((ref) => {
        if (typeof ref === 'string') return !target.classList.contains(ref);
        return ref.current && !ref.current.contains(target);
      });
      if (isNotInRefs) onClickOutside(target);
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, insideRef]);
};

export default useClickOutside;
