import { createStore, applyMiddleware, compose } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { rootReducer } from './reducer';
import { UserDetailsSaga } from './UserDetailsDuck';
import { UsersSaga } from './UsersDuck';
import { SspUsersSaga } from './SspUsersDuck';
import { SendFormsAndDocumentsSaga } from './SendFormsAndDocumentsDuck';
import { AppointmentSaga } from './AppointmentsDuck';
import { AnalyticsSaga } from './AnalyticsDuck';
import { ReportsSaga } from './ReportsDuck';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['sendFormsAndDocuments', 'appointments'],
};

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const persistor = persistStore(store as any);

function* rootSaga() {
  yield all([
    UserDetailsSaga(),
    UsersSaga(),
    SspUsersSaga(),
    SendFormsAndDocumentsSaga(),
    AppointmentSaga(),
    AnalyticsSaga(),
    ReportsSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);
