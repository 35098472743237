import { ActionType } from 'typesafe-actions';
import { ViewSSPUserDetails } from '../../../models';
import * as SspUsersActions from './action';

export interface State {
  sspUsers: ViewSSPUserDetails[] | null;
  searchResults: ViewSSPUserDetails[] | null;
}

const initialState: State = {
  sspUsers: null,
  searchResults: null,
};

type SspUserDetailsActionsType = ActionType<typeof SspUsersActions>;

export default function reducer(state: State = initialState, action: SspUserDetailsActionsType): State {
  switch (action.type) {
    case SspUsersActions.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: initialState.searchResults,
      };
    case SspUsersActions.CLEAR_SSP_USERS:
      return {
        ...state,
        sspUsers: initialState.sspUsers,
      };
    case SspUsersActions.FETCH_APPEND_SSP_USERS:
    case SspUsersActions.FETCH_APPEND_SSP_USERS_SUCCESS:
      return {
        ...state,
        sspUsers: [
          ...(state.sspUsers ?? []),
          ...(action.payload.sspUsers ? (action?.payload?.sspUsers as ViewSSPUserDetails[]) : []),
        ].filter((value, index, self) => self.findIndex((x) => x.id === value.id) === index),
      };
    case SspUsersActions.FETCH_SSP_USERS:
    case SspUsersActions.FETCH_SSP_USERS_SUCCESS:
    case SspUsersActions.FETCH_SSP_USERS_FAIL:
    case SspUsersActions.FETCH_SEARCH_RESULTS:
    case SspUsersActions.FETCH_SEARCH_RESULTS_SUCCESS:
    case SspUsersActions.FETCH_SEARCH_RESULTS_FAIL:
    case SspUsersActions.FETCH_APPEND_SSP_USERS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
