import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useStore } from 'react-redux';
import { RootState } from 'MyTypes';
import { LabelValue, ViewSSPUserDetails } from '../models';
import { getCurrentSspUserDetails } from '../api';
import { selectSspUser } from '../store/SspUsersDuck/duck/selector';

interface ReturnType {
  accountDetails: LabelValue[];
  sspUser: ViewSSPUserDetails | null | undefined;
}

const useCurrentSspUserDetails = (sspUserId: string): ReturnType => {
  const store = useStore();
  const state: RootState = store.getState();
  const currentSspUser = selectSspUser(state, sspUserId);
  const [sspUser, setSspUser] = useState(currentSspUser);

  useEffect(() => {
    if (sspUser) return;
    getCurrentSspUserDetails(sspUserId).then((currentUser) => {
      setSspUser(currentUser);
    });
  }, [sspUser, sspUserId]);

  const accountDetails: LabelValue[] = [
    {
      label: 'First Name',
      value: `${sspUser?.firstname}`,
    },
    {
      label: 'Middle Initial',
      value: `${sspUser?.middleinitial}`,
    },
    {
      label: 'Last Name',
      value: `${sspUser?.lastname}`,
    },
    {
      label: 'Account ID',
      value: `${sspUser?.id}`,
    },
    {
      label: 'Username',
      value: `${sspUser?.username}`,
    },
    {
      label: 'Phone',
      value: `${sspUser?.phoneRegion} ${sspUser?.phone}`,
    },
    {
      label: 'Verified Account',
      value: `${sspUser?.verified}`,
    },
    {
      label: 'Verified Account Date',
      value: `${dayjs(sspUser?.verifiedAt ?? '').format('MM-DD-YYYY HH:mm')}`,
    },
    {
      label: 'Last Login',
      value: `${dayjs(sspUser?.lastLogin ?? '').format('MM-DD-YYYY HH:mm')}`,
    },
    {
      label: 'Account Creation Date',
      value: `${dayjs(sspUser?.createdAt ?? '').format('MM-DD-YYYY HH:mm')}`,
    },
    {
      label: 'Updated At',
      value: `${dayjs(sspUser?.updatedAt ?? '').format('MM-DD-YYYY HH:mm')}`,
    },
  ];

  return {
    sspUser,
    accountDetails,
  };
};

export default useCurrentSspUserDetails;
