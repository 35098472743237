import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';
import { getTimeslotDetails, updateTimeslot } from '../../../../api';
import { Card, CTAButton, DatePicker, InputDropdownField, InputTextField, TagList } from '../../../../components';
import useStoreLocations from '../../../../hooks/useStoreLocations';
import { DashboardLayout } from '../../../../layouts';
import { Timeslot, UpdateTimeslotPayload } from '../../../../models';
import './ManageAppointmentTimeslots.scss';

const ManageAppointmentTimeslots = (): JSX.Element => {
  const [initialCurrentTimeslots, setInitialCurrentTimeslots] = useState<Timeslot[] | null>(null);
  const [currentTimeslots, setCurrentTimeslots] = useState<Timeslot[] | null>(null);
  const [date, setDate] = useState<Date | null>(null);

  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [customTimeslotValue, setCustomTimeslotValue] = useState<string | undefined | null>(null);
  const [tags, setTags] = useState([
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
  ]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);

  const { register, watch } = useForm();
  const { storeNames, currentStore } = useStoreLocations(watch('store'));
  const fields = watch();

  useEffect(() => {
    if (JSON.stringify(currentTimeslots) !== JSON.stringify(initialCurrentTimeslots)) {
      setDisableSaveBtn(false);
    } else {
      setDisableSaveBtn(true);
    }
  }, [currentTimeslots, initialCurrentTimeslots]);

  useEffect(() => {
    if (date && currentStore.id) {
      const midnightDate = date;
      midnightDate.setHours(0, 0, 0, 0);
      const formattedDate = dayjs(midnightDate).format('MM/DD/YYYY hh:mm A');

      setCurrentTimeslots(null);
      getTimeslotDetails(currentStore.id, formattedDate).then((res) => {
        if (res.data?.timeslots) {
          setCurrentTimeslots(res.data.timeslots);
          setInitialCurrentTimeslots(res.data.timeslots);
        } else {
          setCurrentTimeslots([]);
          setInitialCurrentTimeslots([]);
        }
      });
    }
  }, [currentStore.id, date]);

  const times = useMemo(() => {
    if (!currentTimeslots) return [];
    return currentTimeslots.map((timeslot) => `${timeslot.time} (${timeslot.slots})`);
  }, [currentTimeslots]);

  const sortedTimeslots = useMemo(() => {
    const morningTimeslots = times
      .filter((time) => time.includes('AM') || (time.includes('12:00') && time.includes('PM')))
      .sort((a, b) => Number(a.split(':')[0]) - Number(b.split(':')[0]));

    const afternoonTimeslots = times
      .filter((time) => time.includes('PM') && !time.includes('12:00'))
      .sort((a, b) => Number(a.split(':')[0]) - Number(b.split(':')[0]));

    return [...morningTimeslots, ...afternoonTimeslots];
  }, [times]);

  const handleAddTimeslot = useCallback(
    (time: string) => {
      if (!currentTimeslots) return;
      const findExistingTimeslot = currentTimeslots.find((timeslot) => timeslot.time === time);
      if (findExistingTimeslot) {
        setCurrentTimeslots(
          currentTimeslots.map((timeslot) => {
            if (timeslot.time === time) {
              return {
                ...timeslot,
                slots: timeslot.slots + 1,
              };
            }
            return timeslot;
          }),
        );
      } else {
        setCurrentTimeslots([
          ...currentTimeslots,
          {
            time,
            slots: 1,
          },
        ]);
      }
    },
    [currentTimeslots],
  );

  const handleRemoveTimeslot = useCallback(
    (tag: string) => {
      if (!currentTimeslots) return;
      const time = tag.split(' (')[0];
      const findExistingTimeslot = currentTimeslots.find((timeslot) => timeslot.time === time);
      if (findExistingTimeslot) {
        setCurrentTimeslots(
          currentTimeslots
            .map((timeslot) => {
              if (timeslot.time === time) {
                return {
                  ...timeslot,
                  slots: timeslot.slots - 1,
                };
              }
              return timeslot;
            })
            .filter((x) => x.slots > 0),
        );
      }
    },
    [currentTimeslots],
  );

  const handleSaveChanges = useCallback(() => {
    if (!date || !currentTimeslots || disableSaveBtn) return;
    const midnightDate = date;
    midnightDate.setHours(0, 0, 0, 0);
    const formattedDate = dayjs(midnightDate).format('MM/DD/YYYY hh:mm A');

    const payload: UpdateTimeslotPayload = {
      store: currentStore,
      date: formattedDate,
      timeslots: currentTimeslots,
    };

    setSaveLoading(true);
    updateTimeslot(payload).then((res) => {
      if (res.status === 'ok') {
        setSaveLoading(false);
      }
    });
  }, [currentStore, currentTimeslots, date, disableSaveBtn]);

  return (
    <DashboardLayout title="Manage Appointment Timeslots">
      <Card className="ManageAppointmentTimeslots-container">
        {storeNames.length === 0 && !currentTimeslots ? (
          <div className="ManageAppointmentTimeslots-loading">
            <ClipLoader color="#06bbc9" />
          </div>
        ) : (
          <>
            <InputDropdownField
              value={fields.store}
              placeholder="Select store..."
              items={storeNames}
              label="Store"
              {...register('store')}
            />
            <DatePicker
              name="date"
              placeholder="Select date..."
              label="Date"
              minDate={new Date()}
              date={date}
              onChange={(x: Date) => {
                setDate(x);
              }}
            />
            {currentTimeslots && (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileTimePicker
                    open={openTimePicker}
                    value={customTimeslotValue}
                    onChange={(newValue) => setCustomTimeslotValue(newValue)}
                    onClose={() => setOpenTimePicker(false)}
                    renderInput={() => (
                      <div className="ManageAppointmentTimeslots-custom-timeslot">
                        <InputTextField
                          readOnly
                          onClick={() => setOpenTimePicker(true)}
                          label="Add Custom Timeslot"
                          value={
                            dayjs(customTimeslotValue).format('hh:mm A') !== 'Invalid Date'
                              ? dayjs(customTimeslotValue).format('hh:mm A')
                              : ''
                          }
                          {...register('customTimeslot')}
                        />
                        <CTAButton
                          text="Add timeslot"
                          disabled={
                            !customTimeslotValue ||
                            tags.includes(dayjs(customTimeslotValue).format('hh:mm A')) ||
                            dayjs(customTimeslotValue).format('hh:mm A') === 'Invalid Date'
                          }
                          onClick={() => {
                            const formattedTime = dayjs(customTimeslotValue).format('hh:mm A');
                            if (!customTimeslotValue || formattedTime === 'Invalid Date') return;
                            setTags([formattedTime, ...tags]);
                          }}
                        />
                      </div>
                    )}
                  />
                </LocalizationProvider>
                <div className="ManageAppointmentTimeslots-taglist">
                  <div className="ManageAppointmentTimeslots-taglist-label">Add timeslot</div>
                  <TagList icon="add" onTagClick={(tag: string) => handleAddTimeslot(tag)} tags={tags} />
                </div>
                <div className="ManageAppointmentTimeslots-taglist">
                  <div className="ManageAppointmentTimeslots-taglist-label">Available Timeslots</div>
                  {sortedTimeslots.length === 0 ? (
                    <p>No available timeslots</p>
                  ) : (
                    <TagList tags={sortedTimeslots} onTagRemove={(tag) => handleRemoveTimeslot(tag)} />
                  )}
                </div>
                <CTAButton
                  disabled={saveLoading || disableSaveBtn}
                  text={saveLoading ? 'Saving...' : 'Save'}
                  onClick={handleSaveChanges}
                />
              </>
            )}
          </>
        )}
      </Card>
    </DashboardLayout>
  );
};

export default ManageAppointmentTimeslots;
