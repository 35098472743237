import React, { memo } from 'react';
import { Accordion } from '../../components';
import './UserProfileDetailsContainer.scss';
import composeChunkArr from '../../utils/composeChunkArr';
import { LabelValue } from '../../models';

interface Props {
  title: string;
  subtitle?: string;
  details: LabelValue[];
  itemsPerRow: number;
  defaultExpanded?: true;
}

export const UserProfileDetailsContainer = memo<Props>(({ title, subtitle, details, itemsPerRow, defaultExpanded }) => {
  const chunked: LabelValue[][] = composeChunkArr(details, itemsPerRow);

  return (
    <Accordion title={title} subtitle={subtitle} defaultExpanded={defaultExpanded}>
      {chunked.map((row, rowIndex) => (
        <div key={`${rowIndex + 1}`} className="UserProfileDetailsContainer-details-container">
          {row.map((cell, cellIndex) => (
            <div
              key={`${cellIndex + 1}`}
              style={{
                width: `${100 / itemsPerRow}%`,
                minWidth: `${100 / itemsPerRow}%`,
                maxWidth: `${100 / itemsPerRow}%`,
              }}
              className="UserProfileDetailsContainer-details-container-cell"
            >
              <span>{cell.label}</span>
              <span>{cell.value}</span>
            </div>
          ))}
        </div>
      ))}
    </Accordion>
  );
});

UserProfileDetailsContainer.displayName = 'UserProfileDetailsContainer';

export default UserProfileDetailsContainer;
