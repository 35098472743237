import { ActionType } from 'typesafe-actions';
import { ViewUserDetails } from '../../../models/ViewUserDetails';
import * as UsersActions from './action';

export interface State {
  users: ViewUserDetails[] | null;
  searchResults: ViewUserDetails[] | null;
}

const initialState: State = {
  users: null,
  searchResults: null,
};

type UserDetailsActionsType = ActionType<typeof UsersActions>;

export default function reducer(state: State = initialState, action: UserDetailsActionsType): State {
  switch (action.type) {
    case UsersActions.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: initialState.searchResults,
      };

    case UsersActions.CLEAR_USERS:
      return {
        ...state,
        users: initialState.users,
      };

    case UsersActions.FETCH_APPEND_USERS:
    case UsersActions.FETCH_APPEND_USERS_SUCCESS:
      return {
        ...state,
        users: [
          ...(state.users ?? []),
          ...(action.payload.users ? (action?.payload?.users as ViewUserDetails[]) : []),
        ].filter((value, index, self) => self.findIndex((x) => x.username === value.username) === index),
      };
    case UsersActions.FETCH_USERS:
    case UsersActions.FETCH_USERS_SUCCESS:
    case UsersActions.FETCH_USERS_FAIL:
    case UsersActions.FETCH_APPEND_USERS_FAIL:
    case UsersActions.FETCH_SEARCH_RESULTS:
    case UsersActions.FETCH_SEARCH_RESULTS_SUCCESS:
    case UsersActions.FETCH_SEARCH_RESULTS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
