import React, { memo, ReactNode } from 'react';
import './FormDetailsHeader.scss';

interface Props {
  title: ReactNode;
  id: string;
}

export const FormDetailsHeader = memo<Props>(({ title, id }) => {
  return (
    <div className="FormDetailsHeader">
      <span className="FormDetailsHeader-id">{`#${id}`}</span>
      <h1 className="FormDetailsHeader-title">{title}</h1>
    </div>
  );
});

FormDetailsHeader.displayName = 'FormDetailsHeader';

export default FormDetailsHeader;
