import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';

import languages from 'pages/SelfServicePortal/pages/FormsAndDocuments/pages/FormDetails/i18n/languages';
import { GetFormContentDictionary, GetFormContentErrorResponse, GetFormContentResponse } from 'models';
import * as SendFormsAndDocumentsActions from './action';
import { getAllUsersForms, getFormContent, getSearchUsersForms } from '../../../api';
import {
  ViewAllUsersFormsInterface,
  ViewAllUsersFormsInterfaceResponseInterface,
} from '../../../models/ViewAllUsersFormsInterface';

export function* handleGetFormContent(
  action: SendFormsAndDocumentsActions.ActionType,
): Generator<
  CallEffect<GetFormContentResponse | GetFormContentErrorResponse> | PutEffect<Action<string>>,
  void,
  GetFormContentResponse
> {
  const {
    payload: { i18n, formComponent, version, successCallback, errorCallback },
  } = action;

  try {
    const { content } = yield call(getFormContent, formComponent as string, version as string);
    if (!content || !i18n || !formComponent || !version) return;

    const formI18nNamespace = `${formComponent}-${version}`;
    Object.values(languages).forEach((lng) => {
      i18n.addResources(lng, formI18nNamespace, content[lng as keyof GetFormContentDictionary] ?? {});
    });
    if (successCallback) successCallback();
  } catch (error) {
    if (errorCallback) errorCallback();
  }
}

export function* handleGetAllUsersForms(
  action: SendFormsAndDocumentsActions.ActionType,
  successAction: (allUsersFormsAndDocuments: ViewAllUsersFormsInterface[]) => SendFormsAndDocumentsActions.ActionType,
  failAction: (error?: string | undefined) => SendFormsAndDocumentsActions.ActionType,
): Generator<
  CallEffect<ViewAllUsersFormsInterfaceResponseInterface> | PutEffect<Action<string>>,
  void,
  ViewAllUsersFormsInterfaceResponseInterface
> {
  const {
    payload: { limit, skip, successCallback, errorCallback },
  } = action;

  try {
    if (!limit) return;
    const { data } = yield call(getAllUsersForms, limit, skip);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* handleGetSearchUsersForms(
  action: SendFormsAndDocumentsActions.ActionType,
  successAction: (allUsersFormsAndDocuments: ViewAllUsersFormsInterface[]) => SendFormsAndDocumentsActions.ActionType,
  failAction: (error?: string | undefined) => SendFormsAndDocumentsActions.ActionType,
): Generator<
  CallEffect<ViewAllUsersFormsInterfaceResponseInterface> | PutEffect<Action<string>>,
  void,
  ViewAllUsersFormsInterfaceResponseInterface
> {
  const {
    payload: { query, successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getSearchUsersForms, query || '');
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetAllUsersForms(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    SendFormsAndDocumentsActions.FETCH_ALL_USERS_FORMS,
    (action: SendFormsAndDocumentsActions.ActionType) =>
      handleGetAllUsersForms(
        action,
        SendFormsAndDocumentsActions.fetchAllUsersFormsSuccess,
        SendFormsAndDocumentsActions.fetchAllUsersFormsFail,
      ),
  );

  yield takeLatest(
    SendFormsAndDocumentsActions.FETCH_APPEND_USERS_FORMS,
    (action: SendFormsAndDocumentsActions.ActionType) =>
      handleGetAllUsersForms(
        action,
        SendFormsAndDocumentsActions.fetchAppendUsersFormsSuccess,
        SendFormsAndDocumentsActions.fetchAppendUsersFormsFail,
      ),
  );
}

export function* watchGetSearchUsersForms(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    SendFormsAndDocumentsActions.FETCH_SEARCH_USERS_FORMS_RESULTS,
    (action: SendFormsAndDocumentsActions.ActionType) =>
      handleGetSearchUsersForms(
        action,
        SendFormsAndDocumentsActions.fetchSearchUsersFormsSuccess,
        SendFormsAndDocumentsActions.fetchSearchUsersFormsFail,
      ),
  );
  yield takeLatest(SendFormsAndDocumentsActions.FETCH_FORM_CONTENT, (action: SendFormsAndDocumentsActions.ActionType) =>
    handleGetFormContent(action),
  );
}
