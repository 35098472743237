import React, { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import { InputTextField } from 'components/InputTextField/InputTextField';
import { InputDropdownField } from 'components/InputDropdownField/InputDropdownField';
import parseCustomerDob from 'utils/parseCustomerDob';
import useFormDropdownOptions from '../../../hooks/useFormDropdownOptions';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './FittingCommentsShoeOnly.scss';

interface DropdownOptions {
  subjective: string[];
  objective: string[];
  plan: string[];
}
const getDropdownOtherTextKey = (key: keyof DropdownOptions) => `${key}_text`;
const OTHER_VALUE = 'other';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const FittingCommentsShoeOnly = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        orderNo: form?.paOrderData?.orderNo,
        dob: form?.paOrderData?.dob ? parseCustomerDob(form?.paOrderData?.dob) : '',
        additionalNotes: '',
      } as { [key: string]: string },
    });

    /** ------------------------- Fetch dropdown options ------------------------- */
    const { isRefreshingOptions, dropdownOptions } = useFormDropdownOptions<DropdownOptions>(form);

    const onSubmitWithDataMapping = useCallback(
      (data) => {
        const {
          [getDropdownOtherTextKey('subjective')]: subjectiveText,
          [getDropdownOtherTextKey('objective')]: objectiveText,
          [getDropdownOtherTextKey('plan')]: planText,
          subjective,
          objective,
          plan,
          ...rest
        } = data;
        const mappedData = {
          ...rest,
          subjective: subjective === OTHER_VALUE ? subjectiveText : subjective,
          objective: objective === OTHER_VALUE ? objectiveText : objective,
          plan: plan === OTHER_VALUE ? planText : plan,
        };
        return onSubmit(mappedData);
      },
      [onSubmit],
    );

    const renderDropdownOrInput = (key: keyof DropdownOptions) => {
      // @TODO: Translate options
      const value = watch(key);
      const options = [
        ...(dropdownOptions[key]?.filter((c) => !!c.trim()) ?? []).map((c) => ({ label: c, value: c })),
        { label: tForm('other'), value: OTHER_VALUE },
      ];
      const isOther = value === OTHER_VALUE;
      return (
        <>
          <InputDropdownField
            {...register(key, { required: true })}
            items={options}
            label={tForm(key)}
            placeholder={`e.g. ${options[0]?.label ?? ''}`}
            status={isRefreshingOptions ? 'disabled' : (errors || {})[key] ? 'error' : 'active'}
          />
          <br />
          {isOther && (
            <InputTextField
              {...register(getDropdownOtherTextKey(key), { required: isOther })}
              type="textarea"
              maxLength={500}
            />
          )}
        </>
      );
    };

    return (
      <FormDetailsLayout
        className="FittingCommentsShoeOnly"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmitWithDataMapping)}
        loading={loading || isRefreshingOptions}
        submitError={submitError}
      >
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        <InputTextField {...register('customerId')} label={tForm('caretinuumId')} type="text" status="disabled" />
        <InputTextField {...register('orderNo')} label={tForm('order')} type="text" status="disabled" />
        <InputTextField
          {...register('name')}
          label={tForm('patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField {...register('dob')} label={tForm('dob')} type="text" status="disabled" />
        <InputTextField
          name="itemsDescription"
          label={tForm('itemsDescription')}
          value={`${tForm('orthopedicShoesOrInserts') ?? ''}`}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField
          {...register('doneBy', { required: true })}
          label={tForm('doneBy')}
          status={errors?.doneBy ? 'error' : 'active'}
          placeholder={namePlaceholder}
          type="text"
          maxLength={50}
        />
        {renderDropdownOrInput('subjective')}
        {renderDropdownOrInput('objective')}
        {renderDropdownOrInput('plan')}
        <InputTextField
          {...register('additionalNotes')}
          label={tForm('additionalNotes')}
          type="textarea"
          maxLength={500}
        />
      </FormDetailsLayout>
    );
  },
);

FittingCommentsShoeOnly.displayName = 'FittingCommentsShoeOnly';

export default FittingCommentsShoeOnly;
