import React, { memo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import './BasicLineChart.scss';
import Skeleton from 'react-loading-skeleton';
import { SeriesData } from '../../models/SeriesData';

interface Props {
  title?: string;
  yAxisTitle: string;
  xAxisTitle: string;
  seriesName: string;
  data: SeriesData | null;
  loading?: boolean;
}

export const BasicLineChart = memo<Props>(({ title, seriesName, data, loading }) => {
  const options = {
    rangeSelector: {
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 8,
        style: {
          color: '#06bbc9',
          fontWeight: 'bold',
        },
        states: {
          hover: {},
          select: {
            fill: '#06bbc9',
            style: {
              color: 'white',
            },
          },
          // disabled: { ... }
        },
      },
      inputBoxBorderColor: 'gray',
      inputBoxWidth: 120,
      inputBoxHeight: 18,
      inputStyle: {
        color: '#06bbc9',
        fontWeight: 'bold',
      },
      labelStyle: {
        color: 'silver',
        fontWeight: 'bold',
      },
    },

    title: {
      text: title,
      style: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
      },
    },
    navigator: {
      maskFill: 'rgba(6, 188, 201, 0.25)',
    },
    series: [
      {
        name: seriesName,
        color: '#06bbc9', // $MainCyan,
        data,
      },
    ],
  };

  return (
    <div className="BasicLineChart">
      {loading ? (
        <div style={{ backgroundColor: 'transparent' }} className="BasicLineChart-loading">
          <Skeleton count={1} />
        </div>
      ) : (
        <HighchartsReact constructorType="stockChart" highcharts={Highcharts} options={options} />
      )}
    </div>
  );
});

BasicLineChart.displayName = 'BasicLineChart';

export default BasicLineChart;
