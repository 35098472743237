import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoginDetails } from '../../models';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import * as UsersActions from '../../store/UsersDuck/duck/action';
import { LoginResponse } from '../../models/LoginResponse';
import { getCurrentUserDetails, postLoginDetails } from '../../api';
import { ApplicationVersion, CTAButton, InputTextField } from '../../components';
import './Login.scss';
import { setToken } from '../../api/axios';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';

interface Props {
  setLoginDetails: (loginDetails: LoginResponse) => Promise<UserDetailsActions.ActionType>;
  clearLoginDetails: () => Promise<UserDetailsActions.ActionType>;
  setCurrentUserDetails: (currentUserDetails: CurrentUserDetails) => Promise<UserDetailsActions.ActionType>;
  clearCurrentUserDetails: () => Promise<UserDetailsActions.ActionType>;
  clearUsers: () => Promise<UsersActions.ActionType>;
}

const Login = ({
  setLoginDetails,
  setCurrentUserDetails,
  clearLoginDetails,
  clearCurrentUserDetails,
  clearUsers,
}: Props): JSX.Element => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isFullResBgLoaded, setIsFullResBgLoaded] = useState(false);

  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDetails>();

  useEffect(() => {
    Promise.all([clearLoginDetails(), clearCurrentUserDetails(), clearUsers()]).then(() => {
      sessionStorage.clear();
    });
  }, [clearCurrentUserDetails, clearLoginDetails, clearUsers]);

  const onSubmit = (data: LoginDetails) => {
    setLoading(true);
    postLoginDetails(data)
      .then(async (res: LoginResponse) => {
        setLoading(false);

        switch (res.status) {
          case 'ok': {
            if (res.token) setToken(res.token);
            setLoginDetails(res);

            await getCurrentUserDetails().then((curUserRes) => {
              if (curUserRes.status === 'error') {
                setLoginError(curUserRes.error || null);
                return;
              }
              if (curUserRes.user) setCurrentUserDetails(curUserRes.user);
            });

            history.push('/home');
            break;
          }
          case 'error':
            setLoginError(res.error || null);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        setLoginError(String(err));
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      <div className="login-container-left">
        <h1>
          Welcome to
          <span> iPortal</span>
        </h1>
        <h2>Confucius Pharmacy and Mannings Surgical Supplies</h2>
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-form-container">
            <InputTextField
              label="Email address"
              placeholder="john.appleseed@nymannings.com"
              type="email"
              status={errors?.username ? 'error' : 'active'}
              {...register('username', { required: true })}
            />
            <InputTextField
              type="password"
              label="Password"
              placeholder="•••••••••••••••"
              status={errors?.password ? 'error' : 'active'}
              {...register('password', { required: true })}
            />
          </div>
          <span className={`login-error ${loginError ? 'mod-show' : ''}`}>{`${loginError}`}</span>
          <CTAButton type="submit" text={loading ? 'Logging in...' : 'Login'} disabled={loading} />
        </form>
        <h2 className="register-tip">
          To register for an account, please contact an administrator.
          <ApplicationVersion />
        </h2>
      </div>
      <div className="login-container-right">
        <img
          alt=""
          src={`${process.env.PUBLIC_URL}/assets/images/login-bg_lowRes.jpeg`}
          className="login-container-right-bg-img lowRes"
          style={{ opacity: isFullResBgLoaded ? 0 : 1 }}
        />
        <img
          alt=""
          src={`${process.env.PUBLIC_URL}/assets/images/login-bg.jpeg`}
          className="login-container-right-bg-img"
          onLoad={() => setIsFullResBgLoaded(true)}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLoginDetails: async (loginResponse: LoginResponse) =>
    dispatch(await UserDetailsActions.setLoginDetails(loginResponse)),
  clearLoginDetails: async () => dispatch(await UserDetailsActions.clearLoginDetails()),
  setCurrentUserDetails: async (currentUserDetails: CurrentUserDetails) =>
    dispatch(await UserDetailsActions.setCurrentUserDetails(currentUserDetails)),
  clearCurrentUserDetails: async () => dispatch(await UserDetailsActions.clearCurrentUserDetails()),
  clearUsers: async () => dispatch(await UsersActions.clearUsers()),
});

export default connect(null, mapDispatchToProps)(Login);
