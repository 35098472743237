import { action } from 'typesafe-actions';
import { ViewSSPUserDetails } from '../../../models';
import { Appointment, AppointmentResponseData } from '../../../models/Appointment';
import { ViewAllUsersFormsInterface } from '../../../models/ViewAllUsersFormsInterface';

export interface ActionType {
  type:
    | 'appointments/SET_SELECTED_SSP_USERS'
    | 'appointments/CLEAR_SELECTED_SSP_USERS'
    | 'appointments/SET_SELECTED_DATE_TIME'
    | 'appointments/CLEAR_SELECTED_DATE_TIME'
    | 'appointments/FETCH_ALL_USERS_APPOINTMENTS'
    | 'appointments/FETCH_ALL_USERS_APPOINTMENTS_SUCCESS'
    | 'appointments/FETCH_ALL_USERS_APPOINTMENTS_FAIL'
    | 'appointments/FETCH_APPEND_USERS_APPOINTMENTS'
    | 'appointments/FETCH_APPEND_USERS_APPOINTMENTS_SUCCESS'
    | 'appointments/FETCH_APPEND_USERS_APPOINTMENTS_FAIL'
    | 'appointments/CLEAR_ALL_USERS_APPOINTMENTS'
    | 'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS'
    | 'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_SUCCESS'
    | 'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_FAIL'
    | 'appointments/CLEAR_SEARCH_USERS_APPOINTMENTS_RESULTS';
  payload: {
    query?: string;
    searchUsersFormsResults?: ViewAllUsersFormsInterface[];
    allAppointments?: AppointmentResponseData[];
    selectedSspUsers?: ViewSSPUserDetails[];
    selectedDateTime?: Appointment;
    month?: number;
    year?: number;
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const SET_SELECTED_SSP_USERS = 'appointments/SET_SELECTED_SSP_USERS';
export const SET_SELECTED_DATE_TIME = 'appointments/SET_SELECTED_DATE_TIME';

export const CLEAR_SELECTED_SSP_USERS = 'appointments/CLEAR_SELECTED_SSP_USERS';
export const CLEAR_SELECTED_DATE_TIME = 'appointments/CLEAR_SELECTED_DATE_TIME';

export const FETCH_ALL_USERS_APPOINTMENTS = 'appointments/FETCH_ALL_USERS_APPOINTMENTS';
export const FETCH_ALL_USERS_APPOINTMENTS_SUCCESS = 'appointments/FETCH_ALL_USERS_APPOINTMENTS_SUCCESS';
export const FETCH_ALL_USERS_APPOINTMENTS_FAIL = 'appointments/FETCH_ALL_USERS_APPOINTMENTS_FAIL';

export const FETCH_APPEND_USERS_APPOINTMENTS = 'appointments/FETCH_APPEND_USERS_APPOINTMENTS';
export const FETCH_APPEND_USERS_APPOINTMENTS_SUCCESS = 'appointments/FETCH_APPEND_USERS_APPOINTMENTS_SUCCESS';
export const FETCH_APPEND_USERS_APPOINTMENTS_FAIL = 'appointments/FETCH_APPEND_USERS_APPOINTMENTS_FAIL';

export const CLEAR_ALL_USERS_APPOINTMENTS = 'appointments/CLEAR_ALL_USERS_APPOINTMENTS';

export const FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS = 'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS';
export const FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_SUCCESS =
  'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_SUCCESS';
export const FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_FAIL = 'appointments/FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_FAIL';
export const CLEAR_SEARCH_USERS_APPOINTMENTS_RESULTS = 'appointments/CLEAR_SEARCH_USERS_APPOINTMENTS_RESULTS';

/* ------------- Create Appointment Funnel ------------- */
export const setSelectedSspUsers = (selectedSspUsers: ViewSSPUserDetails[]): ActionType =>
  action(SET_SELECTED_SSP_USERS, { selectedSspUsers });

export const setSelectedDateTime = (selectedDateTime: Appointment): ActionType =>
  action(SET_SELECTED_DATE_TIME, { selectedDateTime });

export const clearSelectedSspUsers = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SELECTED_SSP_USERS)).then();

export const clearSelectedDateTime = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SELECTED_DATE_TIME)).then();

/* ------------- Fetch Users Appointments ------------- */
export const fetchUsersAppointments = (
  month?: number,
  year?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_ALL_USERS_APPOINTMENTS, { month, year, successCallback, errorCallback });
export const fetchUsersAppointmentsSuccess = (allAppointments: AppointmentResponseData[]): ActionType =>
  action(FETCH_ALL_USERS_APPOINTMENTS_SUCCESS, { allAppointments });

export const fetchUsersAppointmentsFail = (error?: string): ActionType =>
  action(FETCH_ALL_USERS_APPOINTMENTS_FAIL, { error });

/* ------------- Fetch More Users Appointments ------------- */
export const fetchAppendUsersAppointments = (
  month?: number,
  year?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_APPEND_USERS_APPOINTMENTS, { month, year, successCallback, errorCallback });
export const fetchAppendUsersAppointmentsSuccess = (allAppointments: AppointmentResponseData[]): ActionType =>
  action(FETCH_APPEND_USERS_APPOINTMENTS_SUCCESS, { allAppointments });
export const fetchAppendUsersAppointmentsFail = (error?: string): ActionType =>
  action(FETCH_APPEND_USERS_APPOINTMENTS_FAIL, { error });

export const clearAllUsersAppointments = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_ALL_USERS_APPOINTMENTS)).then();

/* ------------- Search API ------------- */
export const fetchSearchUsersAppointments = (
  query: string,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS, { query, successCallback, errorCallback });
export const fetchSearchUsersAppointmentsSuccess = (
  searchUsersFormsResults?: ViewAllUsersFormsInterface[],
): ActionType => action(FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_SUCCESS, { searchUsersFormsResults });
export const fetchSearchUsersAppointmentsFail = (error?: string): ActionType =>
  action(FETCH_SEARCH_USERS_APPOINTMENTS_RESULTS_FAIL, { error });
export const clearSearchUsersForms = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_SEARCH_USERS_APPOINTMENTS_RESULTS)).then();
